import React, {Component} from 'react';
import styled from 'styled-components';
import CountryCard from '../components/CountryCard';
import StateCard from '../components/StateCard';

export default class ModalCountryCard extends Component {
    render() {
        return (
            <StyledModalCountryCard>
                {
                  this.props.showCountryCard === true && !this.props.subnationalDisplay ?
                  <CountryCard {...this.props} />
                  : this.props.showCountryCard === true && this.props.subnationalDisplay ?
                  <StateCard {...this.props} />
                  : ''
                }
            </StyledModalCountryCard>
        )
    }
}

const StyledModalCountryCard = styled.div`
`
