import React, {Component} from 'react';
import styled from 'styled-components';
import Loading from '../components/Loading';
import {MobileFilters} from '../components/MobileTriggers.js';
import Clock from '../components/Clock';
import Close from '../_/img/close.svg';

import IconCurrentEscape from '../_/img/icon-current-escape.svg';
import IconTargetEscape from '../_/img/icon-target-escape.svg';

export default class CountryCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
          expanded: this.props.modalReload,
          country: this.props.currentCountryLabel,
        }
    }

    componentDidMount(){
      //Fix for user playing the DJ with the slider, make it only run once the same country & year
      if(this.props.lastYear !== this.props.currentYear || this.props.lastCountry !== this.props.currentCountry){
        this.props.setupInitialCountryCard();
      }
    }

    mapCountryCard = {
        country: this.props.currentCountryLabel,
        countryCode: this.props.currentCountry
    }


    getCountryCardData = () => {
        this.props.mapData.features.forEach ( (feature) => {
            if (feature.id === this.props.currentCountry) {
                this.mapCountryCard.country = this.props.currentCountryLabel;
                this.mapCountryCard.countryCode = this.props.currentCountry;
            }
        });


    }

    toggleExpanded = () => {
        this.setState({expanded: !this.state.expanded})
        this.props.toggleCardReload();
    }
    labelAfterFiltering () {
      if (this.props.filters === undefined) { return 'People in the world living in extreme poverty'; }
      //this.props.currentCountry === 'WORLD' ?  place = '' : place = 'ON ' + this.props.currentCountry;

      if (this.props.filters[0][0]) { return 'PEOPLE IN RURAL AREAS LIVING IN EXTREME POVERTY'; }
      if (this.props.filters[0][1]) { return 'PEOPLE IN URBAN AREAS LIVING IN EXTREME POVERTY'; }

      if (this.props.filters[1][0] && !this.props.filters[1][1] && !this.props.filters[1][2]) { return 'FEMALES LIVING IN EXTREME POVERTY'; }
      if (this.props.filters[1][1] && !this.props.filters[1][0] && !this.props.filters[1][2]) { return 'MALES LIVING IN EXTREME POVERTY'; }

      if (this.props.filters[1][0] && !this.props.filters[1][1] && this.props.filters[1][2]) { return 'FEMALES IN AGE RANGE LIVING IN EXTREME POVERTY'; }
      if (this.props.filters[1][1] && !this.props.filters[1][0] && this.props.filters[1][2]) { return 'MALES IN AGE RANGE LIVING IN EXTREME POVERTY'; }

      if (!this.props.filters[1][1] && !this.props.filters[1][0] && this.props.filters[1][2]) { return 'PEOPLE IN AGE RANGE LIVING IN EXTREME POVERTY'; }

      return 'TOTAL NUMBER OF PEOPLE LIVING IN EXTREME POVERTY';
    }
    getTrack(){
      if(this.props.continentsDisplay){
        if((this.props.cardData.headcountInPoverty / this.props.cardData.population) < 0.03){
          return 'map-legend-item circle circle-no-extreme-poverty';
        }else if(this.props.clockData.escapeRate >= this.props.clockData.targetEscapeRate){
          return 'map-legend-item circle circle-ontrack';
        }else if(this.props.clockData.escapeRate < 0){
          return 'map-legend-item circle circle-poverty-rising';
        }else if(this.props.clockData.escapeRate > 0){
          return 'map-legend-item circle-offtrack';
        }else{
          return 'map-legend-item circle circle-no-extreme-poverty';
        }
      }else{
        switch(this.props.clockData.stableTrack){
          case 'offtrack':
            return 'map-legend-item circle-offtrack'
            
          case 'ontrack':
            return 'map-legend-item circle circle-ontrack'
            
          case 'rising':
            return 'map-legend-item circle circle-poverty-rising'
            
          case 'nopoverty':
            return 'map-legend-item circle circle-no-extreme-poverty'
            
          default:
            return 'map-legend-item circle circle-no-extreme-poverty'
            
        }
      }
    }
    render() {
        this.getCountryCardData();
        return (

            this.props.showCountryCard === true ?
              <StyledCountryCard>
              <div className='country-card'>
                  {/*this.state.expanded ?
                    <button className="modal-expand-button" onClick={() => this.toggleExpanded()}><img className="right-arrow" src={LeftArrow} alt="Right Arrow" /> COLLAPSE</button>
                    :
                    <button className="modal-expand-button" onClick={() => this.toggleExpanded()}><img className="left-arrow" src={LeftArrow} alt="Left Arrow" /> EXPAND</button>
                  */}
                  <button className="country-card-close-button" onClick={ () => { this.props.handleCountryCard(false) } }><img className="close" src={Close} alt="Close" /></button>
                  <div className="country-card-header">
                    <button className="country-card-download-button" onClick={ () => {this.props.handleModal(true, 'infocapture_countrycarddownloadbutton-'+this.mapCountryCard.countryCode)}}>Request data</button>
                  </div>
                  {/*this.props.countryHasSubnational && !this.props.continentsDisplay ? <div className={this.props.subnationalDisplay ? 'subnational button active' : 'subnational button'} onClick={() => this.props.handleSubnational(true)}>Subnational</div>
              : '' */}
                  <div className="country-card-content">
                    <div className="country-card-content-fundamentals">
                        <h2 className="serif country-card-content-fundamentals-title">
                          <div className={this.getTrack()}></div>
                          {this.mapCountryCard.country}
                        </h2>
                        {this.props.countryHasSubnational && !window.matchMedia("(min-width: 768px)").matches && !this.props.continentsDisplay ? <div className={this.props.subnationalDisplay ? 'subnational button active' : 'subnational button'} onClick={() => this.props.handleSubnational(true)}>Subnational view</div>
              : '' }
                        <table className="country-card-pop-table">
                          <tbody>
                            <tr>
                              <td><h6>TOTAL POPULATION</h6></td>
                              <td><h5 className="align-right bold">{this.props.cardData.population !== '' ? 
                              Math.round(this.props.cardData.population).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 
                              <Loading width="15" border="2"></Loading>}</h5></td>
                            </tr>
                            <tr>
                              <td><h6>PEOPLE LIVING IN<br />EXTREME POVERTY</h6></td>
                              <td><h5 className=" align-right red bold">{ this.props.cardData.population !== '' ? 
                              Math.round(this.props.cardData.headcountInPoverty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 
                              <Loading width="15" border="2"></Loading>}</h5></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="country-card-thresholds">
                          <h6>POVERTY THRESHOLDS</h6>
                          <div className="thresholds-selector">
                            <div className="thresholds-selector-item selected" >2.15 $</div>

                            <div className="thresholds-selector-item unavaliable" onClick={ () => {this.props.handleModal(true, 'infocapture_dollarincrement320-'+this.mapCountryCard.countryCode)}}>3.65 $</div>
                            <div className="thresholds-selector-item unavaliable" onClick={ () => {this.props.handleModal(true, 'infocapture_dollarincrement550-'+this.mapCountryCard.countryCode)}}>6.85 $</div>
                          </div>
                        </div>
                        <table className="country-card-all-table">
                          <tbody>
                          <tr>
                            <td><h6>IN RURAL<br />AREAS</h6></td>
                            <td><h4 className="align-right">{this.props.cardData.headcountRural !== '' ?
                            this.props.cardData.headcountRural === 0 ?
                            '< 0.5%' :
                            Math.round(this.props.cardData.headcountRural).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):
                            <Loading width="15" border="2"></Loading>}</h4></td>
                            <td><h4 className="serif align-right">{
                              this.props.cardData.percentageRural !== '' ? 
                              this.props.cardData.percentageRural < 0.01 && this.props.cardData.percentageRural > 0 ?
                              (this.props.cardData.percentageRural*100).toFixed(1)+'%' : 
                              Math.round(this.props.cardData.percentageRural*100)+'%' : 
                              <Loading width="15" border="2"></Loading>}</h4></td>
                          </tr>
                          <tr>
                            <td><h6>IN URBAN<br />AREAS</h6></td>
                            <td><h4 className="align-right">{this.props.cardData.headcountUrban !== '' ?
                            this.props.cardData.headcountUrban === 0 ?
                            '< 0.5%' :
                            Math.round(this.props.cardData.headcountUrban).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):
                            <Loading width="15" border="2"></Loading>}</h4></td>
                            <td><h4 className="serif align-right">{this.props.cardData.percentageUrban !== '' ? 
                            this.props.cardData.percentageUrban < 0.01 && this.props.cardData.percentageUrban > 0 ?
                            (this.props.cardData.percentageUrban*100).toFixed(1)+'%' : 
                            Math.round(this.props.cardData.percentageUrban*100)+'%' : 
                            <Loading width="15" border="2"></Loading>}</h4></td>
                          </tr>
                          <tr>
                            <td><h6>MALES</h6></td>
                            <td><h4 className="align-right">{
                              this.props.cardData.headcountMale !== '' ? 
                              Math.round(this.props.cardData.headcountMale).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):
                              <Loading width="15" border="2"></Loading>}</h4></td>
                            <td><h4 className="serif align-right">{
                              this.props.cardData.percentageMale !== '' ? 
                              this.props.cardData.percentageMale < 0.01 && this.props.cardData.percentageMale > 0 ?
                              (this.props.cardData.percentageMale*100).toFixed(1)+'%' : 
                              Math.round(this.props.cardData.percentageMale*100)+'%' :
                              <Loading width="15" border="2"></Loading>}</h4></td>
                          </tr>
                          <tr>
                            <td><h6>FEMALES</h6></td>
                            <td><h4 className="align-right">{this.props.cardData.headcountFemale !== '' ? 
                            Math.round(this.props.cardData.headcountFemale).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):
                            <Loading width="15" border="2"></Loading>}</h4></td>
                            <td><h4 className="serif align-right">{
                              this.props.cardData.percentageFemale !== '' ? 
                              this.props.cardData.percentageFemale < 0.01 && this.props.cardData.percentageFemale > 0 ?
                              (this.props.cardData.percentageFemale*100).toFixed(1)+'%' : 
                              Math.round(this.props.cardData.percentageFemale*100)+'%' : 
                              <Loading width="15" border="2"></Loading>}</h4></td>
                          </tr>
                          </tbody>
                        </table>
                        <table className="country-card-all-table-mobile">
                          <thead>
                            <tr>
                              <th colSpan="4">out of that:</th>
                            </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td><h6>IN RURAL<br />AREAS</h6></td>
                            <td><h4 className="serif align-right">{
                              this.props.cardData.percentageRural !== '' ? 
                              this.props.cardData.percentageRural < 0.01 && this.props.cardData.percentageRural > 0 ?
                              (this.props.cardData.percentageRural*100).toFixed(1)+'%' : 
                              Math.round(this.props.cardData.percentageRural*100)+'%' : 
                              <Loading width="15" border="2"></Loading>}</h4></td>
                            <td><h6>IN URBAN<br />AREAS</h6></td>
                            <td><h4 className="serif align-right">{this.props.cardData.percentageUrban !== '' ? 
                            this.props.cardData.percentageUrban < 0.01 && this.props.cardData.percentageUrban > 0 ?
                            (this.props.cardData.percentageUrban*100).toFixed(1)+'%' : 
                            Math.round(this.props.cardData.percentageUrban*100)+'%' : 
                            <Loading width="15" border="2"></Loading>}</h4></td>
                          </tr>
                          <tr>
                            <td><h6>MALES</h6></td>
                            <td><h4 className="serif align-right">{
                              this.props.cardData.percentageMale !== '' ? 
                              this.props.cardData.percentageMale < 0.01 && this.props.cardData.percentageMale > 0 ?
                              (this.props.cardData.percentageMale*100).toFixed(1)+'%' : 
                              Math.round(this.props.cardData.percentageMale*100)+'%' :
                              <Loading width="15" border="2"></Loading>}</h4></td>
                            <td><h6>FEMALES</h6></td>
                            <td><h4 className="serif align-right">{
                              this.props.cardData.percentageFemale !== '' ? 
                              this.props.cardData.percentageFemale < 0.01 && this.props.cardData.percentageFemale > 0 ?
                              (this.props.cardData.percentageFemale*100).toFixed(1)+'%' : 
                              Math.round(this.props.cardData.percentageFemale*100)+'%' : 
                              <Loading width="15" border="2"></Loading>}</h4></td>
                          </tr>
                          </tbody>
                        </table>
                    </div>
                    <div className="country-card-content-expanded">
                      <div className="country-card-current-table">
                        <h6 className="white">{this.labelAfterFiltering()}</h6>
                        <table>
                          <tbody>
                            <tr>
                              <td><h3 className="red">
                              {this.props.clockData.headcountInPoverty !== ''
                              ? Math.round(this.props.clockData.headcountInPoverty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : <Loading width="15" border="2"></Loading>}
                              </h3></td>
                              <td><h3 className="serif red">
                              {this.props.clockData.headcountInPoverty !== '' && this.props.clockData.population !== ''
                              ? 
                              this.props.clockData.headcountInPoverty/this.props.clockData.population < 0.01 ?
                              ((this.props.clockData.headcountInPoverty/this.props.clockData.population)*100).toFixed(1)+" %"
                              :
                              Math.round((this.props.clockData.headcountInPoverty/this.props.clockData.population)*100)+" %"
                              : 
                              <Loading width="15" border="2"></Loading>}
                              </h3></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="country-card-clock">
                        {this.props.renderCountryChart ? 
                          <Clock clockData={this.props.clockData} filters={[[true],[true]]} refreshing={false} isCountryCard={true} outerCircleColor={'white'} renderChart={this.props.renderChart}  ></Clock>
                          : 
                          <Loading width="50" border="5"></Loading>
                          }
                      </div>
                      <div className="headline-legend headline-legend-country-card">
                        <ul className="headline-legend-container">
                          <li className="headline-legend-item" onClick={ () => {this.props.handleModal(true, 'target_escape_rate')}} >
                            <div className="headline-legend-icon">
                              <img src={IconTargetEscape} alt="Icon"/>
                            </div>
                            <div className="headline-legend-description">
                              <h6 className="headline-legend-heading label white" >Target escape rate</h6>
                              <div className="headline-legend-score">
                              {
                               this.props.currentYear === 2031 || this.getTrack() === 'map-legend-item circle circle-no-extreme-poverty' ?
                                ' — '
                               : 
                               this.props.clockData ?
                                 //Check if escape rate is < 0.1 to convert to people/min
                                 Math.abs(this.props.clockData.targetEscapeRate) < 0.1 ?
                                 //Check if escape rate PER MIN is < 0.1 to convert to people/hour
                                 Math.abs(Math.round(((this.props.clockData.targetEscapeRate)*60)*10)) < 0.1 ?
                                 //People per Hour if this is true
                                 Math.round(((this.props.clockData.targetEscapeRate)*60*60)*10)/10 :
                                 //People per Minute if this is false
                                 Math.round(((this.props.clockData.targetEscapeRate)*60)*10)/10 :
                                 //Regular People per Second
                                 Math.round((this.props.clockData.targetEscapeRate)*10)/10 :
                                 ''
                                }
                                {
                                   Math.abs(this.props.clockData.targetEscapeRate) < 0.1 ?
                                   Math.abs(Math.round(((this.props.clockData.targetEscapeRate)*60)*10)) < 0.1 ?
                                   ' people/hour' :
                                   ' people/min' :
                                   ' people/sec'
                                 }
                               </div>
                            </div>
                          </li>
                          <li className="headline-legend-item" onClick={ () => {this.props.handleModal(true, 'current_escape_rate')}}>
                            <div className="headline-legend-icon">
                              <img src={IconCurrentEscape} alt="Icon"/>
                            </div>
                            <div className="headline-legend-description">
                              <h6 className="headline-legend-heading label white">Current escape rate</h6>
                              <div className="headline-legend-score">{
                                this.props.clockData ?
                                 //Check if escape rate is < 0.1 to convert to people/min
                                 Math.abs(this.props.clockData.escapeRate) < 0.1 ?
                                 //Check if escape rate PER MIN is < 0.1 to convert to people/hour
                                 Math.abs(Math.round(((this.props.clockData.escapeRate)*60)*10)) < 0.1 ?
                                 //People per Hour if this is true
                                 Math.round(((this.props.clockData.escapeRate)*60*60)*10)/10 :
                                 //People per Minute if this is false
                                 Math.round(((this.props.clockData.escapeRate)*60)*10)/10 :
                                 //Regular People per Second
                                 Math.round((this.props.clockData.escapeRate)*10)/10 :
                                 ''
                                  }
                                 {
                                   Math.abs(this.props.clockData.escapeRate) < 0.1 ?
                                   Math.abs(Math.round(((this.props.clockData.escapeRate)*60)*10)) < 0.1 ?
                                   ' people/hour' :
                                   ' people/min' :
                                   ' people/sec'
                                 }
                                 </div>
                            </div>
                          </li>
                        </ul>
                        
                      </div>
                      <MobileFilters {...this.props} />
                    </div>
                  </div>
                  <div className="country-card-footer"><p>Based on World Data Lab’s global poverty model, outlined in detail at: <a href="https://www.nature.com/articles/s41599-018-0083-y" target="_blank" rel="noopener noreferrer">nature</a></p></div>
              </div>
              </StyledCountryCard>
              :
              ''
        )
    }
}

export const StyledCountryCard = styled.div`
.subnational-card{
  .country-card-clock{
    top: 50%;
    transform: translateY(-50%);
  }
}
.mobile{display:none}
.country-card {
    background-color: rgba(0,0,0,0.85);
    color: white;
    padding: 1em 2.75em;
    position: fixed;
    z-index: 12;
    right: 0;
    margin-top: 6.75em;
    .map-legend-item{
      width: .7em;
      height: .7em;
      margin-right: .3em;
      border-radius: 50vh;
    }
    hr{
      margin: 0;
    }
    table{
      width: 100%;
      border-collapse: collapse;
      tr{
        td{
          position: relative;
        }
      }
    }
    &-download-button{
      padding: .6em .75em;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      background-color: #9b9b9b;
      margin-top: .5em;
      cursor: pointer;
      font-size: 1em;
    }
    &-pop-table{

      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      tr{

        td{
          padding: .8em 0
        }
        &:first-child{
          border-bottom: 1px solid #fff
        }
      }
    }
    &-all-table{
      min-width: 20em;
      /*margin-top: 4em;*/
      tr:nth-child(1){
        border-bottom: 3px solid transparent
      }
      tr:nth-child(2){
        border-bottom: 1px solid #fff;
        td{
          padding-bottom: .5em;
        }
      }
      tr:nth-child(3){
        td{
          padding-top: .5em;
        }
      }
      tr{
        td{
          &:after{
            content: '';
            height: 3px;
            display: block;
            width: 1px
          }
        }
        td:nth-child(2){
          h6,h4{
            border-right: 1px solid #fff;
            padding-right: 2em;
          }

        }
      }

    }
    &-all-table-mobile{
      display: none;
    }
    &-current-table{
      margin-bottom: 1em;
      h6{
        max-width: 80%;
      }
      table{
        margin-top: 5px;
        width: 90%;
        tr{
          td:nth-child(2){
            border-left: 1px solid #fff;
            padding-left: 2em;
          }
        }
      }
    }
    &-thresholds{
      margin-top: 1.5em;
      .thresholds-selector{
        display: flex;
        margin: .5em 0 1.5em 0;
        &-item{
          display: flex;
          padding: .45em 1.5em;
          &:last-child{
            border: none !important;
          }
        }
        .selected{
          color: white;
          font-weight: bold;
          background-color: #ee4322;
        }
        .unavaliable{
          background-color: #eeeeee99;
          border-right: 2px solid #eeeeee99;
          color: #4a4a4a;
        }
      }
    }

    &-header{
      flex-wrap: wrap;
      margin-bottom: 1em;
    }
    &-content{
      display: flex;
      .headline-legend{
          margin-top: .5em;
          &-item{
            flex: none;
          }
          &-container{
            justify-content: space-around;
            margin-right: 0;
            margin-bottom: 0;
            max-width: 30em;
            margin-top: 2rem;

          }
        }
      &-expanded{
        margin-left: 4em;
        flex: 0 0 50%;
        min-width: 21em;
      }
      &-fundamentals{
        flex: 0 0 50%;
        min-width: 21em;
        &-title{
          margin-bottom: .5em
        }

      }
    }
    &-footer{
      margin-top: .5em
      p{
        color:#9b9b9b;
        font-size: .75em;
      }
      a{
        font-weight: bold;
        color: #ee4322;
      }
    }
    &-clock{
      width: 11.5em;
      height: 11.5em;
      margin-right:12em;
      position: relative;
      
      .clock{
        height: 13em !important;
        &-needle{
          display: none;
        }
      }
      overflow: hidden;
    }
    &-close-button{
      clear: both;
      float: right;
      margin-top: 1.5em;
      margin-right: 2em;
      top: 0;
      right: 0;
      cursor: pointer;
      position: absolute;
      .close{
        width: 20px
      }
    }

    .headline-legend-container{
      justify-content: flex-start;
      flex-direction: column;
      &-item{
        flex: 0;
      }
    }
    h2{
      font-family: 'Berlingske';
    }

    h2, h3{
      font-size: 1.75em;
      font-weight: bold;
    }
    h4{
      font-size: 1.75em;
    }
    h5{
      font-size: 1.25em;
    }
    h6{
      font-size: 0.75em;
      color: #9b9b9b;
      margin: 0;
      letter-spacing: 1.5px;
      font-weight: normal;
    }
    h6.white{
      color: #fff;
    }
    .circle {
    
    &-poverty-rising {
      background-color: #EE4322;
    }
    &-offtrack {
      background-color: #EBA900;
    }
    &-ontrack {
      background-color: #00A046;
    }
    &-no-extreme-poverty {
      background-color: #D7D7D7;

    }
    &-no-data {
      background-color: #fff;
      border: 1px solid #979797;
    }
    }
}
@media screen and (max-width: 768px) {
  .mobile{display: block;}
  .country-card{
    margin-top: 0;
    width: 100%;  
    z-index: 14;
    padding: 1em;
    height: 100vh;
    overflow-x: hidden;
    top: 0;
    &-content{
      flex-direction: column;
      min-height: 90vh;
      margin-top: 1em;
      &-expanded{
        margin-left: 0;
        margin-top: 1em;
      }
      &-fundamentals{
        min-width: 100%;
        width: 100%;
        flex-flow: column;
        display: flex;
        .subnational.button{
          color: black;
          margin-bottom: .5em;
          text-align: center;
        }
      }
      .country-card-clock{
        display: none;
      }
    }
    &-thresholds{
      order: 3;
      .thresholds-selector-item{
        padding: .25em 1.2em;
      }
    }
    &-pop-table{
      border-bottom: 0;
      tr:last-child{
        
      }
    }
    &-all-table{
      display: none;
    }
    &-all-table-mobile{
      display: table;
      thead{
        tr{
          width: 100%;
          th{
            text-align: left;
            font-size: 1.25rem;
            font-weight: normal;
            padding-bottom: 1em;
          }
        }
      }
      tbody{
        tr{
          &:first-child{
            border-bottom: 1px solid #fff;
          }
          td:first-child{
            padding-bottom: .75em;
            padding-top: .75em;
          }
          td:nth-child(2){
            padding-right: 1em;
          }
          td:nth-child(4){
              padding-left: 1em;
          }
      }
      }
      
    }
    &-header{
      display: none;
    }
    .headline-legend-country-card{
      margin-left: 0; 
    }
    .map-legend-item{
      display: inline-block;
    }
  }
  
}
`