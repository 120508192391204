import React, {Component} from 'react';
import styled, { keyframes } from 'styled-components';

export default class Loading extends Component {
    render() {
        return (
            <StyledLoading {...this.props}>
                <div className="loading">
                    <div className="loading-spinner">
                          <div className="loading-spinner-wheel"></div>
                    </div>
                </div>
            </StyledLoading>
        )
    }
}
Loading.defaultProps = {
    color: "#fff"
}
const rotate = keyframes`
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
`;

const StyledLoading = styled.div`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.width}px;
    position: absolute;

    .loading{
    width: ${props => props.width}px;
    height: ${props => props.width}px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    &-spinner{
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0;
        &-wheel{
            position: absolute;
            width: 70%;
            height: 70%;
            border: ${props => props.border}px solid ${props => props.color};
            border-top-color: transparent;
            border-radius: 50%;
            animation: ${rotate} 2.0408163265306123s linear infinite;
            top: 50%;
            left: 50%;
            box-sizing: content-box;
        }
    }
    }
`