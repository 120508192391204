import React, {Component} from 'react';
import styled from 'styled-components';

import {MorphTransition} from 'react-svg-morph';

class Zero extends React.Component {
    hasActiveFilters = () => {
        if (this.props.filters === undefined) {
          return;
        }
        let has = false;
        this.props.filters.forEach( x => {
          x.forEach( y => {
            if (y) has = true;
            return;
          });
          return;
        });
        return has;
    }

    color = '#ee4422';


    render() {
        if (this.hasActiveFilters()) {
            this.color = '#000';
        }


        switch(this.props.number) {
            case 0:
                return  <svg height='100px'width="100px">
                            <path fill={this.color} d="M27.37 79.05Q4.08 79.05 4.08 50.15L4.08 50.15Q4.08 21.25 27.37 21.25L27.37 21.25Q50.66 21.25 50.66 50.15L50.66 50.15Q50.66 79.05 27.37 79.05L27.37 79.05ZM27.37 67.66Q32.05 67.66 34.13 63.58Q36.21 59.50 36.21 50.15L36.21 50.15Q36.21 40.80 34.13 36.72Q32.05 32.64 27.37 32.64L27.37 32.64Q22.70 32.64 20.61 36.72Q18.53 40.80 18.53 50.15L18.53 50.15Q18.53 59.50 20.61 63.58Q22.70 67.66 27.37 67.66L27.37 67.66Z"/>
                        </svg>
            case 1:
                return  <svg height='100px'width="100px">
                            <path fill={this.color} d="M19.38 22.10L31.03 22.10L31.03 78.20L16.92 78.20L16.92 36.46Q10.37 42.41 3.66 43.60L3.66 43.60L2.30 31.53Q6.63 31.02 11.94 28.09Q17.26 25.16 19.38 22.10L19.38 22.10Z"/>
                        </svg>
            case 2:
                return  <svg height='100px'width="100px">
                            <path fill={this.color} d="M5.10 78.20L5.10 68.85Q19.30 60.01 25.63 53.17Q31.96 46.33 31.96 40.29L31.96 40.29Q31.96 36.89 30.01 34.81Q28.05 32.73 24.40 32.73L24.40 32.73Q20.40 32.73 18.15 35.66Q15.90 38.59 15.81 44.20L15.81 44.20L3.32 40.38Q3.49 31.28 9.56 26.27Q15.64 21.25 25.33 21.25L25.33 21.25Q32.39 21.25 37.15 23.84Q41.91 26.44 44.20 30.52Q46.50 34.59 46.50 39.27L46.50 39.27Q46.50 47.17 40.59 53.80Q34.68 60.44 22.70 67.32L22.70 67.32L22.70 67.41Q28.31 67.06 34 67.06L34 67.06L47.18 67.06L47.18 78.20L5.10 78.20Z"/>
                        </svg>
            case 3:
                return  <svg height='100px'width="100px">
                            <path fill={this.color} d="M25.42 21.25Q34.85 21.25 40.12 25.50Q45.39 29.75 45.39 36.98L45.39 36.98Q45.39 41.31 43.56 44.24Q41.74 47.17 39.23 48.53Q36.72 49.89 34.85 49.89L34.85 49.89L34.85 50.15Q37.23 50.15 39.91 51.38Q42.59 52.62 44.54 55.46Q46.50 58.31 46.50 62.90L46.50 62.90Q46.50 67.66 43.69 71.31Q40.89 74.97 35.96 77.01Q31.03 79.05 24.74 79.05L24.74 79.05Q15.73 79.05 10.33 75.73Q4.93 72.42 2.64 65.11L2.64 65.11L15.90 59.42Q16.32 63.84 18.49 65.79Q20.66 67.75 24.57 67.75L24.57 67.75Q28.22 67.75 30.30 66.00Q32.39 64.26 32.39 61.12L32.39 61.12Q32.39 57.80 30.39 56.36Q28.39 54.91 24.06 54.91L24.06 54.91L20.91 54.91L20.91 45.65L23.38 45.65Q27.46 45.65 29.33 43.99Q31.20 42.33 31.20 38.93L31.20 38.93Q31.20 35.87 29.45 34.21Q27.71 32.55 24.57 32.55L24.57 32.55Q17.43 32.55 16.75 40.12L16.75 40.12L3.91 35.78Q5.27 28.30 11.01 24.78Q16.75 21.25 25.42 21.25L25.42 21.25Z"/>
                        </svg>
            case 4:
                return  <svg height='100px'width="100px">
                            <path fill={this.color} d="M52.53 55.84L52.53 65.88L43.95 65.88L43.95 78.20L31.03 78.20L31.03 65.88L2.81 65.88L2.81 58.99L26.86 22.10L43.95 22.10L43.95 55.84L52.53 55.84ZM26.27 40.88L16.41 55.84L31.03 55.84L31.03 40.88L31.37 31.70L31.20 31.70Q29.67 35.53 26.27 40.88L26.27 40.88Z"/>
                        </svg>
            case 5:
                return  <svg height='100px'width="100px">
                            <path fill={this.color} d="M28.73 41.99Q34.17 41.99 38.12 44.16Q42.08 46.33 44.20 50.19Q46.33 54.06 46.33 58.99L46.33 58.99Q46.33 65.62 43.44 70.13Q40.55 74.63 35.62 76.84Q30.69 79.05 24.48 79.05L24.48 79.05Q16.83 79.05 11.48 76.42Q6.12 73.78 2.55 67.83L2.55 67.83L15.39 60.52Q16.23 64.26 18.28 66.13Q20.32 68 24.57 68L24.57 68Q28.56 68 30.86 65.96Q33.15 63.92 33.15 60.01L33.15 60.01Q33.07 56.19 30.94 54.14Q28.82 52.11 24.57 52.11L24.57 52.11Q18.45 52.11 15.64 55.42L15.64 55.42L4.59 53.80L8.16 22.10L43.18 22.10L43.18 33.41L18.79 33.41L17.51 43.95Q17.26 45.39 17.09 45.98L17.09 45.98L17.17 45.98Q18.87 44.12 21.93 43.05Q24.99 41.99 28.73 41.99L28.73 41.99Z"/>
                        </svg>
            case 6:
                return  <svg height='100px'width="100px">
                            <path fill={this.color} d="M28.90 21.25Q44.20 21.25 48.45 33.66L48.45 33.66L36.47 39.27Q34.68 32.81 27.97 32.81L27.97 32.81Q23.21 32.81 20.23 37.27Q17.26 41.73 17.26 49.30L17.26 49.30L17.26 50.66L17.43 50.66Q18.87 47.09 22.44 45.18Q26.01 43.27 31.03 43.27L31.03 43.27Q36.21 43.27 40.38 45.43Q44.54 47.60 46.88 51.55Q49.22 55.51 49.22 60.69L49.22 60.69Q49.22 69.19 43.52 74.12Q37.83 79.05 27.63 79.05L27.63 79.05Q16.32 79.05 10.29 71.66Q4.25 64.26 4.25 50.15L4.25 50.15Q4.25 40.55 7.44 34.04Q10.63 27.54 16.15 24.39Q21.68 21.25 28.90 21.25L28.90 21.25ZM27.88 53.13Q24.23 53.13 22.06 55.12Q19.89 57.12 19.89 60.61L19.89 60.61Q19.89 64.09 22.06 66.09Q24.23 68.09 27.88 68.09L27.88 68.09Q31.71 68.09 33.79 66.13Q35.87 64.17 35.87 60.61L35.87 60.61Q35.87 57.03 33.79 55.08Q31.71 53.13 27.88 53.13L27.88 53.13Z"/>
                        </svg>
            case 7:
                return  <svg height='100px'width="100px">
                            <path fill={this.color} d="M3.57 22.10L49.73 22.10L49.73 32.47Q39.70 42.16 35.53 53.30Q31.37 64.43 31.37 78.20L31.37 78.20L16.92 78.20Q16.92 65.37 21.38 54.36Q25.84 43.35 35.45 33.58L35.45 33.58L3.57 33.58L3.57 22.10Z"/>
                        </svg>
            case 8:
                return  <svg height='100px'width="100px">
                            <path fill={this.color} d="M26.61 21.42Q36.38 21.42 42.03 25.63Q47.69 29.84 47.69 37.15L47.69 37.15Q47.69 41.48 45.35 44.67Q43.01 47.86 38.76 49.47L38.76 49.47L38.76 49.55Q43.95 51.26 46.71 54.74Q49.47 58.23 49.47 63.33L49.47 63.33Q49.47 70.64 43.39 74.84Q37.32 79.05 26.61 79.05L26.61 79.05Q16.07 79.05 9.95 74.84Q3.83 70.64 3.83 63.33L3.83 63.33Q3.83 58.23 6.59 54.74Q9.35 51.26 14.54 49.55L14.54 49.55L14.54 49.47Q10.29 47.86 7.95 44.67Q5.61 41.48 5.61 37.15L5.61 37.15Q5.61 29.84 11.26 25.63Q16.92 21.42 26.61 21.42L26.61 21.42ZM26.61 32.05Q23.12 32.05 21.17 33.74Q19.21 35.45 19.21 38.51L19.21 38.51Q19.21 41.48 21.21 43.22Q23.21 44.97 26.61 44.97L26.61 44.97Q30.09 44.97 32.09 43.22Q34.09 41.48 34.09 38.51L34.09 38.51Q34.09 35.45 32.09 33.74Q30.09 32.05 26.61 32.05L26.61 32.05ZM26.61 68.42Q30.69 68.42 32.98 66.56Q35.28 64.69 35.28 61.45L35.28 61.45Q35.28 58.14 32.98 56.31Q30.69 54.48 26.61 54.48L26.61 54.48Q22.61 54.48 20.32 56.31Q18.02 58.14 18.02 61.45L18.02 61.45Q18.02 64.69 20.32 66.56Q22.61 68.42 26.61 68.42L26.61 68.42Z"/>
                        </svg>
            case 9:
                return  <svg height='100px'width="100px">
                            <path fill={this.color} d="M25.33 21.25Q36.55 21.25 42.54 28.73Q48.54 36.21 48.54 50.49L48.54 50.49Q48.54 65.11 42.37 72.08Q36.21 79.05 24.74 79.05L24.74 79.05Q17 79.05 11.86 76.08Q6.72 73.10 4.17 66.81L4.17 66.81L17.26 61.63Q18.02 64.77 19.76 66.39Q21.51 68 24.99 68L24.99 68Q35.36 68 35.36 52.11L35.36 52.11L35.36 50.75L35.11 50.75Q34 54.31 30.26 56.23Q26.52 58.14 21.68 58.14L21.68 58.14Q16.66 58.14 12.50 55.93Q8.33 53.72 5.91 49.64Q3.49 45.56 3.49 40.29L3.49 40.29Q3.49 31.37 9.22 26.31Q14.96 21.25 25.33 21.25L25.33 21.25ZM24.91 32.73Q21.08 32.73 19.00 34.68Q16.92 36.63 16.92 40.20L16.92 40.20Q16.92 43.77 19.00 45.73Q21.08 47.69 24.91 47.69L24.91 47.69Q28.65 47.69 30.77 45.69Q32.90 43.69 32.90 40.20L32.90 40.20Q32.90 36.72 30.77 34.72Q28.65 32.73 24.91 32.73L24.91 32.73Z"/>
                        </svg>

            default:
            return null;
        }
    }
}


class Human extends React.Component {
    render() {
        if (this.props.gender === 'male') {
        return (
          <svg width="20" id="man" fill={this.props.fell? "red" : "black"} viewBox="0 0 47.35 253.39">
              <g>
                <path d="M30.38,67c0,11.24-.14,21.07,0,30.6.14,11.68,1,23.49,1.14,34.88,0,3.7-1,7.54-1,11.24,0,3.14,1.14,6.13,1.43,9.4.85,9.11,1.42,18.22,2.13,27.61,1.43,14.24-2,28.05-5.12,41.86-2.14,9.39-4,18.64-6,27.75-.57,2.57-1.57,3.56-4.41,3-3-.57-5.7-1-8.69-1.42-1.85-.14-2.56-.85-2.13-2.85,1.14-8,2.42-15.66,3.41-23.63,1.57-12.67,5.56-25.19,5-38.29-.15-7.12-1.57-14.52-2.57-21.64-.42-2.84-.57-5.55-1-8.11-.14-.85,0-2-.57-2.42-2.85-1.57-4-4.56-5.41-7.12-.43-.57-1.14-1.14-1.14-2-1.85-10.68-3.7-21.64-5.12-32.31-.43-2.42.85-5,1.13-7.55.43-4,.43-8.11,1.43-12.1C5,84.21,2.77,74.82.77,65.28c-1.85-8.68.57-12.24,7-15.94,7.12-4,15.66-2.57,20.07,3a9.93,9.93,0,0,1,2,5.41A62.49,62.49,0,0,1,30.38,67Z" transform="translate(-0.17 -0.08)"/>
                <path d="M26,.08A21.71,21.71,0,0,1,47,17.31a23.31,23.31,0,0,1-12,25.19c-6,3.14-12.1,3.14-18.22,1.57-3-.85-5.7-3.56-7.69-6.12-6.4-7.55-7.69-16.51-4-25.62C7.75,5.64,17.28.08,26,.08Z" transform="translate(-0.17 -0.08)"/>
              </g>
          </svg>
        )
        } else {
        return (
            <svg width="20" id="woman" fill={this.props.fell? "red" : "black"} viewBox="0 0 47.35 253.39">
              <g>
                <path d="M42.56,251.64c1-6.55,1.85-13,2.56-19.08,2-13.66,4.42-27.19,6-40.85.57-4-.42-8.4-.85-12.53-.85-7.12-1.57-14.52-2.56-21.64-.15-1.85-.15-4-3-4.13-.43,0-1-.85-1.14-1.13a54.3,54.3,0,0,1-5.13-16.23c-1-7.4-2-14.52-3-21.78a16,16,0,0,1,.15-4.56c2.84-7,1.56-14.23,3-21.49,1.14-6.55-1-12.67-2-19.08a72.66,72.66,0,0,1-1.14-7.54C35,56.47,38,53.06,42,50.64c6.55-4.13,15.23-3.56,20.22,1.56,1.42,1.57,2.84,4,2.84,6.13.43,7.4-.14,14.94,0,22.2.43,10.68,1.57,21.21,1.57,31.75s-.85,21.21-1,31.74c0,2.56,1,5.12,1.43,7.69.85,9.39,1.42,18.5,2.42,27.75,1.56,14.67-2,28.62-5.13,42.71-2,9.11-4,18.08-5.69,27.19-.57,3-1.85,4.55-5.13,3.56-3.27-.57-6.83-.72-11-1.28Z" transform="translate(0 -0.08)"/>
                <path d="M37.86,13.77c-1,2-2,3.7-3,5.69-1.57,2.85-2.85,6-4.7,8.55C23.2,37.68,6.69,37,0,25.44c2.14.86,4,1.14,5.69,2,6,2.42,11.68-.86,13.1-7.12a30.56,30.56,0,0,1,2.85-8.4c3.13-5.69,12.24-8.4,18.22-5,2.13,1.14,3,.85,5-.57C52.24.67,60.5-2.17,69.61,2.24c8.54,4,12.67,11,12.67,20.07C82.28,31.85,77.72,39,69,43.38a22.49,22.49,0,0,1-17.65,1c-5.55-2-9.54-7.12-11.67-13.1a26.42,26.42,0,0,1-1-15.23,4.9,4.9,0,0,0,0-1.43c0-.42-.43-.57-.86-.85Z" transform="translate(0 -0.08)" />
              </g>
            </svg>
            )
        }
    }
}

export default class Morph extends Component {

  constructor(props) {
        super(props);

        this.state = {
            render: true,
            //x: 0,
        }

    }
    speed = 0.25;
    change = false;
    transition = 1;
    x = 0;
    scale = 5;
    morphInterval = '';
    morphTimeout = '';
    
    componentWillUnmount() {
        clearInterval(this.morphInterval);
        clearTimeout(this.morphTimeout);
    }
    componentDidMount(){
        this.change = true;
        this.getEmValue("morph") //get em value in pixels to resize morph

        this.morphTimeout = setTimeout(() => { //stop rendering the icon
           this.setState({render: false});
        }, 1100)
        this.morphInterval = setInterval(() => {
            if (!this.state.render) { return }
            this.x = this.x + this.speed;//make the morph move horizontally

            if (this.transition >= 90) {//control the svg transition
                this.transition = 100
            } else {
                this.transition = this.transition * 1.1382;
            }

            this.scale = this.scale * 0.948 //control the scale to match number size

            this.forceUpdate();
        }, 30)
    }

    em = 0;
    getEmValue(id){
        var div = document.getElementById(id);
        div.style.height = '1em';
        this.em = div.offsetHeight;
    }

    render() {
        const styles = {
            transform: `translate(${this.x}em)`,
            height: `${this.scale*2.2 + 4}em`,
        };

        return (
            <StyledMorph id="morph" style={styles}>
                {this.state.render
                ?<MorphTransition rotation={'none'} progress={this.transition} width={4*this.em} height={3*this.scale*this.em}>
                    {{from: <Zero number={this.props.person.number} {...this.props}/>, to: <Human gender={this.props.person.gender} fell={this.props.person.fell} key="man" />}}
                </MorphTransition>
                : ''
                }

            </StyledMorph>
        );
    }
}
const StyledMorph = styled.button`
    position: absolute;


`
