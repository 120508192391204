import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class ScrollPage extends Component {


  static defaultProps = {
    delay: 1200
  }

  constructor(props) {
    super(props);
    this.state = {
      curPage: this.props.curPage,
      totalPage: this.props.totalPage,
    };
    this.onPageChange = this.props.onPageChange;
  }

  componentDidMount() {
    if (window.matchMedia("(min-width: 768px)").matches) {
      this.resize();
      this.addWheelEvent();
      window.onresize = document.onresize = this.resize.bind(this);
      window.turnTo = document.turnTo = this.turnTo.bind(this);
    }else{
      window.turnTo = document.turnTo = function(){};
    }
    
    
  }

  handle(delta) {
    if (delta < 0) {
      if (this.state.curPage < this.state.totalPage) {
        this.turnTo(this.state.curPage + 1);
        window.curPage = this.state.curPage;
      }
    } else {
      if (this.state.curPage > 1) {
        this.turnTo(this.state.curPage - 1);
        window.curPage = this.state.curPage;
      }
    }
  }

  wheel(event) {
    if(this.props.isAllowedTransition){
      let delta = 0;

      if (event.wheelDelta) {
        delta = event.wheelDelta / 120;
        if (window.opera) delta = - delta;
      } else if (event.detail) {
        delta = - event.detail / 3;
      }

      if (delta) {
        this.handle(delta);
      }
    }
  }

  resize() {
    const translatey = `translatey(-${window.innerHeight * (this.state.curPage - 1)}px)`;
    for (let i = 0; i < this.state.totalPage; i++) {
      document.getElementsByClassName('scroll-section')[i].style.transform = translatey;
      document.getElementsByClassName('scroll-section')[i].style.height = `${window.innerHeight}px`;
    }
  }

  removeWheelEvent() {
    window.removeEventListener('DOMMouseScroll', this.wheel.bind(this), false);
    window.onmousewheel = document.onmousewheel = '';
  }

  addWheelEvent() {
    window.addEventListener('DOMMouseScroll', this.wheel.bind(this), false);
    window.onmousewheel = document.onmousewheel = this.wheel.bind(this);
  }
  isRunning = false;
  
  turnTo(num) {
      
      if(!this.isRunning){
        this.isRunning = true;
        this.setState({ curPage: num },()=>{
          this.onPageChange && this.onPageChange(this.state.curPage);
        });
        const translatey = `translatey(-${window.innerHeight * (num - 1)}px)`;
        const length = document.getElementsByClassName('scroll-section').length;
        for (let i = 0; i < length; i++) {
          document.getElementsByClassName('scroll-section')[i].style.transform = translatey;
        }
        this.removeWheelEvent();
        if(num === 2){
          this.props.history.push('/map');
          this.props.toggleMap();
        }
        setTimeout(() => {
          this.addWheelEvent();
          this.isRunning = false;
        }, this.props.delay);
      }
  }

  render() {
    return (
      <div style={
        window.matchMedia("(min-width: 768px)").matches ? 
        {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'fixed',
        } :
        {}
    }>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(ScrollPage);
