import React from 'react';
import styled from 'styled-components';

export default function ClockLegend(props) {
  let startAngle = props.startAngle || 0;
  let currAngle = props.currAngle || 0;
  let showPercentage = false;
  let percentage = '';
  if (props.filters === undefined) {
    showPercentage = false;
  }
  if (props.filters !== undefined) {
    props.filters.forEach( x => {
      x.forEach( y => {
        if (y) showPercentage = true;
      });
    });
  }

  let styles = {};

  if (!showPercentage) {
    styles = {transform: `rotate(${ (1  - startAngle / 2 - currAngle) - ( (props.clockData.headcountInPoverty - props.clockData.offTrackHeadcount) / props.clockData.population)}turn)`}
  } else {
    styles = {transform: `rotate(${ (1  - startAngle / 2 - currAngle) + ( (props.clockData.headcountInPoverty  / props.clockData.population) * (1/2) ) }turn)`}
  }
  function getPercentageOutOf(){

    if(!props.clockData){return}else{
      if(!props.filters[1][2]){
        percentage = Math.round( 100 * (props.clockData.headcountInPoverty / props.clockData.population)).toString();
      }else{
        percentage = Math.round( 100 * (props.clockData.headcountInAgeGroup / props.clockData.population)).toString();
      }
    }
    let middleString = '';
    if (props.filters === undefined) { middleString = 'of the world\'s population' }
    if (props.filters[0][0]) { middleString = "of the world's rural population" }
    if (props.filters[0][1]) { middleString = 'of the world\'s urban population' }
    if (props.filters[1][0] && !props.filters[1][1] && !props.filters[1][2]) { middleString = 'of the world\'s female population'}
    if (props.filters[1][1] && !props.filters[1][0] && !props.filters[1][2]) { middleString = 'of the world\'s male population' }
    if (props.filters[1][0] && !props.filters[1][1] && props.filters[1][2]) { middleString = 'of the world\'s female population in selected age range ' }
    if (props.filters[1][1] && !props.filters[1][0] && props.filters[1][2]) { middleString = 'of the world\'s male population in selected age range ' }
    if (!props.filters[1][1] && !props.filters[1][0] && props.filters[1][2]) { middleString = 'of the world\'s population in selected age range ' }
    return <p className="clock-legend-percentage">{percentage}%<small> {middleString}</small></p>;
  }
  return (
    <StyledClockLegend
        {...props}
        className="clock-legend"
        style={styles}
        percentage={percentage}
    >
      {showPercentage
        ? getPercentageOutOf()
        : ''
      }
      <div className="clock-legend-color"></div>
      <div className="clock-legend-description">

        <p className="clock-legend-heading label">"Off-track" SDG1</p>
        <p className="clock-legend-score">{ props.clockData ? Math.round(props.clockData.offTrackHeadcount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</p>


      </div>
    </StyledClockLegend>
  )
}
//if( this.props.filters[1][2] ) if using headcountInAgeGroup instead of headcount
const StyledClockLegend = styled.div`
  display: flex;
  white-space: nowrap;

  .clock-legend {
    &-color {
      flex: none;
      height: 2.5em;
      width: 2.5em;
      margin-right: 1em;
      background-color: #EE4422;
      border-radius: 50vw;
    }

    &-heading {
      font-size: 87.5%;
    }

    &-score {
      font-size: 112.5%;
      font-weight: bold;
      color: #EE4422;
    }

    &-percentage {
      font-size: 175%;
      font-weight: bold;
      border-top: 1px black;
      border-bottom: 1px black;
      width: 100%;
      position: absolute;
      margin-top: ${percentage => percentage < 8 ? `-1.5em` : `-2.2em`};
      border-bottom: 1px solid;
      padding-bottom: .3em;
      padding-left: .3em;
      font-family: 'Berlingske';
      small{
        font-size: 50%;
        font-family: 'Work Sans';
        font-weight: 300;
      }
    }
  }

`
