import React, {Component} from 'react';
import styled from 'styled-components';

export default class Counter extends Component {

  hasActiveFilters = () => {
    if (this.props.filters === undefined) {
      return;
    }
    let has = {color: '#EE4422'};
    this.props.filters.map( x => {
      x.map( y => {
        if (y) has = {color: 'black'};
        return y;
      });
      return x;
    });
    return has;
  }

  render() {
    return (
      <StyledCounter className="counter" style={this.hasActiveFilters()}>
        {this.props.clockData? Math.round(this.props.clockData.headcountInPoverty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}
      </StyledCounter>
    )
  }
}


const StyledCounter = styled.div`
  font-size: 5em;
  font-weight: bold;
`

// function format(num) {
//   return num.toLocaleString();
// }
