import React, {Component} from 'react';
import Loading from './Loading';
import Clock from '../components/Clock';
import {StyledCountryCard} from './CountryCard';
import Close from '../_/img/close.svg';

import IconCurrentEscape from '../_/img/icon-current-escape.svg';
import IconTargetEscape from '../_/img/icon-target-escape.svg';

export default class StateCard extends Component {
    regionData = {
        name: '',
        population: 0,
        headcountInPoverty: 0,
        currentEscapeRate: 0,
        targetEscapeRate: 0,
        stableTrack: ''
    }

    getRegionCardData = () => {
        this.props.subnationalData.features.forEach ( (feature) => {
          
            if (feature.properties.name === this.props.currentRegion) {
              
                this.regionData.name = feature.properties.name;
                this.regionData.population = feature.properties.population;
                this.regionData.headcountInPoverty = feature.properties.headcount_in_poverty;
                this.regionData.escapeRate = feature.properties.escape_rate;
                this.regionData.targetEscapeRate = feature.properties.target_escape_rate;
                this.regionData.stableTrack = feature.properties.stable_track;
            }
        });
    }
    
    getTrack(){
        switch(this.regionData.stableTrack){
          case 'offtrack':
            return 'map-legend-item circle-offtrack'
            
          case 'ontrack':
            return 'map-legend-item circle circle-ontrack'
            
          case 'rising':
            return 'map-legend-item circle circle-poverty-rising'
            
          case 'nopoverty':
            return 'map-legend-item circle circle-no-extreme-poverty'
            
          default:
            return 'map-legend-item circle circle-no-extreme-poverty'
            
        }
    }
    render() {
        this.getRegionCardData()

        return (

            this.props.showCountryCard === true ?
              <StyledCountryCard>
              <div className='country-card subnational-card'>
                 
                  <button className="country-card-close-button" onClick={ () => { this.props.handleCountryCard(false) } }><img className="close" src={Close} alt="Close" /></button>
                  <div className="country-card-header">
                    <button className="country-card-download-button" onClick={ () => {this.props.handleModal(true, 'infocapture_subnationalstatecard-'+this.props.currentRegion)}}>Request data</button>
                  </div>
                  <div className="country-card-content">
                    <div className="country-card-content-fundamentals">
                        <h2 className="serif country-card-content-fundamentals-title">
                        <div className={this.getTrack()}></div>
                        {this.props.currentRegion}
                        </h2>

                        <table className="country-card-pop-table">
                          <tbody>
                            <tr>
                              <td><h6>TOTAL POPULATION</h6></td>
                              <td><h5 className="align-right bold">{this.regionData.population !== '' ? 
                              Math.round(this.regionData.population).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 
                              <Loading width="15" border="2"></Loading>}</h5></td>
                            </tr>
                            <tr>
                              <td><h6>PEOPLE LIVING IN<br />EXTREME POVERTY</h6></td>
                              <td><h5 className=" align-right red bold">{ this.regionData.population !== '' ? 
                              Math.round(this.regionData.headcountInPoverty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 
                              <Loading width="15" border="2"></Loading>}</h5></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="country-card-thresholds">
                          <h6>POVERTY THRESHOLDS</h6>
                          <div className="thresholds-selector">
                            <div className="thresholds-selector-item selected" >2.15 $</div>

                            <div className="thresholds-selector-item unavaliable" onClick={ () => {this.props.handleModal(true, 'infocapture_dollarincrement320-subnational'+this.props.currentRegion)}}>3.65 $</div>
                            <div className="thresholds-selector-item unavaliable" onClick={ () => {this.props.handleModal(true, 'infocapture_dollarincrement550-subnational'+this.props.currentRegion)}}>6.85 $</div>
                          </div>
                        </div>
                        <div className="headline-legend headline-legend-country-card">
                        <ul className="headline-legend-container">
                          <li className="headline-legend-item" onClick={ () => {this.props.handleModal(true, 'target_escape_rate')}} >
                            <div className="headline-legend-icon">
                              <img src={IconTargetEscape} alt="Icon"/>
                            </div>
                            <div className="headline-legend-description">
                              <h6 className="headline-legend-heading label white" >Target escape rate</h6>
                              <div className="headline-legend-score">
                              {
                               this.props.currentYear === 2031 || this.getTrack() === 'map-legend-item circle circle-no-extreme-poverty' ?
                                ' — '
                               : 
                               this.regionData ?
                                 //Check if escape rate is < 0.1 to convert to people/min
                                 Math.abs(this.regionData.targetEscapeRate) < 0.1 ?
                                 //Check if escape rate PER MIN is < 0.1 to convert to people/hour
                                 Math.abs(Math.round(((this.regionData.targetEscapeRate)*60)*10)) < 0.1 ?
                                 //People per Hour if this is true
                                 Math.round(((this.regionData.targetEscapeRate)*60*60)*10)/10 :
                                 //People per Minute if this is false
                                 Math.round(((this.regionData.targetEscapeRate)*60)*10)/10 :
                                 //Regular People per Second
                                 Math.round((this.regionData.targetEscapeRate)*10)/10 :
                                 ''
                                }
                                {
                                   Math.abs(this.regionData.targetEscapeRate) < 0.1 ?
                                   Math.abs(Math.round(((this.regionData.targetEscapeRate)*60)*10)) < 0.1 ?
                                   ' people/hour' :
                                   ' people/min' :
                                   ' people/sec'
                                 }
                               </div>
                            </div>
                          </li>
                          <li className="headline-legend-item" onClick={ () => {this.props.handleModal(true, 'current_escape_rate')}}>
                            <div className="headline-legend-icon">
                              <img src={IconCurrentEscape} alt="Icon"/>
                            </div>
                            <div className="headline-legend-description">
                              <h6 className="headline-legend-heading label white">Current escape rate</h6>
                              <div className="headline-legend-score">{
                                this.regionData ?
                                 //Check if escape rate is < 0.1 to convert to people/min
                                 Math.abs(this.regionData.escapeRate) < 0.1 ?
                                 //Check if escape rate PER MIN is < 0.1 to convert to people/hour
                                 Math.abs(Math.round(((this.regionData.escapeRate)*60)*10)) < 0.1 ?
                                 //People per Hour if this is true
                                 Math.round(((this.regionData.escapeRate)*60*60)*10)/10 :
                                 //People per Minute if this is false
                                 Math.round(((this.regionData.escapeRate)*60)*10)/10 :
                                 //Regular People per Second
                                 Math.round((this.regionData.escapeRate)*10)/10 :
                                 ''
                                  }
                                 {
                                   Math.abs(this.regionData.escapeRate) < 0.1 ?
                                   Math.abs(Math.round(((this.regionData.escapeRate)*60)*10)) < 0.1 ?
                                   ' people/hour' :
                                   ' people/min' :
                                   ' people/sec'
                                 }
                                 </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                    </div>
                    <div className="country-card-content-expanded">
                      <div className="country-card-clock">
                          {this.props.renderCountryChart ? 
                            <Clock clockData={this.regionData} filters={[[true],[true]]} refreshing={false} isCountryCard={true} outerCircleColor={'white'} renderChart={this.props.renderChart} currentRegion={this.props.currentRegion} subnationalClock={true}   ></Clock>
                            : 
                            <Loading width="50" border="5"></Loading>
                            }
                        </div>
                    </div>
                  </div>
                  <div className="country-card-footer"><p>Based on World Data Lab’s global poverty model, outlined in detail at: <a href="https://www.nature.com/articles/s41599-018-0083-y" target="_blank" rel="noopener noreferrer">nature</a></p></div>
              </div>
              </StyledCountryCard>
              :
              ''
        )
    }
}

