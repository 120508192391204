import React, {Component} from "react";
import {LogoIcon} from '../assets/Logo';
import Socials from '../components/Socials';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

export default class Menu extends Component {
    
    render(){
        return(
            <StyledMenu>
            <div className="menu">
                
                <div className="menu-header">  
                    <div className="menu-header-logo">
                        <div className="menu-header-logo-icon">{LogoIcon}</div>
                        <div className="menu-header-logo-title">World Poverty Clock</div>
                    </div>
                </div>
                <div className="menu-content">
                <div className="menu-content-sides">
                    <div className="menu-content-sides-article">
                    <h6>About WDL</h6>
                    <h1>Make Everyone Count with Groundbreaking Data</h1>
                    <p>World Data Lab is driven by an appetite for innovation. We push the boundaries of what is knowable and actionable. Granular economic and demographic forecasts—at any point in time—are our specialty.</p>
                    <Link to="/about">Find out more</Link>
                    </div>
                    <div className="mobile">
                    <Socials />
                    </div>
                    <ul>
                    <li><Link to="/methodology">Methodology</Link></li>
                    <li><a href="https://worlddata.io/blog/?category=world-poverty">Blog</a></li>
                    <li className="mobile"><a href="https://worlddata.io/news/in-the-news">Press</a></li>
                    {/*<li className="mobile"><a href="https://worlddata.io/index.php/blog?cat=6">Help Expand WPC</a></li>*/}
                    </ul>
                </div>
                <div className="menu-content-sides">
                    <div className="menu-content-sides-article">
                        <h6>Get Involved</h6>
                        <h1>Help fund expansion of World Poverty Clock</h1>
                        <p>Help us create the data necessary to end extreme poverty. We are interested in partnering with companies and organizations to create state-of-the-art poverty models and forecasts at a highly granular level.</p>
                        <Link onClick={ () => {this.props.handleModal(true, 'infocapture_helpfund')}}>Get in touch</Link>
                    </div>                  
                    
                </div>
                </div>
                <div className="menu-footer">
            <ul>
                <li><a href="https://www.linkedin.com/company/world-data-lab/">LinkedIn</a></li>
                <li><a href="https://twitter.com/worlddatalab">Twitter</a></li>
                <li><a href="https://worlddata.io/index.php/press-media">Press</a></li>
                {/*<li><Link onClick={ () => {this.props.handleModal(true, 'infocapture_newslettersubscribe')}}>Newsletter</Link></li>*/}
                {/*<li><a href="#">Legal</a></li>*/}
            </ul>
            </div>
            </div>
            </StyledMenu>
        );
    }
}

export const StyledMenu = styled.div`
    
   
    h1{
        font-family: 'Berlingske';
        font-size: 2em;
        
    }
    h6{
        text-transform: uppercase;
        font-weight: 100;
        letter-spacing: 2px;
        font-size: .9em;
        margin-bottom: 1em;
    }
    p{
        margin: 2em 0 1em 0;
    }
    
    .menu{
        width: 100%;
        height: 100vh;
        padding: 1.5em 3.5em;
        background-color: black;
        color: white;
        &-header{
            &-logo{
                display: flex;
                &-icon{
                    width: 4rem;
                    margin-right: 1em;
                    margin-left: 4.5em;
                }
                &-title{
                    font-size: 1em;
                    letter-spacing: 2px;
                    padding-top: .75em;
                    text-transform: uppercase;
                }
            }
        }
        &-content{
            display: flex;
            
            padding: 5em 2.5em;
            &-sides{
                flex-basis: 0;
                flex-grow: 1;
                padding: 2em;
                &:last-child{
                    border-left:1px solid #fff;
                    padding-left: 3.5em;
                }
                &-article{
                    a{
                        color: #ee4322;
                        border-bottom: 1px solid #fff;
                        padding-bottom: 6px;
                        letter-spacing: 2px;
                    }
                }
                ul{
                    margin-top: 6em;
                    li{
                        
                        margin: .5em 0;
                        a{
                            text-transform: uppercase;
                            letter-spacing: 2px;
                        }
                    }
                }
            }
            
            
            
        }
        &-footer{
            ul{
                display: flex;
                padding-left: 4.5em;
                letter-spacing: 2px;
                li{
                    margin-right: 1.25em;
                }
            }
        }
    }
    .mobile{
        display: none;
    }
    @media screen and (max-width: 768px) {
        .menu{
            padding: 0;
            overflow-y: scroll;
            overflow-x: hidden;
            &-header{
                padding: 0;
                padding-top: .85em;
                padding-bottom: .5em;
                margin-bottom: 1em;
                &-logo{
                    &-title{
                        padding-top: .35em;
                        margin-left: 1em; 
                    }
                    &-icon{
                        margin-left: 2.25em;
                        margin-right: 0;
                        width: 3rem;
                    }
                }
            }
            &-content{
                padding: 0;
                &-sides{
                    width: 100%;
                    &:last-child{
                        border: none;
                    }
                    .socials{
                        margin-top: 6rem;
                    }
                    ul{
                        margin-left: -2em;
                        width: 100vw;
                        margin-top: 4rem;
                        li{
                            padding: 1rem 0 1rem 3em;
                            margin-bottom: 0;
                            margin-top: -1px;
                            border-top: 1px solid #fff;
                            border-bottom: 1px solid #fff;
                        }
                    }
                }
            }
            &-footer{
                    display: none;
                }
        }
        .mobile{
            display: block;
        }
    }
    @media screen and (max-width: 360px){
        .menu-header-logo-title{
            font-size: .85em;
        }
    }
    @media screen and (max-width: 330px){
        .menu-header-logo-title{
            font-size: .7em;
        }
    }
`