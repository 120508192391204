import React, {Component}  from 'react';
import Spritesheet from 'react-responsive-spritesheet';
import styled from 'styled-components';

import {manInit, manRun, redManInit, redManRun, womanInit, womanRun, redWomanInit, redWomanRun} from '../_/animations'

export default class Person extends Component {
    constructor(props) {
      super(props);
      this.state = {
        x: 8,
        stop: false,
        personType: manRun,
        startPersonType: manInit,
        run: false,
        move: false,
      }
    }
    speed = 0.25;
    framesStanding = 19;

    _isMounted = false;
    peopleInterval = ''
    peopleTimeout = ''
    runningTimeout = ''
    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.peopleInterval)
        clearTimeout(this.peopleTimeout);
        clearTimeout(this.runningTimeout);
      }

    componentDidMount(){
        this._isMounted = true;

        this.startClockTick();

        if (this.props.person.gender === 'female') {
            if(!this.props.person.fell) {
                this.setState({personType: womanRun})
                this.setState({startPersonType: womanInit})
            } else {
                this.setState({personType: redWomanRun})
                this.setState({startPersonType: redWomanInit})
            }
        }
        if (this.props.person.gender === 'male') {
            if(!this.props.person.fell) {
                this.setState({personType: manRun})
                this.setState({startPersonType: manInit})
            } else {
                this.setState({personType: redManRun})
                this.setState({startPersonType: redManInit})
            }
        }

    }


    startClockTick(){
        this.peopleInterval = setInterval(() => {
            if (this.state.stop || !this.state.move || !this._isMounted) { return }
            this.setState({x: this.state.x + this.speed})
        }, 30)

        this.peopleTimeout = setTimeout( () => {
            if (!this._isMounted) { return }
            this.setState({move: true})
        }, 30 * 7);

        this.runningTimeout = setTimeout( () => {
            if (!this._isMounted) { return }
            this.setState({run: true})
        }, (this.framesStanding)* 30);


    }


    render(){

        const styles = {
            transform: `translate(${this.state.x}em)`,
        };


        return(<StyledPerson>
            {this.state.stop
                ? ''
                :<Spritesheet
                    style={styles}
                    image={this.state.run? this.state.personType : this.state.startPersonType}
                    widthFrame={216/2}
                    heightFrame={162/2}
                    steps={19}
                    fps={30}
                    autoplay={true}
                    loop={true} />
                /**/
            }
        </StyledPerson>)
    }
}
const StyledPerson = styled.div`
    height: 6em;
    width: 6em;
    position: absolute;
    animation:         fadeout 1.5s infinite; /* IE 10+, Fx 29+ */
    animation-delay: 1s;

`
