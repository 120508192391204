import React, {Component}  from 'react';
import styled from 'styled-components';
import {CSSTransition} from 'react-transition-group';




export default class Tooltip extends Component {
    render () {
        return (
            <StyledTooltip>
                <CSSTransition in={this.props.hasActiveFilter} timeout={500000} classNames="fade">
                    <div className={`tooltip ${this.props.hasActiveFilter ? 'show' : ''}`}> {this.props.context ? 'The demographic selection is independent from rural/urban aggregation' : 'The Rural/Urban selection is independent from demographic information'} </div>
                </CSSTransition>
            </StyledTooltip>
        )
    }
}


const StyledTooltip = styled.div`

.tooltip {

    display: none;
    position: absolute;
    margin-top: 4.5rem;
    background-color: #b5b5b5;
    padding: 5px;
    z-index:101;

}

.fade-enter {
    opacity: 1;
}

.fade-enter.fade-enter-active {
    opacity: 0;
    transition: opacity 4000ms ease-out;
    transition-delay: 1500ms;
}

.fade-leave {
    opacity: 0;
}

.fade-leave.fade-leave-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
}


.tooltip.show {
    display: block;
}



.tooltip:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #b5b5b5;
    margin-left: 10px;
    margin-top: -10px
}
`
