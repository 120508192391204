import React, {Component} from 'react';
import styled from 'styled-components';
import FiltersModal from '../components/FiltersModal';
import SubscribeForm from '../components/SubscribeForm';
import ExplainedModal from '../components/ExplainedModal';
import Lenses from '../components/Lenses';
import Menu from '../templates/Menu';
import { ReactComponent as Close }  from '../_/img/close.svg';


const ExplainedModalTexts = {
  target: {
    title: "What does target escape rate mean?",
    description: "The World Poverty Clock provides real-time poverty estimates until 2030 for almost every country in the world. It monitors progress against Ending Extreme Poverty, which is the UN’s first Sustainable Development Goal (SDG1). The target escape rate calculates how the rate of poverty reduction in the world should be in order to achieve UN's SDG1."
  },
  current: {
    title: "What does current escape rate mean?",
    description: "The World Poverty Clock provides real-time poverty estimates until 2030 for almost every country in the world. It monitors progress against Ending Extreme Poverty, which is the UN’s first Sustainable Development Goal (SDG1). The current escape rate calculates the rate of poverty reduction in the world."
  },
  end:{
    title:"SDG1 Ends in 2030",
    description:"The Sustainable Development Goals are set for 2030. This means that, according to our projections the world will still be offtrack SDG1 - erradicate extreme poverty - by the end of the SDG period in 2030."
  }
}
export default class Modal extends Component {
    renderPage(modalPage) {
        if(modalPage){
          switch (modalPage) {
            case 'mobile_filters':
                if (this.props.groups === undefined) return '';
                return <FiltersModal {...this.props}/>
            case 'mobile_lenses':
                return <Lenses {...this.props} />
            case 'topmenu':
                return <Menu {...this.props} />;
            case 'target_escape_rate':
              return <ExplainedModal title={ExplainedModalTexts.target.title} description={ExplainedModalTexts.target.description} {...this.props} />;
            case 'current_escape_rate':
              return <ExplainedModal title={ExplainedModalTexts.current.title} description={ExplainedModalTexts.current.description} {...this.props} />;
            case 'end':
              return <ExplainedModal title={ExplainedModalTexts.end.title} description={ExplainedModalTexts.end.description} {...this.props} />;
            case 'page':
                return <div></div>;
            case (modalPage.match(/infocapture/) || {}).input:
                const whichModal = modalPage.split('_');
                const whichCountry = whichModal[1].split('-')
                return <SubscribeForm modal={whichModal} country={whichCountry} />
            default:
                return <span/>;
          }
        }
        
    }

    render() {

        return (
            <StyledModal>
                <div className={this.props.showModal === true && this.props.modalPage !== 'country_card' ? "modal display-block"
                : this.props.showModal === true && this.props.modalPage === 'country_card' ? "display-block"
                : "modal display-none"}>
                    <div className={'modal-main ' + this.props.modalPage}>
                        {this.renderPage(this.props.modalPage)}
                        <button className="modal-close-button" onClick={ () => { this.props.handleModal(false, '');} }><Close className="close-svg"/></button>
                    </div>
                </div>
            </StyledModal>
        )
    }
}

const StyledModal = styled.div`

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
  }

  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    .close-svg{
      width: 1.5em;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 1.5em;
      margin-right: 2em;
    }
  }

  [class*="infocapture"]{
    max-width: 40em;
    padding: 2.5em;
    text-align: center;
    .close-svg path{
        fill: #000 !important;
    }
    h1{
      font-family: Berlingske;
      font-size: 2.5em;
      margin-bottom: .25em;
    }
    p{
      margin: 1em 1.5em 1em 1.5em;
      padding: 0 1em 2.5em 1em;
      border-bottom: 1px solid #000;
      font-size: .9em
    }
    span{
      font-size: .7em
    }
  }

  .target_escape_rate, .current_escape_rate, .end, .topmenu{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,0.65)
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .mobile_filters, .mobile_lenses  {
    width: 100% !important;
    top: 0;
    left: 0;
    height: 100vh !important;
    background-color: rgba(0,0,0,0.65) !important;
    padding: 2em;
    transform: none;
}
.mobile_lenses .lenses{
  margin-top: 15vh;
  .button{
    padding: 1em 1.25em;
    width: 100%;
    margin-bottom: 1em;
  }
  
}
.mobile_filters .filters-container{
  flex-direction: row;
  flex-wrap: wrap;
}
.mobile_filters .filters-heading{
    color: #fff !important;
    margin-bottom: 1em;
}
.mobile_filters .filters-section:first-child{
    margin-top: 1.75em;
    .filters-container{
      pointer-events: none;
      filter: grayscale(100%);
    }
}
.mobile_filters .filters-section{
    margin-bottom: 1.5em;
    width: 100%;
    padding-right: 0;
}

 .mobile_filters .filters-section:not(:last-child){
    border: 0;
 }
 .mobile_filters .filters-container .filter{
    padding: 1.2em;
    text-align: center;
    display: block;
    width: 13.8em;
    flex: 1 0 45%;
 }
 .mobile_filters .filters-section:nth-child(2) .filter:nth-child(3){
     margin-right: 0
 }
 .mobile_filters .filters-section:nth-child(2) .filter:last-child{
     flex: 0 1 100%;
     margin-top: .75em;
 }
 .mobile_filters .filter-tick{
   margin-left: .5em;
 }
 .mobile_filters .react-select__control{
  padding-top: .75em;
  padding-bottom: .75em;
 }
 .mobile_filters .fvJOwx{
   width: 100%;
   top: 4em;
 }
 @media screen and (max-width: 768px) {
   [class*="infocapture"]{
    width: 90%;
    height: 90%;
    overflow: auto;
    padding: 4em 2em;
   }
    .mobile_filters{
      overflow-y: scroll;
    }
    .infocapture{
      padding: 3.5em 1em;
      text-align: center;
      width: 100%;
      height: 100vh;
      overflow-y: scroll;
      p{
        margin: 1em 1.5em 0em 1.5em;
        padding-bottom: 1em;
      }
    }
  .close-svg{
    margin-top: 1em;
  }
 }
 
`
