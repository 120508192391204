import React, { Component } from 'react';

class ScrollSection extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <section className="scroll-section" style={{
        transition: 'transform 1s ease 0s',
        position: 'relative',
      }}>
        {this.props.children}
      </section>
    );
  }
}

export default ScrollSection;
