import React, {Component} from 'react';
import Wrapper from '../components/Wrapper';
import Burger from '../components/Burger';
import Filters from '../components/Filters';
import styled from 'styled-components';

const LogoIcon = (
  /*<svg  width="100%" height="100%" viewBox="0 0 315 229" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.41421"><g transform="translate(-587 -415)" fill="currentColor"><clipPath id="A"><path d="M812.71 632.27l58.414-134.924c3.897-9.01-5.927-17.922-15.086-13.612-16.113 7.578-37.33 14.25-60.626 14.25-23.428 0-44.865-6.74-61.135-14.37-9.143-4.295-18.944 4.618-15.062 13.62L777.7 632.27c6.46 14.9 28.55 14.9 35 0z"/></clipPath><g clipPath="url(#A)"><g transform="translate(0 101.948)"><clipPath id="B"><path d="M557 313.052h315v229H557z"/></clipPath><g clipPath="url(#B)"><path d="M713.34 375.554H877v170.944H713.34z" fill="#ee4322"/></g></g></g><clipPath id="C"><path d="M557 482.86h150.57v160.584H557z"/></clipPath><g clipPath="url(#C)"><g transform="translate(0 102.304)"><clipPath id="D"><path d="M615.11 391.712l-57.254 134.615c-3.818 8.996 5.824 17.88 14.798 13.588 15.78-7.568 36.555-14.24 59.39-14.24 22.956 0 43.97 6.73 59.92 14.358 8.958 4.278 18.57-4.62 14.743-13.603l-57.314-134.72c-6.308-14.874-27.966-14.874-34.28 0z"/></clipPath><g clipPath="url(#D)"><path d="M557 379.696h151v162H557z"/></g></g></g><clipPath id="E"><path d="M823.472 443.53c0-15.684-12.838-28.384-28.657-28.384-15.796 0-28.634 12.7-28.634 28.384 0 15.662 12.838 28.377 28.634 28.377 15.82 0 28.657-12.715 28.657-28.377z"/></clipPath><g clipPath="url(#E)"><g transform="translate(0 -136.947)"><clipPath id="F"><path d="M557 551.947h315v229H557z"/></clipPath><g clipPath="url(#F)"><path d="M761.18 547.093h67.292v66.762H761.18z" fill="#ee4322"/></g></g></g><g transform="translate(0 -136.947)"><clipPath id="G"><path d="M656.87 580.477c0-15.685-13.633-28.384-30.45-28.384-16.79 0-30.432 12.7-30.432 28.384 0 15.662 13.64 28.377 30.432 28.377 16.816 0 30.45-12.715 30.45-28.377z"/></clipPath><g clipPath="url(#G)"><path d="M595 551.947h62v57h-62z"/></g></g></g></svg>*/
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 41.8 31.3">
  <g>
    <path className="fillSecond" fill="#E8482B" d="M34.2,29.8l7.5-18c0.5-1.2-0.8-2.4-1.9-1.8c-2.1,1-4.8,1.9-7.7,1.9c-3,0-5.7-0.9-7.8-1.9   c-1.2-0.6-2.4,0.6-1.9,1.8l7.5,18C30.6,31.8,33.4,31.8,34.2,29.8z"></path>
    <path className="fillFirst" fill="#010202" d="M7.6,11.3l-7.5,18c-0.5,1.2,0.8,2.4,1.9,1.8c2.1-1,4.8-1.9,7.7-1.9c3,0,5.7,0.9,7.8,1.9   c1.2,0.6,2.4-0.6,1.9-1.8l-7.5-18C11.2,9.3,8.4,9.3,7.6,11.3z"></path>
    <path className="fillSecond" fill="#E8482B" d="M35.7,3.7C35.7,1.6,34,0,32,0c-2.1,0-3.7,1.7-3.7,3.7c0,2.1,1.7,3.7,3.7,3.7C34.1,7.4,35.7,5.8,35.7,3.7z"></path>
    <path className="fillFirst" fill="#010202" d="M13.6,3.7c0-2.1-1.7-3.7-3.7-3.7C7.8,0,6.2,1.7,6.2,3.7c0,2.1,1.7,3.7,3.7,3.7S13.6,5.8,13.6,3.7z"></path>
    
  </g>
  </svg>

)
;

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      areFiltersVisible: true
    }

    this.toggleFilters = this.toggleFilters.bind(this);
  }
  toggleFilters() {
    if(window.matchMedia("(min-width: 768px)").matches){
      this.setState({
        areFiltersVisible: !this.state.areFiltersVisible
      })
    }
    
  }
  render() {
    return (
      <StyledHeader {...this.props} {...this.state} className="header">
        <Wrapper>
          <div className={`header-logo ${!this.state.areFiltersVisible ? 'is-light' : ''}`}>
            {
              this.props.isHeadline
              ? <button className="header-logo-img" onClick={this.toggleFilters}>

                {LogoIcon}
                <h6 className="header-logo-img-text">by WORLD DATA LAB</h6>
              </button>
              : <a className="header-logo-img" href=".">
                {LogoIcon}
              </a>
            }
          </div>
          {!this.props.isHeadline || !this.state.areFiltersVisible
            ? <h1 className="header-logo-type label">World Poverty Clock      <span><small>by</small>      World Data Lab</span></h1>
            : 
            <Filters
                {...this.props}
                className={this.state.areFiltersVisible ? 'filters is-visible' : 'filters'}
                handleClickDropdown={this.props.handleClickDropdown} handleFilterClick={this.props.handleFilterClick} handleCountryChange={this.props.handleCountryChange}
                groups={this.props.groups} filters={this.props.filters} currentAgeRange={this.props.currentAgeRange}
            />
          }


          <Burger onClick={ () => {this.props.handleModal(true,'topmenu')}}/>

        </Wrapper>
      </StyledHeader>
    )
  }
}

const StyledHeader = styled.header`
  padding-top: 4vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 13;
  background-color: white;

  .wrapper {
    display: flex;
    align-items: center;
  }

  .header {
    &-logo {
      flex: none;
      display: flex;
      align-items: center;
      transition: color .3s;
      position: relative;

      &.is-light {
        color: #CDCDCD;
        .fillFirst{
          fill: #CDCDCD;
        }
      }

      &-img {
        flex: none;
        width: 7rem;
        margin-right: 1em;
        &-text{
          width: 100%;
          
          text-align: left;
          font-weight: 500;
          margin-top: .75em;
          position: absolute;
          font-size: .85em;
          letter-spacing: 1px;
        }
        svg {
          height: 3rem;
        }
      }

      &-type {
        flex: none;
        margin: 1.4em;
        span{
          font-weight: 500;
        }
        small{
          text-transform: lowercase;
          margin: 0 2em;
        }
      }
    }
  }

  .filters {
    margin-left: 1.5em;
    transition-duration: 300ms;
    transition-property: opacity, visibility;

    &:not(.is-visible) {
      opacity: 0;
      visibility: hidden;
    }
  }

  .burger { margin-left: auto;padding-top: 2.5rem; }

  @media screen and (max-width: 768px) {
    padding-top: .75em;
    padding-bottom: .5em;
    box-shadow: -1px -2px 5px 2px rgba(0,0,0,0.1);
    .burger{
      padding-top: 0;
    } 
    .header-logo{
      &-type{
        display: none;
      }
      &-img{
        width: 5rem;
        transform: scale(0.75);
        &-text{
          display: none;
        }
      }
    }

    

    .filters {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  


`

export default Header;
