import React, {Component} from 'react';
import ShareIcon from '../_/img/share.svg';
import IconTwitter from '../_/img/icon-twitter.png';
import IconFacebook from '../_/img/icon-facebook.png';
import IconLinkedin from '../_/img/icon-linkedin.png';
import styled from 'styled-components';

export default class Share extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    }
  }
    
  
  toggleSocial = () => {
    this.setState({isActive: !this.state.isActive})
  }
  
  render() {
    return (
      <StyledShare {...this.props} className="share" onClick={this.toggleSocial}>
          <span className="share-label label">Share</span>
          <span className="share-button">
            <img className="share-icon" src={ShareIcon} alt="Share icon"/>
          </span>
          
          <div className={this.state.isActive ? 'share-networks active' : 'share-networks'}>
                <a href="http://twitter.com/share?text=Check this out, this tool by @worlddatalab forecasts poverty dynamics until 2030 https://worldpoverty.io" rel="noopener noreferrer" className="footer-socials-item" target="_blank">
                  <img src={IconTwitter} alt=""/>
                </a>
                <a href="https://www.facebook.com/sharer/sharer.php?u=https://worldpoverty.io" className="footer-socials-item"  rel="noopener noreferrer" target="_blank">
                  <img src={IconFacebook} alt=""/>
                </a>
                <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://worldpoverty.io" className="footer-socials-item"  rel="noopener noreferrer" target="_blank">
                  <img src={IconLinkedin} alt=""/>
                </a>
        </div>
        
      </StyledShare>
    )
  }
  
}

const StyledShare = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .share {
    &-label {
      margin-bottom: .5em;
      writing-mode: vertical-rl;
    }

    &-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 2em;
      width: 2em;
      padding: .5em;
      background-color: #EE4422;
      border-radius: 50vw;
      border: 1px solid white;
      cursor: pointer;
    }
    &-networks{
      &:not(.active){
        .footer-socials-item{
          opacity: 0;
          visibility: hidden;
          transform: translateY(1vw);
        }
      }
      position: absolute;
      bottom: 0;
      margin-left: .5em;
      margin-bottom: 2em;
      .footer-socials-item{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2.5em;
          width: 2.5em;
          padding: .5em;
          background-color: #fff;
          border-radius: 50vw;
          margin-bottom: .5em;
          box-shadow: 0 0.1vw 0.25vw 0 rgba(0,0,0,0.5);
          transition: 150ms;
          transition-property: opacity, visibility, transform;
          > img {
            max-width: 100%;
            max-height: 100%;
          }

          &:not(:last-child) {
            margin-right: 1em;
          }
      }
      
    }
    
    
    
  }
`
