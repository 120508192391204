import React, {Component} from 'react';
import Home from './templates/Home';
import Headline from './templates/Headline';
import Article from './templates/Article';
import styled from 'styled-components';
import {Switch, MemoryRouter} from 'react-router';
import worldGeoJSON from './worldGeoJSONcont';
import continentsJSON from './continents.json';
import ExtendedGeoJSON from 'extend-geojson-properties';
import BackendBridge from './components/BackendBridge';
import { BrowserRouter as Router, Route } from "react-router-dom";

function getDate() {
  let now = new Date();
  let dd = now.getDate();
  let mm = now.getMonth() + 1;
  let yyyy = now.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  let date = yyyy + '-' + mm + '-' + dd;
  let secs = now.getSeconds() + (60 * (now.getMinutes() + (60 * now.getHours())));
  let year = yyyy;
  return {
    date,
    secs,
    year
  }
}
function getYearRange (start, end) {
  let yearsList = [];
  for (var i = start; i <= end; i++) {
    if(i === end){
      yearsList.push({value: i, label: 'END'});
    }else{
      yearsList.push({value: i, label: i});
    }
    
  }
  return yearsList;
}


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isMapReady: false,
      showModal: 'false',
      showCountryCard: false,
      modalPage: '',
      modalReload: false,
      isFooter: false,
      isAllowedTransition: true,
      renderChart: true,
      renderCountryChart: false,

      bridge: new BackendBridge(),

      //countryData: this.getCountryData,
      clockData: this.getClockData,
      cardData: {
        population: '',
        headcountInPoverty: '',
        headcountRural: '',
        percentageRural: '',
        headcountUrban: '',
        percentageUrban: '',
        headcountMale: '',
        percentageMale: '',
        headcountFemale: '',
        percentageFemale: '',
        targetEscapeRate: '',
        currentEscapeRate: '',
      },

      groups: [ //groups and filters seems to represent the same functionality. It is easier to use the filters booleans to filter the data
        {
          heading: 'Geographical filters',
          filters: [
            { label: 'Rural' },
            { label: 'Urban' }
          ],
          hasActiveFilter: false
        },
        {
          heading: 'Demographic filters',
          filters: [
            { label: 'Female', independent:true },
            { label: 'Male', independent:true },
            { label: 'all ages', isActive: false, independent:true,
              dropdown: ['loading'] /* First index[0] always is interpreted as default(all) */
            }
          ],
          hasActiveFilter: false
        }
      ],
      filters: [
        [false, false],
        [false, false, false]
      ],

      currentAgeRange: [],
      currentYear: new Date().getFullYear(),
      yearRange: getYearRange(2016, 2031),
      lastYear: '',
      povertyLevel: "1.9",
      currentCountry: 'WORLD',
      currentCountryLabel: 'All Countries',
      currentRegion: '',
      lastCountry: '',
      filteredCountries: [],
      currentMapLens: 'track',
      neverUpdated: true,
      isCountryCardOpened: false,

      mapDisplay: false,
      mapData: worldGeoJSON,
      subnationalDisplay: false,
      subnationalCountry: '',
      continentsDisplay: false,
      countryHasSubnational: false,

      handleClickDropdown: this.handleClickDropdown,
      handleFilterClick: this.handleFilterClick,
      handleFilterSwap: this.handleFilterSwap,
      handleYearChange: this.handleYearChange,
      handleCountryChange: this.handleCountryChange,
      handleSubnational: this.handleSubnational,
      handleContinentsToggle: this.handleContinentsToggle,
      handleModal: this.handleModal,
      handleCountryCard: this.handleCountryCard,
      handleRegion: this.handleRegion,
      toggleMap: this.toggleMap,
      toggleCardReload: this.toggleCardReload,
      toggleFooter: this.toggleFooter,
      toggleScroll: this.toggleScroll,
      toggleLenses: this.toggleLenses,
      getCurrentFilterScope: this.getCurrentFilterScope,
      updateClockFromBridge: this.updateClockFromBridge,
      updateMapFromBridge: this.updateMapFromBridge,
      setupInitialCountryCard: this.setupInitialCountryCard,
      resetFilters: this.resetFilters,
    }
    //window.getData = this.state.bridge.getData;//Only for Debug purposes, DELETE in future
    this.state.bridge.addRealtimeContext("mapContext");
  }
  presentDate = new Date();
  clearAgeSelectionFlag = false;
  interval = '';
  updateFilterClick = () => {
    if(this.state.continentsDisplay){
      this.setupContinentForBridge(undefined,this.state.currentCountry);
    }else{
      this.setupClockForBridge();
      this.setupTodayFigures();
      
    }
    this.setupMapForBridge();
  }

  handleClickDropdown = (element) => {
    if (element === "all ages" || (element === this.state.currentAgeRange[0] && this.state.currentAgeRange.length === 1)) {
      this.setState({currentAgeRange: []},() => {
        this.updateFilterClick()
      });
      this.clearAgeSelectionFlag = true;
    } else if ( !this.state.currentAgeRange.includes(element) ) {
      this.setState({currentAgeRange: [...this.state.currentAgeRange, element]},() => {
        this.updateFilterClick()
      });
    } else if ( this.state.currentAgeRange.includes(element) ) {
      this.setState({currentAgeRange: this.state.currentAgeRange.filter( x => x !== element)},() => {
        this.updateFilterClick()
      });
    }
    this.state.toggleScroll(true);
  }

  resetFilters = (e) => {
    if(e){
      e.preventDefault();
    }
    const filters = [
      [false, false],
      [false, false, false]
    ]
    const groups = this.state.groups.map(group => {
      group.hasActiveFilter = false
      return group;
    });
    if(this.state.currentAgeRange.length > 0){
      groups[1].filters[2].label = "all ages";
      this.setState({filters,groups},()=>{
        this.handleClickDropdown("all ages");
      })
    }else{
      this.setState({filters,groups},()=>{
        this.setupClockForBridge(false);
        this.setupMapForBridge();
      })
    }
    
    
    
    if(this.state.showCountryCard === true){
      this.handleCountryCard(false, true);
    }
  }

  handleFilterClick = (i,j) => {
    //if(i !== 0){
      setTimeout(() => {
        let isIndependent = (this.state.groups[i].filters[j].independent !== undefined && this.state.groups[i].filters[j].independent !== false);
        let isDropdown = (this.state.groups[i].filters[j].dropdown !== undefined && this.state.groups[i].filters[j].dropdown);
        let filters = this.state.filters.map(group => group.map(filter => false));
        //let renderChanges = true;
        if(isIndependent)filters[i] = this.state.filters[i];
        let groups = this.state.groups.map(group => {
          group.hasActiveFilter = false
          return group;
        });
        if(isDropdown){
          groups[i].filters[j].isActive = !this.state.groups[i].filters[j].isActive; /*Toggle State*/
          filters[i][j] = this.state.groups[i].filters[j].label !== this.state.groups[i].filters[j].dropdown[0][0]
  
          if(filters[i][j]) {
            if(this.state.currentAgeRange.length > 1){
              groups[i].filters[j].label = 'multiple'
            }else if(this.state.currentAgeRange.length > 0){
              let ageRange = this.state.currentAgeRange[0].substring(1).slice(0,-1).replace(',','-').replace('-INF','+');
              const firstAge = ageRange.split('-')[0];
              const secondAge = parseInt(ageRange.split('-')[1])-1;
              if(!isNaN(secondAge)){
                ageRange = firstAge + '-' + String(secondAge).padStart(2, '0');
              }
                  
              groups[i].filters[j].label = ageRange;
            }
          }else{
            //renderChanges = false;
          }
  
          if(this.clearAgeSelectionFlag) {
  
            filters[i][j] = false;
            this.clearAgeSelectionFlag = false;
            groups[i].filters[j].label = "all ages";
          }
        }
  
        if (this.state.filters[i][j] === false && !isIndependent) { //setting group filters
          filters[i][j] = true;
          groups[i].hasActiveFilter = true;
        }
  
  
        if(isIndependent) {
          if(!isDropdown)filters[i][j] = !filters[i][j];
          for(var filter in this.state.filters[i]){
            groups[i].hasActiveFilter = this.state.filters[i][filter];
            if(this.state.filters[i][filter])break;
          }
        }
  
  
        if(filters[1][0] && i === 1 & j === 1) {//filter exclusivity swapping
          filters[1][0] = false;
          filters[1][1] = true;
        }
  
        if(filters[1][1] && i === 1 & j === 0) {//filter exclusivity swapping
          filters[1][1] = false;
          filters[1][0] = true;
        }
  
        if(j !== 2) {//dropdown deactivation
          groups[1].filters[2].isActive = false;
        }
        /*if(!filters[0][0] && !filters[0][1] && !filters[1][0] && !filters[1][1] && !filters[1][2]){
  
        }*/
        //if(renderChanges){
        if(!isDropdown){
          this.setState({
            filters,
            groups
          },() => {
            this.updateFilterClick();
          })
        }
         
      }, 100);  
    //}
      
  }

  handleFilterSwap = () => {
    if ( this.state.filters[0][0]) {
      this.setState({filters: [
        [false, true],
        [false, false, false]
      ]},() => {
        this.setupClockForBridge();
        this.setupMapForBridge();
      });
    }
    if ( this.state.filters[0][1]) {
      this.setState({filters: [
        [true, false],
        [false, false, false]
      ]},() => {
        this.setupClockForBridge();
        this.setupMapForBridge();
      });
    }

    if ( this.state.filters[1][0]) {
      this.setState({filters: [
        [false, false],
        [false, true, this.state.filters[1][2]]
      ]},() => {
        this.setupClockForBridge();
        this.setupMapForBridge();
      });
    }
    if ( this.state.filters[1][1]) {
      this.setState({filters: [
        [false, false],
        [true, false, this.state.filters[1][2]]
      ]},() => {
        this.setupClockForBridge();
        this.setupMapForBridge();
      });
    }


  }

  handleYearChange = (year) => {
    if(!this.state.clockData) {return}
    if(this.state.currentYear === year){return}

    this.setState({currentYear: year},() => {
      if(this.state.continentsDisplay){
        this.setupContinentForBridge(undefined,this.state.currentCountry);
      }else{
        this.setupClockForBridge();
      }

      if(!this.state.subnationalDisplay){
        //First updates countries for the 3% general filter, then after ready setups up map for bridge
        this.setupNoPovFilter(true);
      }else{
        this.handleSubnational();
      }
    });
  }

  setupClockForBridge = (rerenderChart = false, isRealtime = false) => {
    let filterScope = this.getCurrentFilterScope();

    if(isRealtime){
      this.state.bridge.addRealtimeContext("clockContext");
    }
    
    if(rerenderChart)
      this.setState({renderChart: false})

    if(filterScope['getAgeRange'].length === 0){
      filterScope['getAgeRange'] = ['[00,INF)'];
    }
    if(filterScope['getType'] === 'ages'){
      this.state.bridge.getData("clockContext",filterScope['getType'], [[...filterScope['getCountry']], [filterScope['getYear']], filterScope['getAgeRange'], filterScope['regionOrGender'], filterScope['getPovLevel']], data => this.updateClockFromBridge(data,filterScope), true);
    }else{
      this.state.bridge.getData("clockContext",filterScope['getType'], [[...filterScope['getCountry']], [filterScope['getYear']], filterScope['regionOrGender'], filterScope['getPovLevel']], data => this.updateClockFromBridge(data,filterScope), true);
    }
  }

  setupMapForBridge = (filterScope = this.getCurrentFilterScope()) => {
    if(filterScope['getType'] === 'ages'){
      this.state.bridge.getData("mapContext",filterScope['getType'], [["ALL", "WORLD"], [filterScope['getYear']], filterScope['getAgeRange'], filterScope['regionOrGender'], filterScope['getPovLevel']],
            data => this.updateMapFromBridge(data,filterScope),true);
    }else{
      this.state.bridge.getData("mapContext",filterScope['getType'], [['ALL', "WORLD"], [filterScope['getYear']], filterScope['regionOrGender'], filterScope['getPovLevel']], data => this.updateMapFromBridge(data,filterScope),true);
    }
  }

  getContinentList = (continentCode) => {
    var countriesList = [];
    this.state.mapData.features.forEach((country)=>{
      let is_global = false;
      if(country.properties.continent_id === continentCode && country.properties.tag !== 4){
        let ruralOnly = this.state.clockData.areaCountries;
        ruralOnly.forEach((ruralCountry)=>{
          if(country.id === ruralCountry[0]){
            is_global = true;
          }
        })
        if(is_global){
          countriesList.push(country.id);
        }
      };
    });
    return countriesList;
  }

  setupContinentForBridge = (filterScope = this.getCurrentFilterScope(),continentCode) => {
    var countriesList = this.getContinentList(continentCode);

    if(filterScope['getType'] === 'ages'){
      this.state.bridge.getData("cardContext",filterScope['getType'], [[...countriesList], [filterScope['getYear']], filterScope['getAgeRange'], filterScope['regionOrGender'], filterScope['getPovLevel']], data => this.updateContinentFromBridge(data,this.getCurrentFilterScope(countriesList),countriesList), true);
    }else{
      this.state.bridge.getData("cardContext",filterScope['getType'], [[...countriesList], [filterScope['getYear']], filterScope['regionOrGender'], filterScope['getPovLevel']], data => this.updateContinentFromBridge(data,this.getCurrentFilterScope(countriesList),countriesList), true);
    }
  }
  updateContinentFromBridge = (data,filterScope = this.getCurrentFilterScope(),countriesList) => {
    this.updateClockFromBridge(data,filterScope);
    this.setupInitialCountryCard(countriesList);
  }
  setupTodayFigures = (filterScope = this.getCurrentFilterScope()) => {
      if(filterScope['getType'] === 'ages'){
        this.state.bridge.getData("cardContext",filterScope['getType'], [[...filterScope['getCountry']], [this.presentDate.getFullYear()], filterScope['getAgeRange'], filterScope['regionOrGender'], filterScope['getPovLevel']], data => this.updateTodayFigures(data,filterScope), true);
      }else{
        this.state.bridge.getData("cardContext",filterScope['getType'], [[...filterScope['getCountry']], [this.presentDate.getFullYear()], filterScope['regionOrGender'], filterScope['getPovLevel']], data => this.updateTodayFigures(data,filterScope), true);
      }

    }

  setupNoPovFilter = (renderMap) => {
        let filterScope = this.getCurrentFilterScope()
        this.state.bridge.getData("filterContext","ages", [["ALL"], [filterScope['getYear']], ["[00,INF)"], "both", filterScope['getPovLevel']], (data) => {

            let filteredArray = [];
            Object.keys(data["ages"]).forEach((country) => {
              const countryInfo = data["ages"][country][filterScope['getYear']]['[00,INF)']['both'][filterScope['getPovLevel']];
              const stableTrack = countryInfo.stable_track;

              if(stableTrack === 'nopoverty'){
                filteredArray.push(country);
              }
            })

            this.setState({
              filteredCountries: filteredArray
            },()=>{
              if(renderMap){
                this.setupMapForBridge();
              }

            });

        },true);
    }

  updateClockFromBridge = (data,filterScope = this.getCurrentFilterScope()) => {
    
    this.state.bridge.removeRealtimeContext("clockContext");

    var totalResult = { population: 0, headcount: 0, escape_rate: 0, target_escape_rate: 0, sdg_headcount: 0, escape_from_poverty_rate: 0, fell_into_poverty_rate: 0, stable_track: ''}
    var secondsUntilYear = this.getSecondsUntilYear();
    var secondsUntilDay = this.getSecondsUntilDay();
    if(filterScope['getType'] === 'regions'){
      filterScope['getCountry'].forEach((countryCode,i)=>{
        let partialResult = data[filterScope['getType']][filterScope['getCountry'][i]][filterScope['getYear']][filterScope['regionOrGender']][filterScope['getPovLevel']];
        totalResult.population += partialResult.population;
        totalResult.headcount += partialResult.headcount;
        totalResult.escape_rate += partialResult.escape_rate;
        totalResult.sdg_headcount += partialResult.sdg_headcount;
        totalResult.target_escape_rate += partialResult.target_escape_rate;
        if(filterScope['getCountry'][i] === 'WORLD'){
          totalResult.escape_from_poverty_rate += partialResult.escape_from_poverty_rate;
          totalResult.fell_into_poverty_rate += partialResult.fell_into_poverty_rate;
        }
        totalResult.stable_track = partialResult.stable_track;
      });
    }else{
      filterScope['getCountry'].forEach((countryCode,i)=>{
        let partialData = data[filterScope['getType']][filterScope['getCountry'][i]][filterScope['getYear']];
        //ForEach all age groups to aggregate them
        if(partialData){
          Object.keys(partialData).forEach(function(ageRange){
            let partialResult = partialData[ageRange][filterScope['regionOrGender']][filterScope['getPovLevel']]

            if(filterScope['getCountry'][i] === 'WORLD'){
              totalResult.escape_from_poverty_rate += partialResult.escape_from_poverty_rate;
              totalResult.fell_into_poverty_rate += partialResult.fell_into_poverty_rate;
            }
            totalResult.headcount += partialResult.headcount;
            totalResult.population += partialResult.population;
            totalResult.sdg_headcount += partialResult.sdg_headcount;
            totalResult.escape_rate += partialResult.escape_rate;
            totalResult.target_escape_rate += partialResult.target_escape_rate;
            totalResult.stable_track = partialResult.stable_track;
          });
        }
      })

    }

    var escapedThisYear = totalResult.escape_rate*secondsUntilYear;
    var offtrackThisYear = (Math.abs(totalResult.target_escape_rate) - totalResult.escape_rate)*secondsUntilYear;

    var escapedToday = this.state.clockData.escapedPovertyToday;
    var fellToday = this.state.clockData.fellIntoPovertyToday;

    
    if (this.state.currentYear !== this.presentDate.getFullYear() && this.state.currentYear !== 2031){
        escapedThisYear = 0;
        offtrackThisYear = 0;
    }else if(this.state.currentYear === 2031){
        totalResult.sdg_headcount = totalResult.headcount - escapedThisYear;
        offtrackThisYear = 0;
    }else{
      if(this.state.neverUpdated){
        fellToday = Math.abs(totalResult.fell_into_poverty_rate)*secondsUntilDay;
        escapedToday = Math.abs(totalResult.escape_from_poverty_rate)*secondsUntilDay;
      }else{
        fellToday = Math.abs(this.state.clockData.curYearfallRate)*secondsUntilDay;
        escapedToday = Math.abs(this.state.clockData.curYearEscapeRate)*secondsUntilDay;
      }
    }
    this.setState({
        clockData: Object.assign({}, this.state.clockData, {
          population: totalResult.population,
          headcountInPoverty: totalResult.headcount - escapedThisYear,
          offTrackHeadcount: totalResult.sdg_headcount + offtrackThisYear,
          offTrackChangeRate:Math.abs(totalResult.target_escape_rate) - totalResult.escape_rate,
          targetEscapeRate: Math.abs(totalResult.target_escape_rate),
          currentEscapeRate: totalResult.escape_from_poverty_rate,
          fallRate: Math.abs(totalResult.fell_into_poverty_rate),
          escapedPovertyToday: escapedToday,
          fellIntoPovertyToday: fellToday,
          escapeRate: totalResult.escape_rate,
          headcountInAgeGroup: totalResult.headcount,
          stableTrack: totalResult.stable_track,
        })
      },()=>{
          if(this.state.neverUpdated){
            this.setState({
              clockData: { ...this.state.clockData,
                curYearEscapeRate: totalResult.escape_from_poverty_rate,
                curYearfallRate: Math.abs(totalResult.fell_into_poverty_rate),
              },
              neverUpdated: false
            });
          }
    });
    this.setState({renderChart: true})
  }

  updateMapFromBridge = (data, filterScope=this.getCurrentFilterScope()) => {
    let countries = {};
    let jsonCountries = '[';
    let worldHeadcount = 0;
    const filteredCountries = this.state.filteredCountries;
    if(data[filterScope['getType']]['WORLD']){
      if(filterScope['getType'] === 'regions'){
        let totalResult = data[filterScope['getType']]['WORLD'][filterScope['getYear']][filterScope['regionOrGender']][filterScope['getPovLevel']];
        worldHeadcount += totalResult.headcount;
      }else{
        let partialData = data[filterScope['getType']]['WORLD'][filterScope['getYear']];
        Object.keys(partialData).forEach(function(ageRange){
          let totalResult = partialData[ageRange][filterScope['regionOrGender']][filterScope['getPovLevel']];
          worldHeadcount += totalResult.headcount;
        });
      }
    }
    Object.keys(data[filterScope['getType']]).forEach((country) => {
      if(country !== 'WORLD'){
        let headcount = 0,
            population = 0,
            escape_rate = 0,
            percentage_country = 0,
            percentage_world = 0,
            tag = 0,
            stable_track = '';
        
        if(filterScope['getType'] === 'regions'){
          let totalResult = data[filterScope['getType']][country][filterScope['getYear']][filterScope['regionOrGender']][filterScope['getPovLevel']];
          headcount += totalResult.headcount;
          population += totalResult.population;
          escape_rate += totalResult.escape_rate;
          stable_track = totalResult.stable_track;
        }else{
            let partialData = data[filterScope['getType']][country][filterScope['getYear']];
            Object.keys(partialData).forEach(function(ageRange){
              let totalResult = partialData[ageRange][filterScope['regionOrGender']][filterScope['getPovLevel']]
              headcount += totalResult.headcount;
              population += totalResult.population;
              escape_rate += totalResult.escape_rate;
              stable_track = totalResult.stable_track;
            });
        }

        if(filteredCountries && filteredCountries.includes(country)){
          stable_track = 'nopoverty';
          tag = 4;
        }
        if(headcount/worldHeadcount > 0.001){
          percentage_world = headcount/worldHeadcount;
        }
        percentage_country = headcount/population;

        countries = {"code":country, "headcount_in_poverty":headcount,"population":population,"escape_rate":escape_rate,"stable_track":stable_track, "percentage_country": percentage_country, "percentage_world": percentage_world, "tag":tag};

        jsonCountries += JSON.stringify(countries)+',';
      }    
    });

    jsonCountries = jsonCountries.slice(0, -1);
    jsonCountries += ']';

    this.createWorldGeoJSON(JSON.parse(jsonCountries));
    
    this.setState({
        countryData: {
          ...jsonCountries,
        }
      },()=>{
        if(this.state.showCountryCard === true){
          this.handleCountryCard(false, true);
        }
        //Setup how countries are displayed along with running people
        this.setCountryChances();  
        
        
      })
  }

  //Updates "Fell Into/Escape Poverty Today" only when you use the filters, ignoring year changes
  updateTodayFigures = (data,filterScope=this.getCurrentFilterScope()) => {
    
    var totalResult = {
      escape_from_poverty_rate:0,
      fell_into_poverty_rate:0
    }
    var secondsUntilDay = this.getSecondsUntilDay();
    if(filterScope['getType'] === 'regions'){
      filterScope['getCountry'].forEach((countryCode,i)=>{
        let partialResult = data[filterScope['getType']][filterScope['getCountry'][i]][this.presentDate.getFullYear()][filterScope['regionOrGender']][filterScope['getPovLevel']];
        totalResult.escape_from_poverty_rate += partialResult.escape_from_poverty_rate;
        totalResult.fell_into_poverty_rate += partialResult.fell_into_poverty_rate;
      });
    }else{
      filterScope['getCountry'].forEach((countryCode,i)=>{
        let partialData = data[filterScope['getType']][filterScope['getCountry'][i]][this.presentDate.getFullYear()];
        if(partialData){
          Object.keys(partialData).forEach(function(ageRange){
            let partialResult = partialData[ageRange][filterScope['regionOrGender']][filterScope['getPovLevel']];
            totalResult.escape_from_poverty_rate += partialResult.escape_from_poverty_rate;
            totalResult.fell_into_poverty_rate += partialResult.fell_into_poverty_rate;
          });
        }
      });
    }
    var fellToday = Math.abs(totalResult.fell_into_poverty_rate)*secondsUntilDay;
    var escapedToday = Math.abs(totalResult.escape_from_poverty_rate)*secondsUntilDay;
    this.setState({clockData: { ...this.state.clockData,
      curYearEscapeRate: totalResult.escape_from_poverty_rate,
      curYearfallRate: Math.abs(totalResult.fell_into_poverty_rate),
      escapedPovertyToday: escapedToday,
      fellIntoPovertyToday: fellToday,
    }});
  }

  setCountryChances = () => {
    const orderChances = (array) => {
      array.sort(function (a, b) {
        if(a.chance < b.chance){
          return 1;
        }else if(a.chance > b.chance){
          return -1;
        }
          return 0;
      });
    }

    if (this.state.mapData){
      let fellProportion = [];
      let escapeProportion = [];
      let fellTotal = 0;
      let escapeTotal = 0;

      this.state.mapData.features.forEach( (feature) => {
          if(feature.properties.stable_track === 'rising'){
            fellTotal += Math.abs(feature.properties.escape_rate);
          }else if(feature.properties.tag !== 4){
            
            escapeTotal += Math.abs(feature.properties.escape_rate);
          }
      })
      
      this.state.mapData.features.forEach( (feature) => {
          if(feature.properties.stable_track === 'rising'){
            fellProportion.push({id: feature.id,chance: Math.abs(feature.properties.escape_rate)/fellTotal});
          }else if(feature.properties.tag !== 4){
        
            escapeProportion.push({id: feature.id,chance: Math.abs(feature.properties.escape_rate)/escapeTotal});
          }
      })
      orderChances(fellProportion);
      orderChances(escapeProportion);

      if(this.state.clockData){
        this.setState({clockData: { ...this.state.clockData,
          fellProportion: fellProportion,
          escapeProportion: escapeProportion,
        }});
      }
      }
  }


  getSecondsUntilYear = () => {
    let secondsUntilYear = 0;
    if(this.state.currentCountry === 'WORLD'){
      if (this.state.currentYear === this.presentDate.getFullYear()){
        let midYear = new Date(this.presentDate.getFullYear(),6,2);
        let newYear = new Date(this.presentDate.getFullYear(),0,1);
        secondsUntilYear = ((newYear.getTime() - midYear.getTime()) + (this.presentDate.getTime() - (newYear.getTime())))/1000;
      }else if(this.state.currentYear === 2031){
        let begginingLastYear = new Date(2031,0,1).getTime();
        let midLastYear = new Date(2031,6,2).getTime();
        secondsUntilYear = (begginingLastYear - midLastYear)/1000;
      }
    }
    return secondsUntilYear
  }
  getSecondsUntilDay = () => {
    let newDay = new Date(this.presentDate);
    newDay.setHours(0,0,0,0);
    let secondsUntilDay = (this.presentDate.getTime() - newDay.getTime())/1000;
    return secondsUntilDay;
  }


  setupInitialCountryCard = (countryCardCountry=[this.state.currentCountry]) => {
    //const secondsUntilYear = this.getSecondsUntilYear();
    
    this.setState({renderCountryChart: false},()=>{
      this.setState({cardData: { ...this.state.cardData,
        population:'',headcountInPoverty:'',headcountRural:'',percentageRural:'',headcountUrban:'',percentageUrban:'',headcountMale:'',percentageMale:'',headcountFemale:'',percentageFemale:'',filteredPopulation:'',filteredHeadcountInPoverty:''
      }},()=>{
        if(this.state.currentCountry !== 'WORLD'){
          this.handleCountryCard(false,true);
        }
        this.setState({lastYear: this.state.currentYear, lastCountry: this.state.currentCountry},()=>{
            let countryCardYear = this.state.currentYear;
            this.state.bridge.getData("cardContext","ages", [[...countryCardCountry], [countryCardYear], ["[00,INF)"], "both", "1.9"],(data)=>{
              let totalResult = { population: 0, headcount: 0, escape_rate: 0, target_escape_rate: 0 }
              countryCardCountry.forEach((countryCode,i)=>{
                let partialResult = data['ages'][countryCardCountry[i]][countryCardYear]['[00,INF)']["both"]["1.9"];
                totalResult.population += partialResult.population;
                totalResult.headcount += partialResult.headcount;
                totalResult.target_escape_rate += partialResult.target_escape_rate;
                totalResult.escape_rate += partialResult.escape_rate;
              })

              this.setState({cardData: { ...this.state.cardData,
                population: totalResult.population,
                headcountInPoverty: totalResult.headcount,
                targetEscapeRate: Math.abs(totalResult.target_escape_rate),
                currentEscapeRate: totalResult.escape_rate}},()=>{
                  this.state.bridge.getData("cardContext","ages", [[...countryCardCountry], [countryCardYear], ["[00,INF)"], "female", "1.9"],(data)=>{
                    let totalResult = { population: 0, headcount: 0, escape_rate: 0, target_escape_rate: 0 }
                    countryCardCountry.forEach((countryCode,i)=>{
                      let partialResult = data['ages'][countryCardCountry[i]][countryCardYear]['[00,INF)']["female"]["1.9"];
                      totalResult.population += partialResult.population;
                      totalResult.headcount += partialResult.headcount;
                      totalResult.target_escape_rate += partialResult.target_escape_rate;
                      totalResult.escape_rate += partialResult.escape_rate;
                    })
                    this.setState({cardData: { ...this.state.cardData,
                      headcountFemale: totalResult.headcount,
                      percentageFemale: totalResult.headcount / totalResult.population}},()=>{
                        this.state.bridge.getData("cardContext","ages", [[...countryCardCountry], [countryCardYear], ["[00,INF)"], "male", "1.9"],(data)=>{
                          let totalResult = { population: 0, headcount: 0, escape_rate: 0, target_escape_rate: 0 }
                          countryCardCountry.forEach((countryCode,i)=>{
                            let partialResult = data['ages'][countryCardCountry[i]][countryCardYear]['[00,INF)']["male"]["1.9"];
                            totalResult.population += partialResult.population;
                            totalResult.headcount += partialResult.headcount;
                            totalResult.target_escape_rate += partialResult.target_escape_rate;
                            totalResult.escape_rate += partialResult.escape_rate;
                          })
                          this.setState({cardData: { ...this.state.cardData,
                            headcountMale: totalResult.headcount,
                            percentageMale: totalResult.headcount / totalResult.population}},()=>{
                              this.state.bridge.getData("cardContext","regions", [[...countryCardCountry], [countryCardYear], "rural", "1.9"],(data)=>{
                                let totalResult = { population: 0, headcount: 0, escape_rate: 0, target_escape_rate: 0 }
                                countryCardCountry.forEach((countryCode,i)=>{
                                  let partialResult = data['regions'][countryCardCountry[i]][countryCardYear]["rural"]["1.9"];
                                  totalResult.population += partialResult.population;
                                  totalResult.headcount += partialResult.headcount;
                                  totalResult.target_escape_rate += partialResult.target_escape_rate;
                                  totalResult.escape_rate += partialResult.escape_rate;
                                })
                                this.setState({cardData: { ...this.state.cardData,
                                  headcountRural: totalResult.headcount,
                                  percentageRural: totalResult.headcount / totalResult.population}},()=>{
                                    this.state.bridge.getData("cardContext","regions", [[...countryCardCountry], [countryCardYear], "urban", "1.9"],(data)=>{
                                      let totalResult = { population: 0, headcount: 0, escape_rate: 0, target_escape_rate: 0 }
                                      countryCardCountry.forEach((countryCode,i)=>{
                                        let partialResult = data['regions'][countryCardCountry[i]][countryCardYear]["urban"]["1.9"];
                                        totalResult.population += partialResult.population;
                                        totalResult.headcount += partialResult.headcount;
                                        totalResult.target_escape_rate += partialResult.target_escape_rate;
                                        totalResult.escape_rate += partialResult.escape_rate;
                                      })
                                      this.setState({cardData: { ...this.state.cardData,
                                        headcountUrban: totalResult.headcount,
                                        percentageUrban: totalResult.headcount / totalResult.population}})
                                        this.setState({renderCountryChart: true},()=>{
                                          if(this.state.continentsDisplay){
                                            if(this.state.currentCountry !== 'WORLD'){
                                              this.handleCountryCard(false,true);
                                            }else{
                                              this.handleCountryCard(false);
                                            }
                                          }
                                        })
                                      },true);
                                  })
                                },true);
                            })
                          },true);
                      })
                  },true);
                })
            },true);
        });
      })
    })

  }

  handleCountryChange = (countryCode, countryLabel) => {
    if(this.state.subnationalDisplay && countryCode !== 'WORLD'){
      this.handleSubnational(true);
    }
    if(countryCode === 'WORLD'){
      this.state.bridge.addRealtimeContext("clockContext");
    }
    if(!this.state.continentsDisplay || countryCode.length === 3){
      if(this.state.continentsDisplay){
        this.handleContinentsToggle();
      }
      setTimeout(() => {
        let countryHasSubnational = false;
        let openCountryCard = true;
        this.setState({currentCountry: countryCode,currentCountryLabel: countryLabel},()=>{
            this.setupClockForBridge(true,true);
            this.state.mapData.features.forEach( (feature) => {
              //Don't open country cards for countries with poverty below 3%
              if(feature.id === countryCode && (feature.properties.stable_track === 'nopoverty' || feature.properties.stable_track === undefined)){
                openCountryCard = false
              }
            })
            //if(countryCode === 'BRA' || countryCode === 'KEN') {
            if(countryCode === 'KEN') { 
              countryHasSubnational = true;
            }
            if(!this.state.mapDisplay && countryCode !== 'WORLD'){
              window.turnTo(2);
              
              this.toggleMap();
              if(countryCode !== 'WORLD' && this.state.showCountryCard === true && openCountryCard ){
                  this.handleCountryCard(false, true);
                  openCountryCard = false;
              }else if(countryCode !== 'WORLD' && openCountryCard){
                  this.handleCountryCard(true);
              }
            }
            if (this.state.mapDisplay && this.state.showCountryCard === true && countryCode !== 'WORLD' && openCountryCard) { // open modal if using select to choose a country at the map
                this.handleCountryCard(false, true);
            }else if(countryCode !== 'WORLD' && openCountryCard){
                this.handleCountryCard(true);
            }

            if ( countryHasSubnational ) {
              this.setState({countryHasSubnational: true});
            } else {
              //this.setState({countryHasSubnational: false, subnationalDisplay: false});
              this.setState({countryHasSubnational: false});
            }
        });
      }, 250);
      
    }else{
      if(!this.state.mapDisplay){
        window.turnTo(2);
        //this.toggleMap();
      }
      this.setState({currentCountry: countryCode,currentCountryLabel: countryLabel},()=>{
        this.setupContinentForBridge(undefined,countryCode);
      });

    }
    
  }


  handleSubnational = (subnationalToggle = false) => {
      if(this.state.subnationalDisplay && this.state.currentCountry === 'WORLD'){
        this.setState({subnationalDisplay: false});
      }else{
        let subnationalAreas = this.state.clockData.subnationalCodes[this.state.currentCountry];
        let aggregatedQuery = [];
        const subnationalYear = this.state.currentYear;
        const subnationalPoverty = this.state.povertyLevel;
    
        subnationalAreas.forEach((subarea)=>{
          aggregatedQuery.push(subarea[0]);
        });
    
        this.state.bridge.getData("mapContext","ages", [aggregatedQuery, [subnationalYear], ["[00,INF)"], "both", subnationalPoverty], (data) => {
          let regions = {};
          let jsonRegions = '[';
        
          Object.keys(data['ages']).forEach((key)=>{
            let totalResult = data['ages'][key][subnationalYear]["[00,INF)"]["both"][subnationalPoverty];
            let regionCode = key.split('-')[2];
            regions = {"code":regionCode, "headcount_in_poverty":totalResult.headcount,"population":totalResult.population,"stable_track":totalResult.stable_track,"escape_rate":totalResult.escape_rate,"target_escape_rate":totalResult.target_escape_rate,"percentage_world":totalResult.headcount/this.state.clockData.headcountInPoverty, "percentage_country": totalResult.headcount/totalResult.population};
            jsonRegions += JSON.stringify(regions)+',';
          });
          jsonRegions = jsonRegions.slice(0, -1);
          jsonRegions += ']';
          
          let joinMap;
          if(this.state.currentCountry === 'KEN'){
            joinMap = {
              geoKey:'properties.code',
              dataKey: 'code'
            }
            import('./kenyaSubnationalGeoJSON')
            .then(( kenyaSubnationalGeoJSON ) => {
              ExtendedGeoJSON(kenyaSubnationalGeoJSON.default, JSON.parse(jsonRegions), joinMap);
              this.setState({subnationalData: kenyaSubnationalGeoJSON.default},()=>{
                this.setupSubnationalMap(subnationalToggle);
              });
            })
            
          }else if(this.state.currentCountry === 'BRA'){
             joinMap = {
              geoKey:'id',
              dataKey: 'code'
            }
            import('./brazilSubnationalGeoJSON')
            .then(( brazilSubnationalGeoJSON ) => {
              ExtendedGeoJSON(brazilSubnationalGeoJSON.default, JSON.parse(jsonRegions), joinMap);
              this.setState({subnationalData: brazilSubnationalGeoJSON.default},()=>{
                  this.setupSubnationalMap(subnationalToggle);            
              });
            })    
          }
          if(subnationalToggle){
            this.handleCountryCard(false,false,true);
          }
        },true);
      }
  }

  setupSubnationalMap = (subnationalToggle) => {
    if(subnationalToggle){
      if(!this.state.subnationalDisplay){
        this.resetFilters();
      }
      this.setState({subnationalDisplay: !this.state.subnationalDisplay});
    }
  }
  handleContinentsToggle = () => {
    
    let timeoutForChange = 0
    if(this.state.subnationalDisplay){
      this.handleSubnational(true);
      timeoutForChange = 250;
    }
    
    setTimeout(() => {
      this.setState({currentCountry: 'WORLD'});
      
      this.setState({continentsDisplay: !this.state.continentsDisplay},()=>{
        if(this.state.continentsDisplay){
          this.setState({currentCountryLabel:'All continents'})
          this.state.handleCountryCard(false);
          this.setState({clockData: { ...this.state.clockData,
            selectorList: this.state.clockData.continents,
          }})
        }else{
          this.handleCountryChange('WORLD','All countries');
          this.state.handleCountryCard(false);
          this.setState({clockData: { ...this.state.clockData,
            selectorList: this.state.clockData.countries,
          }})
        }
      });
    }, timeoutForChange);
    

  }
  
  mouseWheelDefault = '';
  handleModal = (showModal, page) => {
    if(showModal){
      this.mouseWheelDefault = document.onmousewheel;
      window.onmousewheel = document.onmousewheel = '';
    }else{
      window.onmousewheel = document.onmousewheel = this.mouseWheelDefault;
      this.mouseWheelDefault = '';
    }
    this.setState({showModal: showModal, modalPage: page});
  }

  handleCountryCard = (showCard, reopen=false, keepSubnational = false) => {
      if(this.state.showCountryCard && !showCard && !reopen && !window.matchMedia("(min-width: 768px)").matches){
        setTimeout(() => {
          //if(!this.state.subnationalDisplay){
            this.handleCountryChange('WORLD','All countries',keepSubnational);
          //}
        }, 500);
      }
      this.setState({showCountryCard: showCard},()=>{
        if(reopen){
          this.setState({showCountryCard: !showCard});
        }
      });
    
    
  }

  handleRegion = (region) => {
    this.setState({currentRegion: region});
  }
  toggleScroll = (mouseLeave) => {
      if(mouseLeave){
        this.setState({
          isAllowedTransition: true
        })
      }else{
        this.setState({
          isAllowedTransition: false
        })
      }
  }
  toggleMap = () => {
    this.setState({mapDisplay: !this.state.mapDisplay});
  }
  toggleFooter = () => {
    this.setState({isFooter: !this.state.isFooter})
  }
  toggleCardReload = () => {
    this.setState({modalReload: !this.state.modalReload});
  }
  toggleLenses = (newLens) => {
    this.setState({currentMapLens: newLens});
  }

  getCurrentFilterScope = (multipleCountries) => {
    let currentFilterScope = {
      getCountry: [this.state.currentCountry],
      getAgeRange: [...this.state.currentAgeRange],
      getYear: this.state.currentYear,
      getPovLevel: this.state.povertyLevel,
      isRegion: this.state.groups[0].hasActiveFilter,
      isDemography: this.state.groups[1].hasActiveFilter,
      getType: 'ages',
      regionOrGender: 'both'
    };
    if(multipleCountries){
      currentFilterScope['getCountry'] = multipleCountries;
    }
    
    if(this.state.currentAgeRange.length === 0){
      currentFilterScope['getAgeRange'].push('[00,INF)');
    }

    if(currentFilterScope['isRegion']){
      currentFilterScope['getType'] = 'regions';
      if(this.state.filters[0][0] === true){
        currentFilterScope['regionOrGender'] = 'rural';
      }else{
        currentFilterScope['regionOrGender'] = 'urban';
      }
    }else if(currentFilterScope['isDemography']){
      if(this.state.filters[1][0] === true && this.state.filters[1][1] === false){
        currentFilterScope['regionOrGender'] = 'female';
      }else if(this.state.filters[1][0] === false && this.state.filters[1][1] === true){
        currentFilterScope['regionOrGender'] = 'male';
      }else{
        currentFilterScope['regionOrGender'] = 'both';
      }
    }
    return currentFilterScope;
  }
  escapeRateTimeout = ''
  dynamicTickerCurrentEscapeRate(){
    clearTimeout(this.escapeRateTimeout);
    let frequency = 10;
    if (this.state.clockData && this.state.clockData.currentEscapeRate > 0) {
      frequency = this.state.clockData.currentEscapeRate
    }
    //if no clock data is loaded, check every 1s without ticking

    this.escapeRateTimeout = setTimeout( () => {
      if(this.state.clockData && this.state.currentYear !== 2031 && !this.state.showCountryCard) {

          this.setState({clockData: { ...this.state.clockData,
            headcountInPoverty: this.state.clockData.headcountInPoverty - 1
          }})
        }
        
        this.dynamicTickerCurrentEscapeRate();
      }, 1000/frequency);
    }

  fallRateTimeout = '';
  dynamicTickerCurrentFallRate(){
    clearTimeout(this.fallRateTimeout);
    let frequency = 20;
    if (this.state.clockData && this.state.clockData.fallRate > 0) { frequency = this.state.clockData.fallRate } //if no clock data is loaded, check every 1s without ticking
      this.fallRateTimeout = setTimeout( () => {
        if(this.state.clockData && this.state.currentYear !== 2031 && !this.state.showCountryCard) {

          this.setState({clockData: { ...this.state.clockData,
            headcountInPoverty: this.state.clockData.headcountInPoverty + 1,
          }})
        }
        
        this.dynamicTickerCurrentFallRate();
      }, 1000/frequency);
    }
  fellTodayTimeout = '';
  dynamicTickerFellToday(){
    clearTimeout(this.fellTodayTimeout);
    let frequencyFall = 20;
    if(this.state.clockData){
      frequencyFall = this.state.clockData.curYearfallRate;
    }
    this.fellTodayTimeout = setTimeout( () => {
      if(this.state.clockData) {
        this.setState({clockData: { ...this.state.clockData,
          fellIntoPovertyToday: this.state.clockData.fellIntoPovertyToday + 1,
        }})
      }
      
      this.dynamicTickerFellToday();
    }, 1000/frequencyFall);
  }
  escapedTodayTimeout = ''
  dynamicTickerEscapedToday(){
    clearTimeout(this.escapedTodayTimeout);
    let frequencyEscape = 20;
    if(this.state.clockData){
      frequencyEscape = this.state.clockData.curYearEscapeRate;
    }
    this.escapedTodayTimeout = setTimeout( () => {
      if(this.state.clockData) {
        this.setState({clockData: { ...this.state.clockData,
          escapedPovertyToday: this.state.clockData.escapedPovertyToday + 1,
        }})
      }
      
      this.dynamicTickerEscapedToday();
    }, 1000/frequencyEscape);
  }

  dynamicTickerOfftrack(){
      this.interval = setInterval(() => {
        if (this.state.clockData === undefined || this.state.currentYear === 2031) { return }
        this.setState({clockData:
          { ...this.state.clockData,
            offTrackHeadcount: this.state.clockData.offTrackHeadcount + this.state.clockData.offTrackChangeRate,
          }})
      }, 1000)

  }
  
  createWorldGeoJSON(countryJSON){
    var joinMap = {
      geoKey:'id',
      dataKey: 'code',
    };
    ExtendedGeoJSON(worldGeoJSON, countryJSON, joinMap);
    if(!this.state.isMapReady)
      this.setState({isMapReady: true});
  }
  
  getCountryData = () => {
    let year = getDate().year;
    this.setupNoPovFilter(false);
    this.state.bridge.getData("mapContext","ages", [["ALL", "WORLD"], [year], ["[00,INF)"], "both", "1.9"],this.updateMapFromBridge,true);

  }

  getClockData = () => {
    let year = getDate().year;
    this.state.bridge.getData("clockContext","consts",(data)=>{
      const age_groups = data.age_groups;
      const countries = data.country_codes;
      const areaCountries = data.regions_country_codes;
      const animation_countries = data.animation_countries;
      const subnational_county_codes = data.subnational_county_codes;
      var continents = [];
      
      countries.sort((a,b) => a[1].localeCompare(b[1]));

      continentsJSON.forEach((continent)=>{
        continents.push(Object.values(continent));
      })
      continents.unshift(['WORLD','All Continents']);
      countries.unshift(['WORLD','All Countries']);
      age_groups.unshift(['all ages','all ages']);
      /*let countries_filtered = []
      this.state.mapData.features.forEach( (feature) => {
        
        if(feature.properties.tag !== 3 && feature.properties.tag !== 4){
          countries_filtered.push([feature.id,feature.properties.name])
        }
      });
      */
      this.setState({
        clockData: Object.assign({}, this.state.clockData, {
          countries: countries,
          continents: continents,
          areaCountries: areaCountries,
          animationCountries: animation_countries,
          ageRanges: age_groups,
          subnationalCodes: subnational_county_codes,
          selectorList: countries,
        })
      });
    });
    this.state.bridge.getData("clockContext", "ages", [["WORLD"], [year], ["[00,INF)"], "both", "1.9"], this.updateClockFromBridge, true);
  }

  componentDidMount() {
    
      this.getClockData();
      this.getCountryData();
      this.dynamicTickerOfftrack();
      this.dynamicTickerCurrentEscapeRate();
      this.dynamicTickerCurrentFallRate();
      this.dynamicTickerFellToday();
      this.dynamicTickerEscapedToday();
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    clearTimeout(this.escapedTodayTimeout);
    clearTimeout(this.fellTodayTimeout);
    clearTimeout(this.fallRateTimeout);
    clearTimeout(this.escapeRateTimeout);
  }
  render() {
    // @Poli – below there are <Router>, <Switch> and <Router> tags that are the part of the react-router library. You'll find more info in it's docs
    return (
      <StyledApp>
      <MemoryRouter>
        <Router basename={process.env.PUBLIC_URL} onUpdate={() => window.scrollTo(0, 0)}>
          <main className="main">
            <Switch>
              <Route
                path="/headline"
                render={(props) =>
                  <Headline
                    {...this.state}
                    />}
              />
              <Route
                path="/map"
                render={(props) =>
                  <Headline
                    {...this.state}
                    mapDisplay={true}
                    />}
              />
              <Route
                path="/about"
                render={(props)=>
                  <Article {...this.state} page="about" />
                }
              />
              <Route
                path="/methodology"
                render={(props)=>
                  <Article {...this.state} page="methodology" />
                }
              />
              <Route
                path="/"
                render={(props) =>
                  <Home
                    {...this.state}
                    />}
              />
            </Switch>
          </main>
        </Router>
      </MemoryRouter>
      </StyledApp>
    )
  }
}


// @Poli – below code is a part of styled-components. It's fairly well documented so you should not have any issues with working with it ;)
const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: auto;
  position: relative;

  .main {
    flex: 1;
  }
`
export default App;
