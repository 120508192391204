import React from 'react';
import styled from 'styled-components';

export default function Bookmark(props) {
  // @Poli – if you want to use props with styled-components, you have to spread them like 2 lines below.
  return (
    <StyledBookmark {...props} className="bookmark">
      <div className="bookmark-container">
        {
          props.icon
          ? <span className="bookmark-icon">{props.icon}</span>
          : ''
        }
        <span className="bookmark-label label">{props.label}</span>
      </div>
    </StyledBookmark>
  )
}

const StyledBookmark = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  color: #ffffff;
  font-size: .875em;
  text-align: right;

  .bookmark {
    &-container {
      flex: 0 1 auto;
      display: inline-flex;
      align-items: center;
      min-height: 4em;
      margin-left: 2em;
      padding: .5em 2.5em .5em 1em;
      position: relative;
      background-color: ${props => props.bg || '#000' };
      white-space: pre-wrap;

      &:before,
      &:after {
        content: '';
        display: block;
        height: 3em;
        width: 3em;
        margin-right: -1.5em;
        position: absolute;
        right: 100%;
        border: 2em solid ${props => props.bg || '#000' };
        border-left-color: transparent;
        transform: scaleX(.75);
        transform-origin: right;
      }

      &:before {
        top: 0;
        border-bottom-color: transparent;
      }

      &:after {
        bottom: 0;
        border-top-color: transparent;
      }
    }

    &-icon {
      margin-right: 1em;
    }

    &-label {
      &:first-child {
        margin-left: 1em;
      }
    }
  }
`
