import React, {Component}  from 'react';
import Person from '../components/Person';
import Morph from '../components/Morph';
import styled from 'styled-components';

export default class People extends Component {
    constructor(props) {
      super(props);
      this.state = {
            peopleArray: [],
            morphArray: [],
            escapedPovertyToday: 0,
            fellIntoPovertyToday: 0,
      }
    }
    oldEscapedPovertyToday = 0
    oldFellIntoPovertyToday = 0
    number = 0
    country = '';
    escapedPoverty = false;

    dynamicTickerCurrentEscapeRate(){
        let frequency = 1;

        if (this.props.clockData && this.props.clockData.currentEscapeRate > 0) { frequency = this.props.clockData.currentEscapeRate} //if no clock data is loaded, check every 1s without ticking


        setTimeout( () => {
          if(this.props.clockData && this.props.currentYear !== 2031) {
            this.createPerson(false);
          }
          if(this.props.clockData.headcountInPoverty){
            this.number = Math.trunc(this.props.clockData.headcountInPoverty).toString().split('').pop();
          }
          this.dynamicTickerCurrentEscapeRate();

        }, 1000/frequency);
      }

    dynamicTickerCurrentFallRate(){
        let frequency = 1;
        if (this.props.clockData && this.props.clockData.fallRate > 0) { frequency = this.props.clockData.fallRate} //if no clock data is loaded, check every 1s without ticking

        setTimeout( () => {
            if(this.props.clockData && this.props.currentYear !== 2031) {
            this.createPerson(true)
            }
            if(this.props.clockData.headcountInPoverty){
              this.number = Math.trunc(this.props.clockData.headcountInPoverty).toString().split('').pop();
            }
            this.dynamicTickerCurrentFallRate();

        }, 1000/frequency);
    }

    componentDidMount() {
      this.dynamicTickerCurrentEscapeRate();
      this.dynamicTickerCurrentFallRate();
    }
    componentWillUnmount() {
      clearTimeout(this.runnerTimout);
      clearTimeout(this.cleanTimeout);
    }

    shuffleCountry(arrayProportion){
      //console.log(arrayProportion);
      const random = Math.random();
      let accumulator = 0;
      let selected = '';
      arrayProportion.every((countryTuple)=>{
        accumulator += countryTuple.chance;
        if(random < accumulator){
          selected = countryTuple.id;
          return false
        }else{
          return true;
        }
      });
      
      return selected;
    }

    createPerson = (fell) => {
        if(this.props.clockData.animationCountries){
          var rand1 = Math.random() >= 0.5;
          var number = parseInt(this.number);
          var gender = '';
          if(this.props.filters[1] && (this.props.filters[1][0] && !this.props.filters[1][1])){
            gender = 'female'
          }else if(this.props.filters[1] && this.props.filters[1][1] && !this.props.filters[1][0]){
            gender = 'male'
          }else{
            gender = rand1? 'male' : 'female'
          }
  
          var uniqueId = Date.now() / Math.round(Math.random()*10000);
          var countryCode = '';
              if(this.props.clockData.fellProportion){
                if(fell){
                  countryCode = this.shuffleCountry(this.props.clockData.fellProportion);
                }else{
                  //console.log(this.props.clockData.escapeProportion);
                  
                  countryCode = this.shuffleCountry(this.props.clockData.escapeProportion);
                }
              }
              this.props.clockData.countries.some((country) => {
                  
                  if(country[0] === countryCode){
                    setTimeout( () => {
                      this.country = country[1];
                      this.escapedPoverty = !fell;
                    },1650)
                    return true
                    
                    
                  }else{ return false }
              });
  
          this.setState({morphArray: [...this.state.morphArray, {gender, fell, number, uniqueId}] }) //starts morphing immediately
          
          this.runnerTimout = setTimeout( () => {//person starts running after given time
              this.setState({peopleCreated: this.state.peopleArray.push({gender, fell, uniqueId})})
          }, 1000);
  
          this.cleanTimeout = setTimeout( () => {//clean up
              this.state.peopleArray.shift()
              this.state.morphArray.shift();
              
          }, 3000);
        }
      
      //console.log('person created');
      
    }
    render(){
          let countryStyles = {
            color: 'black',
        };
        if (!this.escapedPoverty){
          countryStyles = {color: '#EE4422'}
        }
        

        return(<StyledPeople>
            {this.state.morphArray.map( (value, i) => {
                return (<Morph key={value.uniqueId} person = {value} {...this.props}></Morph>)
            })}
            {this.state.peopleArray.map( (value, i) => {
                return (<Person key={value.uniqueId} person = {value}></Person>)
            })}
            {
              this.props.currentYear !== 2031 ?
              <div className='current-country' style={countryStyles} >
                {this.country}
              </div>
              :
              ''}

        </StyledPeople>)
    }
}


const StyledPeople = styled.div`
    height: 1px;
    .current-country{
      text-transform: uppercase;
      position: absolute;
      margin-left: 38rem;
      margin-top: 97px;
    }

`
