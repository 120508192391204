import React from 'react';
import IconTwitter from '../_/img/icon-twitter.png';
import IconFacebook from '../_/img/icon-facebook.png';
import IconLinkedin from '../_/img/icon-linkedin.png';
import IconWeb from '../_/img/icon-web.png';
import styled from 'styled-components';

export default function Socials() {
  return (
    <StyledSocials className="socials">
        <a href="https://twitter.com/worlddatalab" rel="noopener noreferrer" className="socials-item" target="_blank">
            <img src={IconTwitter} alt="Twitter"/>
        </a>
        <a href="https://www.facebook.com/worlddatalab" rel="noopener noreferrer" className="socials-item" target="_blank">
            <img src={IconFacebook} alt="Facebook"/>
        </a>
        <a href="https://www.linkedin.com/company/world-data-lab/" rel="noopener noreferrer" className="socials-item" target="_blank">
            <img src={IconLinkedin} alt="LinkedIn"/>
        </a>
        <a href="https://www.worlddata.io" rel="noopener noreferrer" className="socials-item" target="_blank">
            <img src={IconWeb} alt="WDL Website"/>
        </a>
    </StyledSocials>
  )
}

const StyledSocials = styled.div`
    display: flex;
    .socials{
        &-item{
            display: flex;
          align-items: center;
          justify-content: center;
          height: 2.5em;
          width: 2.5em;
          padding: .5em;
          background-color: #fff;
          border-radius: 50vw;
          margin-bottom: .5em;
          box-shadow: 0 0.1vw 0.25vw 0 rgba(0,0,0,0.5);
          transition: 150ms;
          transition-property: opacity, visibility, transform;
          > img {
            max-width: 100%;
            max-height: 100%;
          }

          &:not(:last-child) {
            margin-right: 1em;
          }
        }
    }
    .footer-socials-item{
          
      }
    .socials-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5em;
    width: 2.5em;
    padding: .5em;
    background-color: currentColor;
    border-radius: 50vw;

    > img {
        max-width: 100%;
        max-height: 100%;
    }

        &:not(:last-child) {
            margin-right: 1em;
        }
    }
`
