//https://api.worldpoverty.io/LB0Bq1Tq3HWjL3F5ycnf2IEqxILfUStr/  <-- with PUBLIC KEY (jsons blocked only crypted binary)
//Base64 encoded API URL aHR0cHM6Ly9hcGktdjEud29ybGRwb3ZlcnR5LmlvL0xCMEJxMVRxM0hXakwzRjV5Y25mMklFcXhJTGZVU3RyLw==
function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}
export default class BackendBridge {
  constructor(props) {
    this.settings = {
      "dataCache": {},
      "dest":"aHR0cHM6Ly9hcGktdjEud29ybGRwb3ZlcnR5LmlvL0xCMEJxMVRxM0hXakwzRjV5Y25mMklFcXhJTGZVU3RyLw==", //To protect in case if someone finding binary decryption function in deployed project
      "format": "P2Zvcm1hdD1iaW5hcnk=", //base64encoded "?format=binary"
      "currentType": "",
      "currentProps": "",
      "tempData": {},
      "include_escape_rates": false,
      "ier": "aW5jbHVkZV9lc2NhcGVfcmF0ZXM9dHJ1ZQ=="

    }
      this.tempQueue = [];
      this.downloadingConsts = true;
      this.getData("default_bb", "init_consts", this.setupConsts);
  }
    setupConsts = () => {
      this.downloadingConsts = false;
      for(var i = 0; i < this.tempQueue.length; i++){
        let args = this.tempQueue[i];
        this._getData(args[0],args[1],args[2],args[3],args[4]);
      }
      this.tempQueue = [];
      this.queue = [];
      this.timeToCall = 100;
      this.callTriggered = false;
      this.queueCollector = setInterval(this.queueCollectorFunction, 10);
    }

  /*e.g calls:
    -call for AGES.CSV data: getData("ages", [["POL", "GBR", "USA"], [2016,2019,2020], ["[00,15)", "[05, INF)"], "both", "1.9"]);
    -call for RGIONS.CSV data: getData("regions", [["GER", "GBR", "BRA", "AFG"], [2019,2012,2025], "urban", "1.9"]);
  */
  addRealtimeContext = (context) => {
    if(this.realTimeContextes === undefined)this.realTimeContextes = {};
    this.realTimeContextes[context] = true;
  }
  removeRealtimeContext = (context) => {
    if(this.realTimeContextes === undefined || this.realTimeContextes[context] === undefined)return;
    this.realTimeContextes[context] = false;
  }
  queueCollectorFunction = () => {
    if(this.timeToCall > 0){
      this.timeToCall-=10;
    }
    if(this.callTriggered && this.timeToCall <= 0){
      let cleaned_queue = [];
      let c, i;
      for(i = this.queue.length-1; i >= 0; i--){
        let skip = false;
        for(c = 0; c < cleaned_queue.length; c++){
          if(this.queue[i][0] === cleaned_queue[c][0] && this.queue[i][1] === cleaned_queue[c][1]){
            skip = true;
            break;
          }
        }
        if(skip)continue;
        cleaned_queue.push(this.queue[i]);
      }
      this.queue = [];
      for(c = 0; c < cleaned_queue.length; c++){
        let args = cleaned_queue[c];
        let gD = this._getData;
        setTimeout(function(){gD(args[1],args[2],args[3],args[4]);}, c*100);
      }
      this.callTriggered = false;
    }
  }
  queueTimeout = () => {

  }
  getData = (context, type, props, callback, include_escape_rates) => {
    this.timeToCall = 100;
    this.callTriggered = true;
    if(this.downloadingConsts && type !== "init_consts"){
      this.tempQueue.push([type, props, callback, include_escape_rates]);
      return;
    }
    if(type === "init_consts"){
      type = "consts";
      this._getData(type, props, callback, include_escape_rates);
      return;
    }
    if(this.realTimeContextes && this.realTimeContextes[context]){ //bypasss from queue realtime contextes
      this._getData(type, props, callback, include_escape_rates);
      return;
    }
    this.queue.push([context, type, props, callback, include_escape_rates]);
  }
  _getData = (type, props, callback, include_escape_rates) => {

    if(include_escape_rates === undefined || include_escape_rates !== true)include_escape_rates = false;
    let query, error = "";
    this.settings.include_escape_rates = include_escape_rates;
    this.settings.currentType = type;
    this.settings.currentProps = props;
    let req;
    if(type === "consts") {
      this.settings.callback = props;
      if(typeof props !== "function"){
        error+="getData(\"consts\", <<second argument in case of 'consts' request must be callback function>>);\n";
        console.error("Error trying call function", error);
        return error;
      }
      if(this.settings.dataCache[this.settings.currentType] !== undefined){
        this.settings.callback(this.settings.dataCache[this.settings.currentType]);
        return;
      }
      req = new XMLHttpRequest();
      req.open('GET', atob(this.settings.dest)+"consts"+atob(this.settings.format), true/*async*/);
      req.onreadystatechange = this.xhrReadyStateChange;
      req.currentType = this.settings.currentType;
      req.callback = this.settings.callback;
      req.onreadystatechange = this.xhrReadyStateChange;
      // eslint-disable-next-line
      eval("req.send(null);"); // 'EVAL' to prevent find root of decrypt function in Chrome NetworkDevTools (Code executed by Eval exists in separate VM)
      return;
    }
    this.settings.callback = callback;

    if(type !== "regions" && type !== "ages")error+="getData(\"<<wrong type{"+type+"}>>\", ...);\n";
    if(typeof props !== "object")error+="getData(\"regions & ages\", <<props must be represented as array>>);\n";
    if((type === "regions" && props.length < 4) || (type === "ages" && props.length < 5))error+=`getData("${type}", <<too few elements in properties array>>);\n`;

    if(error !== ""){
      console.error("Error trying call function", error);
      return {"error": "Error trying call function "+error};
    }

    if(typeof props[0] !== "object")error+="getData(\"regions & ages\", [<<this property must be a array of strings>>, x, ...]);\n";
    else if(typeof props[1] !== "object")error+="getData(\"regions & ages\", [x, <<this property must be a array of integers>>, ...]);\n";
    switch(type){
      case "ages":
        //FORMAT: (array)<ID> / (array)<YEARS> / (array)<AGE_GROUPS> / (string)GENDER{"male","female","both"} / (string)DAILY_SPENDING
        if(typeof props[2] !== "object")error+="getData(\"ages\", [x, x, <<this property must be a array of strings>>, x]);\n";
        if(typeof props[3] != "string")error+="getData(\"ages\", [x, x, x, <<this property must be a string>>]);\n";
        if(typeof props[4] != "string")error+="getData(\"ages\", [x, x, x, <<this property must be a string>>]);\n";
      break;
      default: // regions
        //FROMAT: (array)<ID>{e.g GBR,USA,POL} / (array)<YEARS>{from 2000 to 2030} / (string)REGION{"urban", "rural"} / (string)DAILY_SPENDING{"1.9","3.2","5.5","national"}
        if(typeof props[2] != "string")error+="getData(\"regions\", [x, x, <<this property must be a string>>, x]);\n";
        if(typeof props[3] != "string")error+="getData(\"regions\", [x, x, x, <<this property must be a string>>]);\n";
      break;
    }

    if(error !== ""){
      console.error("Error trying call function", error);
      return {"error": "Error trying call function "+error};
    }
    if(props[0][0] === "ALL"){
      var arr = (type === "regions" ? this.settings.dataCache["consts"].regions_country_codes : this.settings.dataCache["consts"].country_codes);
      props[0] = [];
      arr.forEach(function(country,i) {
        props[0].push(country[0]);
      });
    }

    //Check Cache
    let tempData = {};
    let leftovers = {areas: this.settings.currentProps[0].slice(0)}

    if(this.settings.dataCache[this.settings.currentType] !== undefined){
      tempData[this.settings.currentType]={};
      let cached, a, y;
      for(a in this.settings.currentProps[0]){
        cached = true;
        let area=this.settings.currentProps[0][a];
        tempData[this.settings.currentType][area]={};
        if(this.settings.dataCache[this.settings.currentType][area] === undefined){ cached = false; continue; }
        for(y in this.settings.currentProps[1]){
          let year=this.settings.currentProps[1][y];
          tempData[this.settings.currentType][area][year]={};
          if(this.settings.dataCache[this.settings.currentType][area][year] === undefined){ cached = false; continue; }
          if(this.settings.currentType === "regions"){
            tempData[this.settings.currentType][area][year][this.settings.currentProps[2]]={};
            if(this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]] === undefined){ cached = false; continue; }
            if(this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]] === undefined){ cached = false; continue; }
            tempData[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]]={"headcount": this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].headcount, "population": this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].population, "sdg_headcount":this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].sdg_headcount, "stable_track": this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].stable_track};

            if(include_escape_rates){
              if(this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].escape_rate !== undefined && this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].target_escape_rate !== undefined){
                tempData[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].escape_rate = this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].escape_rate;
                tempData[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].target_escape_rate = this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].target_escape_rate;
              }else { cached = false; continue; }
              if(area === "WORLD") {
                if(this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].fell_into_poverty_rate !== undefined && this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].escape_from_poverty_rate !== undefined){
                  tempData[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].fell_into_poverty_rate = this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].fell_into_poverty_rate;
                  tempData[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].escape_from_poverty_rate = this.settings.dataCache[this.settings.currentType][area][year][this.settings.currentProps[2]][this.settings.currentProps[3]].escape_from_poverty_rate;
                }else { cached = false; continue; }
              }
            }
          }else {
            for(a in this.settings.currentProps[2]){
              let age_group = this.settings.currentProps[2][a];
              tempData[this.settings.currentType][area][year][age_group]={};tempData[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]]={};
              if(this.settings.dataCache[this.settings.currentType][area][year][age_group] === undefined){ cached = false; continue; }
              if(this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]] === undefined){ cached = false; continue; }
              if(this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]] === undefined){ cached = false; continue; }
              tempData[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]]={"headcount": this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].headcount, "population":this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].population, "sdg_headcount":this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].sdg_headcount, "stable_track": this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].stable_track};

              if(include_escape_rates){
                if(this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].escape_rate !== undefined && this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].target_escape_rate !== undefined){
                  tempData[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].escape_rate = this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].escape_rate;
                  tempData[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].target_escape_rate = this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].target_escape_rate;
                }else { cached = false; continue; }
                if(area === "WORLD") {
                  if(this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].fell_into_poverty_rate !== undefined && this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].escape_from_poverty_rate !== undefined){
                    tempData[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].fell_into_poverty_rate = this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].fell_into_poverty_rate;
                    tempData[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].escape_from_poverty_rate = this.settings.dataCache[this.settings.currentType][area][year][age_group][this.settings.currentProps[3]][this.settings.currentProps[4]].escape_from_poverty_rate;
                  }else { cached = false; continue; }
                }
              }
            }
          }
        }
        if(cached)leftovers.areas.splice(leftovers.areas.indexOf(area), 1);
      }
    }
    if(leftovers.areas.length === 0){
      this.settings.callback(tempData);
      return;
    }
    this.settings.currentProps[0] = leftovers.areas;

    if(this.settings.currentType === "regions") query = `regions/${this.settings.currentProps[0].join(",")}/${this.settings.currentProps[1].join(",")}/${this.settings.currentProps[2]}/${this.settings.currentProps[3]}`
    else query = `ages/${this.settings.currentProps[0].join(",")}/${this.settings.currentProps[1].join(",")}/${this.settings.currentProps[2].join(",")}/${this.settings.currentProps[3]}/${this.settings.currentProps[4]}`;


    req = new XMLHttpRequest();
    req.open('GET', atob(this.settings.dest)+query+atob(this.settings.format)+"&"+(include_escape_rates ? atob(this.settings.ier) : ""), true/*async*/);
    req.responseType = 'arraybuffer';
    req.currentType = this.settings.currentType;
    req.currentProps = {};
    req.callback = this.settings.callback
    req.include_escape_rates = this.settings.include_escape_rates;
    Object.assign(req.currentProps, this.settings.currentProps);
    req.onreadystatechange = this.xhrReadyStateChange;
    // eslint-disable-next-line
    eval("req.send(null);"); // 'EVAL' to prevent find root of decrypt function in Chrome NetworkDevTools (Code executed by Eval exists in separate VM) /* eslint-disable */
  }
  xhrReadyStateChange = (evt) => {
    let req = evt.currentTarget;
    if (req.readyState === 4) {
      if (req.status === 200){
        if(req.currentType === "consts"){
          this.settings.dataCache[req.currentType] = JSON.parse(req.response);
          req.callback(this.settings.dataCache[req.currentType]);
        }
        else this.processDownloadedData(req);
      }else console.error(`Error BackendBridge XHR - StageChange[${req.readyState} | ${req.status}]: `, ab2str(req.response));
    }
  }
  processDownloadedData = (req) => {
    let response = req.response;
    let areas = req.currentProps[0], years = req.currentProps[1],region,age_groups,gender,daily_spending;
    if(req.currentType === "regions"){
      region = req.currentProps[2];
      daily_spending = req.currentProps[3];
    }else {
      age_groups = req.currentProps[2];
      gender = req.currentProps[3];
      daily_spending = req.currentProps[4];
    }
    if(typeof response !== "object"){
      console.error("Error in BackendBridge response must be ArrayBuffer ");
      return {"error": "Error in BackendBridge response must be ArrayBuffer "};
    }
    let binaryData = new Uint8Array(response);

    //Decryption algorithm  (XOR two passes)
    binaryData.forEach(function(byte, index) {
      binaryData.set([byte^(index.toString()).charCodeAt(0)],index);
    });
    let bin_len = binaryData.length
    binaryData.forEach(function(byte, index) {
      binaryData.set([byte^((bin_len-index).toString()).charCodeAt(0)],index);
    });

    let binary_offset = 0,a,y;
    let dataview = new DataView(binaryData.buffer);
    let tempData = {};
    if(tempData[req.currentType] === undefined)tempData[req.currentType] = {};
    if(this.settings.dataCache[req.currentType] === undefined) this.settings.dataCache[req.currentType] = {}
    for(a in areas){
      let area=areas[a];
      tempData[req.currentType][area]={};
      if(this.settings.dataCache[req.currentType][area] === undefined) this.settings.dataCache[req.currentType][area] = {}
      for(y in years){
        let year=years[y];
        tempData[req.currentType][area][year]={};
        if(this.settings.dataCache[req.currentType][area][year] === undefined) this.settings.dataCache[req.currentType][area][year] = {}
        if(req.currentType === "regions"){


          tempData[req.currentType][area][year][region]={};
          if(this.settings.dataCache[req.currentType][area][year][region] === undefined) this.settings.dataCache[req.currentType][area][year][region] = {}
          if(this.settings.dataCache[req.currentType][area][year][region][daily_spending] === undefined) this.settings.dataCache[req.currentType][area][year][region][daily_spending] = {};
          this.settings.dataCache[req.currentType][area][year][region][daily_spending].headcount = dataview.getUint32(binary_offset,true);
          binary_offset+=4;
          this.settings.dataCache[req.currentType][area][year][region][daily_spending].sdg_headcount = dataview.getInt32(binary_offset,true);
          binary_offset+=4;
          this.settings.dataCache[req.currentType][area][year][region][daily_spending].population = dataview.getFloat32(binary_offset,true);
          binary_offset+=4;
          this.settings.dataCache[req.currentType][area][year][region][daily_spending].stable_track = this.parseStableTrack(dataview.getUint8(binary_offset,true));
          binary_offset+=1;

          tempData[req.currentType][area][year][region][daily_spending]={"headcount": this.settings.dataCache[req.currentType][area][year][region][daily_spending].headcount, "population": this.settings.dataCache[req.currentType][area][year][region][daily_spending].population,sdg_headcount:this.settings.dataCache[req.currentType][area][year][region][daily_spending].sdg_headcount, stable_track: this.settings.dataCache[req.currentType][area][year][region][daily_spending].stable_track};

          if(req.include_escape_rates){
            this.settings.dataCache[req.currentType][area][year][region][daily_spending].escape_rate = dataview.getFloat32(binary_offset,true);
            binary_offset+=4;
            this.settings.dataCache[req.currentType][area][year][region][daily_spending].target_escape_rate = dataview.getFloat32(binary_offset,true);
            binary_offset+=4;
            tempData[req.currentType][area][year][region][daily_spending].escape_rate = this.settings.dataCache[req.currentType][area][year][region][daily_spending].escape_rate;
            tempData[req.currentType][area][year][region][daily_spending].target_escape_rate = this.settings.dataCache[req.currentType][area][year][region][daily_spending].target_escape_rate;

            if(area === "WORLD") {
              this.settings.dataCache[req.currentType][area][year][region][daily_spending].fell_into_poverty_rate = dataview.getFloat32(binary_offset,true);
              binary_offset+=4;
              this.settings.dataCache[req.currentType][area][year][region][daily_spending].escape_from_poverty_rate = dataview.getFloat32(binary_offset,true);
              binary_offset+=4;
              tempData[req.currentType][area][year][region][daily_spending].fell_into_poverty_rate = this.settings.dataCache[req.currentType][area][year][region][daily_spending].fell_into_poverty_rate;
              tempData[req.currentType][area][year][region][daily_spending].escape_from_poverty_rate = this.settings.dataCache[req.currentType][area][year][region][daily_spending].escape_from_poverty_rate;
            }
          }


        }else {
          for(a in age_groups){
            let age_group = age_groups[a];
            tempData[req.currentType][area][year][age_group]={};tempData[req.currentType][area][year][age_group][gender]={};
            if(this.settings.dataCache[req.currentType][area][year][age_group] === undefined) this.settings.dataCache[req.currentType][area][year][age_group] = {}
            if(this.settings.dataCache[req.currentType][area][year][age_group][gender] === undefined) this.settings.dataCache[req.currentType][area][year][age_group][gender] = {}
            if(this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending] === undefined) this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending] = {}
            this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].headcount = dataview.getUint32(binary_offset,true);
            binary_offset+=4;
            this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].sdg_headcount = dataview.getInt32(binary_offset,true);
            binary_offset+=4;
            this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].population = dataview.getFloat32(binary_offset,true);
            binary_offset+=4;
            this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].stable_track = this.parseStableTrack(dataview.getUint8(binary_offset,true));
            binary_offset+=1;
            tempData[req.currentType][area][year][age_group][gender][daily_spending]={"headcount": this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].headcount, "population":this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].population,sdg_headcount:this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].sdg_headcount, stable_track: this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].stable_track};
            if(req.include_escape_rates){
              this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].escape_rate = dataview.getFloat32(binary_offset,true);
              binary_offset+=4;
              this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].target_escape_rate = dataview.getFloat32(binary_offset,true);
              binary_offset+=4;
              tempData[req.currentType][area][year][age_group][gender][daily_spending].escape_rate = this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].escape_rate;
              tempData[req.currentType][area][year][age_group][gender][daily_spending].target_escape_rate = this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].target_escape_rate;
              if(area === "WORLD") {
                this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].fell_into_poverty_rate = dataview.getFloat32(binary_offset,true);
                binary_offset+=4;
                this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].escape_from_poverty_rate = dataview.getFloat32(binary_offset,true);
                binary_offset+=4;
                tempData[req.currentType][area][year][age_group][gender][daily_spending].fell_into_poverty_rate = this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].fell_into_poverty_rate;
                tempData[req.currentType][area][year][age_group][gender][daily_spending].escape_from_poverty_rate = this.settings.dataCache[req.currentType][area][year][age_group][gender][daily_spending].escape_from_poverty_rate;
              }
            }
          }
        }
      }
    }
    if(req.callback !== "undefined" && typeof req.callback === "function")req.callback(tempData);
  }
  parseStableTrack = (stable_track) => {
    switch (stable_track) {
      case 1:
        return "ontrack";
      case 2:
        return "offtrack";
      case 3:
        return "rising";
      default:
        return "nopoverty";
    }
  }
}
