import React, {Component} from 'react';
import Filter from '../components/Filter';
import Select from '../components/Select';
import Tooltip from '../components/Tooltip';
import styled from 'styled-components';


export default class FiltersModal extends Component {
    handleSelectChange = (event) => {
      const year = event.target.value;
      this.props.handleYearChange(year)
    }
    render() {
        return (
            <StyledFiltersModal>
                <div>
                    {this.props.groups.map((group, i) => {
                        return (
                            <div className={`filters-section ${group.hasActiveFilter ? 'has-active-filter' : ''}`} key={i}>
                            <legend className="filters-heading label">{group.heading}</legend>
                            <div className="filters-container">
                                <Tooltip hasActiveFilter={group.hasActiveFilter} context={i}></Tooltip>
                                {group.filters.map((filter, j) => {
                                return (
                                    <Filter 
                                    hasActiveFilter={group.hasActiveFilter} 
                                    className={this.props.filters[i][j] ? 'is-active' : ''} 
                                    label={filter.label} 
                                    dropdown={(filter.dropdown === undefined ? false : filter)} 
                                    key={j} onClick={() => this.props.handleFilterClick(i, j)} 
                                    handleClickDropdown={ (e) => this.props.handleClickDropdown(e)} 
                                    currentAgeRange = {this.props.currentAgeRange}
                                    toggleScroll = {this.props.toggleScroll}
                                    />
                                )
                                })}
                            </div>
                            </div>
                        )
                    })}
                    <div className="filters-section">
                        <legend className="filters-heading label">Please Choose a year</legend>
                        {
                            window.matchMedia("(min-width: 768px)").matches ? 
                            <Select {...this.props} onChange={this.props.handleYearChange} options= {this.props.yearRange} value={this.props.currentYear}/> 
                            :
                            <select className="year-select-mobile" onChange={this.handleSelectChange} value={this.props.currentYear}>{this.props.yearRange.map(function(year){return <option key={year.value} value={year.value}>{year.label}</option>})}</select>
                        }
                    </div>
                    <button className="button explore" onClick={ () => { this.props.handleModal(false, '');} }>back to the clock</button>
                    <button className="button reset" onClick={this.props.resetFilters}>reset</button>
                </div>
            </StyledFiltersModal>
        )
    }
}

const StyledFiltersModal = styled.div`
.filters {
    &-section {
      min-width: 26.5rem;
      padding-right: 1em;

      &:not(:last-child) {
        margin-right: 1em;
        border-right: 1px solid #DADADA;
      }

      &.has-active-filter {
        .filter {
          border-color: #EE4422;

          &:not(.is-active) {
            background-color: #fff;
          }

          &.is-active {
            background-color: #EE4422;
            color: #fff;
            font-weight: bold;
            text-align: left;
          }
        }
      }
    }

    &-heading {
      margin-bottom: .75em;
      color: #742301;
      font-size: 87.5%;
    }

    &-container {
      display: flex;

      .filter {
        &:not(:last-child) {
          margin-right: .75em;
        }
      }
    }
  }
  .button {
    border: 1px solid black;
    border-radius: 50vw;
    cursor: pointer;
    padding: .75em 2em;
    color: #fff;
    background-color: #000;
    width: 90%;
    display: block;
    margin: 0 auto;
    font-weight: bold;
  }
  .button.explore{
    background-color: #fff;
    font-weight: bold;
    color: #000;
    margin-bottom: .5em;
  }
  
`