import React from 'react';
import styled from 'styled-components';

function Burger(props) {
  return (
    <StyledBurger {...props} className="burger">
      {/*<span className="burger-label label"></span>*/}
      <span className="burger-icon">
        <svg width="100%" height="100%" viewBox="0 0 142 90" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" fill="currentColor">
            <g transform="matrix(1,0,0,1,-7477.78,-287.191)">
                <g transform="matrix(5.55556,0,0,5.55556,0,0)">
                    <g>
                        <g transform="matrix(1,0,0,-1,1346,52.8889)">
                            <rect x="0" y="-0.306" width="25.552" height="1.5"/>
                        </g>
                        <g transform="matrix(1,0,0,-1,1346,59.9999)">
                            <rect x="0" y="-0.306" width="25.552" height="1.5"/>
                        </g>
                        <g transform="matrix(1,0,0,-1,1346,67.5559)">
                            <rect x="0" y="-0.306" width="25.552" height="1.5"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </span>
    </StyledBurger>
  )
}

const StyledBurger = styled.button`
  display: inline-flex;
  align-items: center;

  .burger {
      margin-top: 1.5em;
      &-label {
        margin-right: 1em;
      }

      &-icon {
        width: ${25 / 16 * 1}em;
        height: 1em;
        color: #EE4422;
      }
  }

  @media screen and (max-width: 768px) {
    .burger {
      padding-top: 0;
      &-label {
        display: none;
      }

      &-icon {
        color: inherit;
      }
    }
  }
`



export default Burger;
