import React from 'react';
import styled from 'styled-components';
import ExploreIcon from '../_/img/explore.svg';
import LensIcon from '../_/img/lens.svg'

export const MobileFilters = (props) => {
  return (
    <StyledMobileFilters className="headline-explore button filters" onClick={ () => {props.handleModal(true, 'mobile_filters')} }>
        <span className="headline-explore-icon"><img className="exploreIcon" src={ExploreIcon} alt="Explore Icon" /></span>
        <span className="headline-explore-label">Filters</span>
    </StyledMobileFilters>
  )
}
export const MobileLenses = (props) => {
    return (
      <StyledMobileFilters className="headline-explore button lenses" onClick={ () => {props.handleModal(true, 'mobile_lenses')} }>
            <span className="headline-explore-icon"><img className="LensIcon" src={LensIcon} alt="Lens Icon" /></span>
            <span className="headline-explore-label">Lenses</span>
      </StyledMobileFilters>
    )
  }

const StyledMobileFilters = styled.button`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    background-color: #EE4422;
    color: white;
    border: 0;
            .headline-explore-icon {
                display: flex;
                width: 2em;
                height: 2em;
                margin-right: .5em;
                background-color: #EE4422;
                border-radius: 50vw;
                img{
                    margin: auto;
                    width: 50%;
                }
            }

            .headline-explore-label {
            font-weight: bold;
            }
    
  }
`