import React, {Component} from "react";
import {LogoIcon} from '../assets/Logo';
import {Link} from 'react-router-dom';
import Burger from '../components/Burger';
import Wrapper from '../components/Wrapper';
import Modal from '../components/Modal';
import Footer from '../components/Footer';
import {StyledMenu} from '../templates/Menu';
import styled from 'styled-components';

export default class Article extends Component {
    constructor(props){
        super(props);
        this.props.handleModal(false,'');
        this.state = {
            currentPage: this.props.page
        }
    }
    getArticleContent = () => {
        switch (this.props.page) {
            case 'about':
                return(
                    <>
                    <h1>About</h1>
                    <h6>ABOUT POVERTY CLOCK</h6>
                    <p>The World Poverty Clock provides real-time poverty estimates through 2030 for nearly every country in the world. It monitors progress against Ending Extreme Poverty, which is the UN&rsquo;s first Sustainable Development Goal (SDG). The escape rate calculates the current rate of poverty reduction in the world.</p>
                    <p>The World Poverty Clock was developed by World Data Lab, a global data enterprise. The Clock&rsquo;s underlying dataset draws upon a standardized global poverty database which provides real-time projections on income levels for every individual in the world. The core modeling methodology of the World Poverty Clock has been peer-reviewed by the academic community and published on the website of <a href="https://www.nature.com/articles/s41599-018-0083-y">Nature</a>.</p>
                    <p>Funded by the International Fund for Agricultural Development (IFAD) and the Federal Ministry for Economic Cooperation and Development of Germany, the World Poverty Clock shows the number of people living in extreme poverty worldwide, disaggregated by age, gender and rural/urban location. Every second, some escape while others fall into poverty. We count them. We also count &ldquo;excess&rdquo; poverty &ndash; the gap between the actual number of people who have escaped poverty since end-December 2015 and the hypothetical number who should have escaped in order for the world to be on-track to reach the global target of ending poverty by 2030. The &ldquo;Target Escape Rate&rdquo; is the average poverty reduction (per second) needed to achieve SDG1 by the end of 2030. The &ldquo;Current Escape Rate&rdquo; is the current speed of poverty reduction between the year before and the year after the current year.</p>
                    <p>For more information on the team behind World Data Lab, please visit <a href="http://www.worlddata.io">www.worlddata.io</a>.</p>
                    </>
                );
            case 'methodology':
                return(
                    <>
                        <h1>Methodology</h1>
                        <p></p>
                        <p></p>
                        <p><strong>Update Version: </strong> The World Poverty Clock dataset was updated as of August 2022, taking into consideration the latest estimates of the effects of the COVID-19 pandemic and the war in Ukraine on the global economy. </p>
                        <p></p>
                        <p>The methodology of the World Poverty Clock has been peer-reviewed in the academic community and published on the website of <a href="https://www.nature.com/articles/s41599-018-0083-y">Nature</a>. Overall, our approach is to estimate country-by-country expenditure distributions by fitting Lorenz curves to household survey data published in the World Bank’s Povcal database and updated each year around October. We then shift the distributions to account for short-term income forecasts that we take from the IMF’s World Economic Outlook and update twice a year and long-term income forecasts that we take from the “Shared Socioeconomic Pathways” (SSP) analysis (medium variant) developed by the International Institute for Applied Systems Analysis (IIASA) in Austria. This permits us to compute the speed of poverty reduction in each country and region. We compare this speed to a counterfactual speed needed to end poverty by 2030 (Goal 1 of the Sustainable Development Goals [SDGs]). The comparison allows for a categorization of countries into four classes: No Extreme Poverty [SDG1 already met], On-Track [to meet SDG1], Off-Track [to meet SDG1], Poverty is Rising [backsliding on SDG1].</p>
                        <p></p>
                        <p>For questions regarding our methodology, please feel free to contact <a href="mailto:data@worlddata.io">data@worlddata.io</a>.</p>
                        <p></p>
                        <p>There are two adjustments to the simple methodology that we make. First, in almost all countries there are discrepancies between the data presented in household surveys and data presented in National Income Accounts. Second, some countries do not have survey data at all.</p>
                        <p></p>
                        <p><strong>Addressing discrepancies: </strong>Discrepancies between survey means and mean per capita household final consumption can be large – for example, the survey mean only captures one-half the estimated consumption of households in the national income accounts in India. Allocating this missing spending to households can make a large difference to estimates of the number of poor people. There are two common ways of addressing this in the literature. The World Bank, and others, simply ignore national accounts data on the grounds that the missing spending is likely to be concentrated in rich households that often opt not to participate in surveys. They therefore base their estimates of the number of poor people entirely on survey data. This is not a credible solution, however, in countries with high poverty rates, as the missing expenditure must be allocated to a very few people and can result in implausible levels of spending of the “rich” in those countries. Others allocate missing spending proportionately across all households. They simply scale up each household’s spending by a constant. This avoids the problem of implausibly large spending by any group, but may underestimate poverty substantially by assuming that poor people spend far more than what survey data suggest.</p>
                        <p></p>
                        <p>Our approach is a hybrid. We construct two distributions for each country, one based on survey data and the other based on a mean derived from the national accounts. Households in each percentile are then given a weighted average of the two distributions, with the weight sliding from 100% survey-based at the bottom of the distribution to 100% national accounts at the top of the distribution. An iterative process ensures that all domestic spending is allocated to households.</p>
                        <p></p>
                        <p><strong>Countries with missing surveys: </strong>27 countries have no household surveys. In these cases we estimate a similarity measure to countries that have surveys and construct an average distribution from the six most similar countries. This approach has been laid out by Crespo et. al (2020). The level of the missing survey mean and missing household expenditures are estimated with a random forest model based on data from countries where data is available. </p>
                        <p></p>
                        <p><strong>Other disaggregations:</strong></p>
                        <p></p>
                        <p><strong>Rural/Urban poverty estimates: </strong>The World Bank’s Povcal dataset includes rural and urban poverty headcount ratios at respective nationally defined poverty lines for some countries as well as information regarding rural and urban shares of the total population. We assume that the urban/rural poverty breakdown is the same at the extreme poverty line of 1.90$ (2011 PPP) as it is at the national poverty line. We then train a model to learn the relationship between rural/urban poverty rates and a set of covariates (poverty share, rural population share, age dependency ratios, education structure). We use this relationship to make predictions about countries where we do not have data on nationally defined rural/urban poverty. </p>
                        <p></p>
                        <p>The urban population share and growth is taken from the World Bank whenever possible, otherwise from the Shared Socioeconomic Pathways (SSP) projections. </p>
                        <p></p>
                        <p><strong>Age/gender poverty estimates: </strong>The disaggregation of poverty by age and gender is based on survey data taken from the International Income Distribution Dataset (I2D2) that includes harmonized information on over 110 countries. </p>
                        <p></p>
                        <p>In each country survey, we group the population by five-year age groups of males and females. For each group we compute the mean spending per capita and the spending distribution by estimating Lorenz curves. This means that within every group we know how much each part of the population spends in relation to the rest. </p>
                        <p></p>
                        <p>The computed distributional parameters are kept constant over time, implying non-dynamic distributions. Instead, overall changes only enter the model through changes in the mean spending.</p>
                        <p></p>
                        <p>For every country that has at least two surveys which are five years or more apart, we also compute the growth rate of mean spending of each demographic group. We analyze the relative dynamics of growth rates of spending across groups and use the results of the analysis to specify consistent and realistic assumptions on future growth for both group and national spending. To merge this data with our national poverty numbers we compute the share of each demographic group in the population living in poverty. Applying these shares to our national poverty forecasts we get the number of people in poverty by age and gender. </p>
                        <p></p>
                        <p><strong>Age and gender poverty estimates for countries with missing data: </strong>The main idea of this approach is to match the survey information we have to countries with no available I2D2 data. To do so, we use four sets of variables which summarize aspects related to demography and socioeconomic characteristics: (i) the shares of five-year age groups in the total population by gender, (ii) the shares of population employed in primary, secondary and tertiary sector, (iii) the shares of population by educational attainment (primary, secondary, and tertiary), and (iv) GDP per capita. We do this for every country. </p>
                        <p></p>
                        <p>The data for education and age structure is sourced from IIASA. GDP per capita in 2011 PPP comes from the IMF’s World Economic Outlook (WEO) and employment by sector is sourced from the World Bank’s World Development Indicators (WDI) database. </p>
                        <p></p>
                        <p>Based on this set of variables we try to find the countries that are the most similar. The idea is that countries that are similar in that respect also have similar demographic structures in the poor population. We then impose their spending distributions, anchored at the national expenditure of the missing countries. This method is based on Crespo Cuaresma et al. (2019). </p>
                        <p></p>
                        <p>This method enables us to produce a complete set of forecasts for the numbers of people in poverty by age and gender for almost all countries of the world until 2030. The overall approach on finding similar countries and using information on their spending distributions for countries with little input data is in the process of being peer-reviewed and is expected to be published soon. </p>
                        <p></p>
                        <p><strong>SELECTED COUNTRIES</strong></p>
                        <p></p>
                        <p><strong>Democratic Republic of Congo</strong></p>
                        <p></p>
                        <p>The base survey for the Democratic Republic of Congo (DRC) is from 2012. It is a national survey that collects data on household consumption. No corrections are made to account for cost-of-living or other price differentials between different parts of the country.</p>
                        <p></p>
                        <p>Estimating poverty in the DRC is fraught with challenges. There is a significant number of displaced people, estimated by the UN to number 2.3 million, following years of conflict. Equally, there are many DRC nationals who are refugees in other countries. The large flows of people in and out of the country, with considerable upheaval in their circumstances, make even basic population and household income data in the DRC hard to estimate. The situation is compounded by civil unrest and a difficult political situation resulting from the postponement of elections.</p>
                        <p></p>
                        <p>The DRC economy relies heavily on mineral extraction and is therefore susceptible to swings in commodity terms of trade. It enjoyed rapid growth of 7.7% between 2010-2015 but since then growth has slowed. Because of this pattern of growth, the link between aggregate GDP and household consumption is weaker than in other countries. In our forecasts, therefore, there are two sources of uncertainty: GDP growth is hard to predict because it depends heavily on hard-to-predict commodity prices. And consumption growth may not follow GDP growth, depending on how the government spends its revenues from commodity earnings.</p>
                        <p>Taken together, the various uncertainties mean that estimates of the levels and forecasts of poverty for DRC should be treated with considerable caution.</p>
                        <p></p>
                        <p>Data is sourced from the World Bank’s PovcalNet website.</p>
                        <p></p>
                        <p><strong>Nigeria</strong></p>
                        <p></p>
                        <p>For Nigeria, the Living Standards Survey 2018-2019 is used. We believe that this survey is more accurate than the 2018-2019 general household survey. However it is still missing data. 67.4% of household spending according to the System of National Accounts (SNA) in 2018 is not accounted for. Our poverty numbers might differ from other organizations like the World Bank, because they do not allocate any missing spending to poorer households, whereas in our process we do as described above.</p>
                        <p></p>
                        <p>The Nigerian population numbers and their likely future development are contested. The current UN world population prospects estimates the total population of Nigeria in 2022 at 216.7 million people while the Vienna Institute of Demography (VID) estimates a comparable 214.5 million people. However, the medium variant scenario of the UN forecasts that Nigeria will have 263 million people in 2030 while the middle of the road scenario of the VID forecasts 258.3 million. We believe the VID forecasts to be more realistic because they take educational attainment dynamics into account which are known to have an impact on fertility rates.</p>
                        <p></p>
                        <p>The challenges in estimating poverty in Nigeria stem from the fact that Nigeria is not a homogeneous country. There are distinct differences in economic conditions between different regions. However, we believe that poverty has fallen over time along with economic growth in Southern states, while this has not been the case for Northern states. These differences cannot be factored into our national level calculations, although inequality in Nigeria has risen in recent years.</p>
                        <p></p>
                        <p>Nigeria has also been suffering from civil unrest and conflict in selected Northern areas. Such unrest will indubitably have a negative impact on poverty, but there is insufficient data to quantify this.</p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p><strong>India</strong></p>
                        <p></p>
                        <p>The base survey for India is from 2012. It is conducted by the National Sample Survey Office of the Government of India.</p>
                        <p></p>
                        <p>We do not separate between urban and rural but construct an overall national spending distribution from this survey, based on a survey methodology called MMRP (mixed modified reference period). This is in contrast to the current World Bank figures for India, which are based on the MRP (modified reference period), and are separately reported for rural and urban areas. </p>
                        <p></p>
                        <p>The MRP method asks the households to recall the consumption of less-frequently purchased items over a one-year period (clothing and bedding, footwear, institutional medicine, education, and durable goods) and purchases of perishables (vegetables and fruits) over a 30 day period. </p>
                        <p></p>
                        <p>The mixed MMRP asks households about their consumption of perishables over a 7 day period. The accuracy of the mean consumption is considerably increased with the MMRP because households tend to underestimate their consumption over longer recall periods (Surjit S Bhalla, Karan Bhasin and Arvind Virmani, 2022). The NSS plans to switch to this concept in all future surveys which would be in line with current international standards.</p>
                        <p></p>
                        <p>While there is a long tradition of surveys in India, the gap between mean per capita household consumption from survey data and household expenditure per capita from National Accounts data is among the highest in the world. Over 60 percent of consumption is missing. Much of this can be attributed to the inability of surveys to capture wealthier households, but there are also  methodological differences in the way India conducts its surveys that have a material impact. </p>
                        <p></p>
                        <p>India also does not include an imputation for owner-occupied housing unlike many other countries. Recent studies suggest that the omission of a line item on eating out, food consumed outside the household, is also a major omission.</p>
                        <p></p>
                        <p>Our poverty numbers might differ from other organizations like the World Bank, because they do not allocate any missing spending to poorer households, whereas in our process we do as described above.</p>
                        <p></p>
                        <p><strong>Indonesia</strong></p>
                        <p></p>
                        <p>The base survey for Indonesia is from 2014. Core household consumption expenditure is collected by SUSENAS, the National Socioeconomic Survey, a large nationally representative survey.</p>
                        <p></p>
                        <p>As in the case of China and India, the World Bank makes adjustments to reflect cost-of-living differences between rural and urban areas. Poverty rates are separately computed for households living in these two areas. As is the case in India, there is a large unexplained discrepancy between levels of household consumption inferred from national accounts data and the mean levels reported in survey data. This suggests that poverty estimates for Indonesia should be treated with caution.</p>
                        <p></p>
                        <p>Data is sourced from the World Bank’s PovcalNet website.</p>
                        <p></p>
                        <p><strong>Venezuela</strong></p>
                        <p></p>
                        <p>The numbers shown for Venezuela are provided on a "best estimate" basis with no guarantees of accuracy in the face of hyperinflation and lack of data.</p>
                        <p></p>
                        <p><strong>Ukraine</strong></p>
                        <p></p>
                        <p>Due to the escalation of the Russo-Ukrainian war following the Russian invasion of Ukraine in February this year, our current estimates for Ukraine’s poverty headcounts and population are under review, pending new data. The UNDP projects the war’s impact on poverty along four different scenarios of economic contraction: (i) 7% contraction (inferred from data on armed conflicts across 45 Sub-Saharan African countries between 1989 and 2017); (ii) 15% contraction (inferred from data on the 2014-2015 Ukrainian military conflict); (iii) 20% contraction (inferred from the Yugoslavia and Georgia wars); (iv) 60% contraction (inferred from extreme cases of war-hit countries). </p>
                        <p></p>
                        <p>According to those scenarios, the 2022 headcount for those in poverty (below 5.50$ a day at 2017 PPP) ranges from 1.68 million (4.2%) in scenario (i) to 11.28 million (28%) in scenario (iv).</p>
                        <p></p>
                        <p>In addition, it is also not clear yet how many refugees left Ukraine following the Russian invasion. According to UNHCR the number is currently 6.38 million. The UNDP numbers account for an outflow of at least 3 million refugees.</p>
                        <p></p>
                        <p><strong>North Korea and Syria</strong></p>
                        <p></p>
                        <p>To be updated soon.</p>
                        <p></p>
                        <p></p>
                    </>
                );   
            default:
                break;
        }
    }
    componentDidUpdate(){
    
        if(this.state.currentPage !== this.props.page){
            this.props.handleModal(false,'');
            this.setState({currentPage: this.props.page});
        }
    }
    componentDidMount(){
        document.body.scrollTop = 0;
    }
    render(){
        return(
            <StyledArticle>
                <StyledMenu>
                <div className="menu-header">
                    <Wrapper maxWidth="110rem">
                        <div className="menu-header-logo">
                            <div className="menu-header-logo-icon">{LogoIcon}</div>
                            <div className="menu-header-logo-title"><Link to="/headline">&lt; Back to the clock</Link></div>
                        </div>
                        <Burger onClick={ () => {this.props.handleModal(true,'topmenu')}}/>
                    </Wrapper>
                    
                </div>
                </StyledMenu>
                <Modal
                    {...this.props}
                />
                <Wrapper maxWidth="110rem" >
                <div className="article-content">
                    {this.getArticleContent()}
                </div>
                </Wrapper>
                <Footer/>
            </StyledArticle>
        );
            
        
    }
}
const StyledArticle = styled.div`
    user-select: auto;    

    .menu-header{
        padding: 2em 4em;
        .wrapper{
            display: flex;
        }
        
        &-logo{
            &-icon{
                margin-right: 2em;
            }
            &-title{
                font-size: .85em;
            }
        }
    }
    .burger { margin-left: auto;}
    h1{
        font-family: 'Berlingske';
        font-size: 2em;
        margin-bottom: 1.5em;
    }
    h6{
        text-transform: uppercase;
        font-weight: 100;
        letter-spacing: 2px;
        font-size: .9em;
        margin-top: .5em
    }
    p{
        margin: 1.25em 0 1.25em 0;
    }
    .article-content{
        a{color: #ee4322}
    }
    .footer-top{
        padding-top: 5em;
    }
    .footer-copyrights{
        p{
            margin: 0;
        }
        
    }
    @media screen and (max-width: 768px){
        .menu-header{
            padding: 0;
            padding-top: .75em;
            padding-bottom: .5em;
            margin-bottom: 1em;
            &-logo{
            &-title{
                padding-top: .35em;
                margin-left: 1em;
                
            }
            &-icon{
                margin-right: 0;
            }
            }
        }
        
    }
   
`
