import React from 'react';
import Tick from '../_/img/tick.svg';
import Dropdown from '../components/Dropdown';
import styled from 'styled-components';


export default function Filter(props) {
  return (
      <StyledFilter className={`filter ${props.className}`} onClick={() => props.onClick()}>
        {props.label}
        <img src={Tick} className="filter-tick" alt="Tick icon"/>
        {(props.dropdown.isActive === true ?
          <Dropdown
          parent={props.dropdown}
          handleClickDropdown={(e) => props.handleClickDropdown(e)}
          currentAgeRange = {props.currentAgeRange}
          toggleScroll = {props.toggleScroll}
          />
          :
          "")}

      </StyledFilter>

  )
}

const StyledFilter = styled.div`
  position:relative;
  display: flex;
  align-items: baseline;
  min-width: 7.5em;
  padding: .25em 1em;
  background-color: #FCC8BF;
  border: 1px solid #FCC8BF;
  cursor: pointer;
  text-align: center;
  text-transform: lowercase;

  .filter-tick {
    display: none;
    margin-left: auto;
    width: .6em;
    height: .5em;
  }

  &.is-active {
    .filter-tick {
      display: inline-block;
    }
  }

  
`
