import React, {Component} from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';

const yearRange = {
  start: 2016,
  end: 2031
}

const yearsList = [];
let offset = {};

for (var i = yearRange.start; i <= yearRange.end; i++) {
  yearsList.push(i);
}

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentYear: new Date().getFullYear(),
      isButtonClicked: false
    }

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    offset = this.container.getBoundingClientRect();
    window.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleMouseDown() {
    this.setState({
      isButtonClicked: true
    })
  }

  handleMouseUp() {
    this.setState({
      isButtonClicked: false
    })
  }

  handleMouseMove(e) {
    if (!this.state.isButtonClicked) {
      return;
    }

    let left = e.clientX - offset.left;
    left = left < 0 ? 0 : left;
    left = parseFloat(left > offset.width ? offset.width : left);

    this.indicator.style.left = `${left}px`;

    let year = yearRange.start + parseInt(Math.floor((yearRange.end - yearRange.start) * left / offset.width));

    if (year !== this.state.currentYear) {
      this.setState({
        currentYear: year
      })
    }
    this.props.handleYearChange(year);
  }

  handleClick(e) {
    if (!this.props.isNonInteractive) {
      e.preventDefault()
      e.stopPropagation();
      return false;
    }
  }

  getSliderContent(){
    const styles = {
      left: `${ (this.props.currentYear - yearRange.start) / (yearRange.end - yearRange.start) * 100}%`,
    };
    return(
      <div className="slider-container" onMouseMove={this.handleMouseMove} ref={ container => this.container = container }>
            <ul className="slider-scale">
              {
                yearsList.map(year => {
                  return (
                    <li className="slider-scale-year" key={year}>
                      <span className="slider-scale-year-label">{year}</span>
                    </li>
                  )
                })
              }
            </ul>

            <div className="slider-indicator" style={styles} ref={ indicator => this.indicator = indicator }>
              { this.state.currentYear === 2031 ?
                <div className="slider-indicator-explanation" onClick={ () => {this.props.handleModal(true, 'end')}}>what does this mean?</div>
                : ''
              }
              <span className="slider-indicator-label">{this.state.currentYear !== 2031 ? this.state.currentYear : 'END' }</span>
              {
                this.props.isNonInteractive
                ? <span className="slider-indicator-button"></span>
                : <button className="slider-indicator-button" onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp}></button>
              }
            </div>
          </div>
    )
    
  }

  static getDerivedStateFromProps(props, state) {
    if (props.currentYear !== state.currentYear) {
      return {
        currentYear: props.currentYear
      };
    }

    return null;
  }

  render() {
    
    return (
      <StyledSlider className="slider" currentYear={this.state.currentYear}>
        {
          this.props.isNonInteractive ? 
          <Link className={`slider-link ${!this.props.isNonInteractive ? 'is-not-link' : ''}`} to="/headline" onClick={this.handleClick}>
            {this.getSliderContent()}
          </Link> 
          : 
          <>
          {this.getSliderContent()}
          </>
        }
        
      </StyledSlider>
    )
  }
}

const StyledSlider = styled.div`
  width: 100%;
  max-width: 64em;
  padding: 1.25em 4em .75em;
  position: fixed;
  bottom: 0;
  margin-bottom: calc(1em + .25vh);
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  .slider {
    &-link {
      &.is-not-link {
        cursor: default;


      }
    }

    &-container {
      padding-top: .75em;
      padding-bottom: .75em;
      position: relative;
    }

    &-scale {
      display: flex;
      color: #CDCDCD;
      list-style: none;

      &-year {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        height: 1em;
        position: relative;
        border-left: 1px solid;

        &:before {
          content: '';
          display: block;
          height: 1px;
          width: 100%;
          background: currentColor;
        }

        &-label {
          min-width: auto;
          width: 100%;
          margin-top: .2em;
          position: absolute;
          top: 100%;
          left: -50%;
          text-align: center;
        }

        &:last-child {
          flex: 0;

          .slider-scale-year-label {
            display: inline-flex;
            justify-content: center;
          }
        }

        &:nth-child(even) {
          .slider-scale-year-label {
            display: none;
          }
        }
      }
    }

    &-indicator {
      margin: -1.25em;
      position: absolute;
      top: 50%;

      &-explanation {
        position: absolute;
        bottom: 100%;
        margin-bottom: .75em;
        right: 3.5em;
        border-bottom: 1px solid #EE4422;
        color: #EE4422;
        letter-spacing: 2px;
        min-width: 14em;
        padding-bottom: 4px;
        bottom: 100%;
        cursor: pointer;
      }
      &-label {
        margin-bottom: .5em;
        margin-left: -1em;
        padding: .2em 1em;
        position: absolute;
        left: 50%;
        bottom: 100%;
        background-color: #EE4422;
        color: #fff;

        &:after {
          content: '';
          display: block;
          width: 1em;
          height: 1em;
          position: absolute;
          top: 100%;
          left: .5em;
          border: .5em solid transparent;
          border-top: .5em solid #EE4422;
        }
      }

      &-button {
        display: block;
        height: 2.5em;
        width: 2.5em;
        background: white;
        border-radius: 50vw;
        box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
        cursor: pointer;
        user-select: none;

        &:before,
        &:after {
          content: '';
          display: block;
          width: 1em;
          height: 1em;
          margin: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          border: .5em solid transparent;
          transform: scaleY(.7);
        }

        &:before {
          margin-right: .2em;
          right: 100%;
          border-right: .5em solid #EE4422;
        }

        &:after {
          margin-left: .2em;
          left: 100%;
          border-left: .5em solid #EE4422;
        }
      }
    }
  }
`


export default Slider;
