import React, {Component} from 'react';
import Select from '../components/Select';
import Filter from '../components/Filter';
import styled from 'styled-components';
import Tooltip from '../components/Tooltip';

export default class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [
        {
          heading: 'Geographical filters',
          filters: [
            { label: 'Rural' },
            { label: 'Urban' }
          ],
          hasActiveFilter: false
        },
        {
          heading: 'Demographic filters',
          filters: [
            { label: 'Female', independent:true },
            { label: 'Male', independent:true },
            { label: 'all ages', isActive: false, independent:true,
              dropdown: ['loading'] // First index[0] always is interpreted as default(all)
            }
          ],
          hasActiveFilter: false
        }
      ],
      filters: [
        [false, false],
        [false, false, false]
      ],
      currentAgeRange: []
    }
  }

  handleClickDropdown(element) {
    this.props.handleClickDropdown(element);
  }


  handleFilterClick(i, j) {
    this.props.handleFilterClick(i,j);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.groups === undefined || props.filters === undefined || props.currentAgeRange === undefined) { return null; }
    if (props.groups !== state.groups || props.filters !== state.filters || props.currentAgeRange !== state.currentAgeRange) {
      return {
        groups: props.groups,
        filters: props.filters,
        currentAgeRange: props.currentAgeRange,
      };
    }

    if (props.clockData === undefined) {
      return null;
    }
    let groups = {...state.groups}
    if ( groups[1].filters[2].dropdown !== props.clockData.ageRanges ) {
      groups[1].filters[2].dropdown = props.clockData.ageRanges;
      return groups;
    }
    return null;
  }
  onChange = (event) => {
    this.props.clockData.selectorList.forEach((country) => {
      if(country[0] === event){
        this.props.handleCountryChange(event,country[1]);
      }
    })
  };
  render() {
    var allCountries = this.props.clockData.selectorList;  
    var filteredCountries = this.props.filteredCountries;
    var filteredCountriesList = [];
    if(allCountries && filteredCountries.length > 0){
      filteredCountriesList = allCountries.filter(function(item){
        return !filteredCountries.includes(item[0])
      });
    }
    
    return (
      <StyledFilters className={`filters ${this.props.className}`} {...this.props}>
        <div className="filters-section">
          <legend className="filters-heading label">Search</legend>
          <Select
            {...this.props}
            options = { this.props.clockData && this.props.clockData.selectorList ? filteredCountriesList.map( x => { return { value: x[0], label: x[1]} }) : [ {value: 'loading', label: 'loading'} ] }
            onChange = {this.onChange}
            value={
              this.props.currentCountry === 'WORLD' && !this.props.continentsDisplay ? 'All Countries'
              : this.props.continentsDisplay && !this.props.currentCountryLabel ? 'All Continents'
              : this.props.currentCountryLabel}
            />
          <div className="buttons-container">
            {this.props.mapDisplay && (this.props.countryHasSubnational || this.props.subnationalDisplay) && !this.props.continentsDisplay ? <div className={this.props.subnationalDisplay ? 'subnational button active' : 'subnational button'} onClick={() => this.props.handleSubnational(true)}>Subnational</div>
              : '' }
            <div className={this.props.continentsDisplay ? 'continents button active' : 'continents button'} onClick=
            {this.props.handleContinentsToggle}>Continents</div>
            
          </div>

        </div>
        {this.state.groups.map((group, i) => {
          return (
            <div className={`filters-section ${group.hasActiveFilter ? 'has-active-filter' : ''}`} key={i}>
              <legend className="filters-heading label">{group.heading}</legend>
              <div className="filters-container">
                <Tooltip hasActiveFilter={group.hasActiveFilter} context={i}></Tooltip>
                {group.filters.map((filter, j) => {
                  return (
                    <Filter
                      hasActiveFilter={group.hasActiveFilter}
                      className={this.state.filters[i][j] ? 'is-active' : ''}
                      label={filter.label}
                      dropdown={(filter.dropdown === undefined ? false : filter)}
                      key={j} onClick={() => this.handleFilterClick(i, j)}
                      handleClickDropdown={ (e) => this.handleClickDropdown(e)}
                      currentAgeRange = {this.state.currentAgeRange}
                      toggleScroll= {this.props.toggleScroll}
                      />
                  )
                })}
              </div>
            </div>
          )
        })}
        {
          this.props.currentAgeRange.length > 1 ? 
          <div className="selected-age-groups">
            {
              this.props.currentAgeRange.map( (item, i)=> {
                let followingItem = ', '
                let ageRange = item.substring(1).slice(0,-1).replace(',','-').replace('-INF','+');
                const firstAge = ageRange.split('-')[0];
                const secondAge = parseInt(ageRange.split('-')[1])-1;
                if(!isNaN(secondAge)){
                  ageRange = firstAge + '-' + String(secondAge).padStart(2, '0');
                }
                if(this.props.currentAgeRange.length === i+1){
                  followingItem = '';
                }
                return (ageRange+followingItem);
              })
            }
          </div>
          : ''
        }
        
        <button className="button reset active" onClick={this.props.resetFilters}>reset</button>
      </StyledFilters>

    )
  }
}


const StyledFilters = styled.form`
  display: flex;
  position: relative;
  .buttons-container{
    display: flex;
    margin-top: .5em;
    position: absolute;
  }
  .button {
    border: 1px solid black;
    border-radius: 50vw;
    padding: .3em;
    cursor: pointer;
  }
  .button.active{
    background-color: black;
    font-weight: bold;
    color: white;
  }
  .button.subnational{
    margin-right: .5em;
  }
  .button.reset{
    position: absolute;
    right: 0;
    margin-top: 1.6em;
    margin-right: -6.75em;
    padding: .25em 2em;
    filter: ${props => props.subnationalDisplay ? 'grayscale(100%)' : 'none'};
    pointer-events: ${props => props.subnationalDisplay ? 'none' : 'auto'};
    opacity: ${props=>props.subnationalDisplay ? 0.5 : 1} 
  }
  .selected-age-groups{
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: right;
    margin-right: 2em;
    margin-bottom: -1.75em;
    background-color: #d7d7d7;
    padding: .3em;
  }
  .filters {
    &-section {
      min-width: 21.5rem;
      padding-right: 1em;
      &:not(:first-of-type){
      filter: ${props => props.subnationalDisplay ? 'grayscale(100%)' : 'none'};
      pointer-events: ${props => props.subnationalDisplay ? 'none' : 'auto'};
      opacity: ${props=>props.subnationalDisplay ? 0.5 : 1} 
      }
      &:not(:last-of-type) {
        margin-right: 1em;
        border-right: 1px solid #DADADA;
      }
      &:nth-of-type(2){
        /*filter: grayscale(100%);
        pointer-events: none;*/
      }
      &.has-active-filter {
        .filter {
          border-color: #EE4422;

          &:not(.is-active) {
            background-color: #fff;
          }

          &.is-active {
            background-color: #EE4422;
            color: #fff;
            font-weight: bold;
            text-align: left;
          }
        }
      }
    }

    &-heading {
      margin-bottom: .75em;
      color: #742301;
      font-size: 87.5%;
    }

    &-container {
      display: flex;

      .filter {
        &:not(:last-child) {
          margin-right: .75em;
        }
      }
    }

  }
  @media screen and (max-width: 768px) {
    .filters {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
`
