import React from 'react';
import styled from 'styled-components';

function Wrapper(props) {
  return (
    <StyledWrapper {...props} className="wrapper">
      {props.children}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div.attrs(props => ({
  maxWidth: props.maxWidth || '130rem',
  padding: props.padding || '3rem'
}))`
  width: 100%;
  max-width: calc(${props => props.maxWidth} + 2 * ${props => props.padding});
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${props => props.padding};
`

export default Wrapper;
