import React, {Component} from 'react';
import styled from 'styled-components';
import LeftArrow from '../_/img/left-arrow.svg';

export default class ExplainedModal extends Component  {
  render() {
    return(
      <StyledExplainedModal>
        <div className="main-explanation white">
          <button className="round-close-button" onClick={ () => { this.props.handleModal(false, '');} }><img className="left-arrow" src={LeftArrow} alt="Left Arrow" /> Back</button>
          <h1 className="serif">{this.props.title}</h1>
          <p>{this.props.description}</p>
        </div>

      </StyledExplainedModal>
    )
  }
}
const StyledExplainedModal = styled.div`
.main-explanation{
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 50vw;
  width: 25vw;
}
h1{
  font-family: Berlingske;
  font-size:2em;
  margin-bottom: 1em;
  width: 70%;
}
p{
  font-size: .9em;
}
.round-close-button{
  clear: both;
  letter-spacing: 2px;
  cursor: pointer;
  margin-bottom: 1.2em;
  display: block;
  color: white;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  margin-top: -12vh;
  margin-left: -8vw;
  .left-arrow{
    width: 2em;
    border: 1px solid #fff;
    padding: .5em;
    border-radius: 20px;
    vertical-align: middle;
  }
}
@media (max-width: 768px) {
  .main-explanation{
    margin-left: 0;
    width: 100%;
    top: 0;
    margin-top: 20vh;
    transform: none;
    padding: 1.5em;
  }
  .round-close-button{
    display: none;
  }
}
`
