import React, {Component} from 'react';
import Wrapper from '../components/Wrapper';
import Clock from '../components/Clock';
import CopyHeader from '../components/CopyHeader';
import styled from 'styled-components';

const LogoIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 41.8 31.3">
  <g>
    <path className="fillSecond" fill="#E8482B" d="M34.2,29.8l7.5-18c0.5-1.2-0.8-2.4-1.9-1.8c-2.1,1-4.8,1.9-7.7,1.9c-3,0-5.7-0.9-7.8-1.9   c-1.2-0.6-2.4,0.6-1.9,1.8l7.5,18C30.6,31.8,33.4,31.8,34.2,29.8z"></path>
    <path className="fillFirst" fill="#010202" d="M7.6,11.3l-7.5,18c-0.5,1.2,0.8,2.4,1.9,1.8c2.1-1,4.8-1.9,7.7-1.9c3,0,5.7,0.9,7.8,1.9   c1.2,0.6,2.4-0.6,1.9-1.8l-7.5-18C11.2,9.3,8.4,9.3,7.6,11.3z"></path>
    <path className="fillSecond" fill="#E8482B" d="M35.7,3.7C35.7,1.6,34,0,32,0c-2.1,0-3.7,1.7-3.7,3.7c0,2.1,1.7,3.7,3.7,3.7C34.1,7.4,35.7,5.8,35.7,3.7z"></path>
    <path className="fillFirst" fill="#010202" d="M13.6,3.7c0-2.1-1.7-3.7-3.7-3.7C7.8,0,6.2,1.7,6.2,3.7c0,2.1,1.7,3.7,3.7,3.7S13.6,5.8,13.6,3.7z"></path>
    
  </g>
  </svg>
)

class Loading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      chartColors: ["#EE4422", 'none']
    }
  }

  render() {
    return (
      
      <StyledLoading className="loading">
        
        
        <Wrapper>
        <div className={`header-logo`}>
          <a className="header-logo-img" href=".">
                {LogoIcon}
              </a>
        </div>
        <h1 className="header-logo-type label">World Poverty Clock<span><small>by</small>World Data Lab</span></h1>
        </Wrapper>
        
        
        
        <div className="loading-bg">
          <Clock
            {...this.state}
            clockData={this.props.clockData}
            hasLogo={true}
          />
        </div>
        <Wrapper maxWidth="100rem">
          <CopyHeader intro="August 2022" url="https://us02web.zoom.us/webinar/register/4915917910922/WN_gUmlZwvxRhWiZAtuh3vwXA" url_data="" title="We have updated our data to reflect the global impact of the war in Ukraine" />
        </Wrapper>
      </StyledLoading>
    );
  }
}

const StyledLoading = styled.div`
.header {
    &-logo {
      flex: none;
      display: flex;
      align-items: center;
      transition: color .3s;
      position: relative;
      animation: appear 0.5s 1.5s both;
      
      &.is-light {
        color: #CDCDCD;
      }

      &-img {
        flex: none;
        width: 7rem;
        margin-right: 1em;
        &-text{
          width: 100%;
          
          text-align: left;
          font-weight: 500;
          margin-top: .75em;
          position: absolute;
          font-size: .85em;
          letter-spacing: 1px;
        }
        svg {
          height: 3rem;
        }
      }

      &-type {
        animation: appear 0.5s 1.5s both;
        flex: none;
        margin: 1.4em;
        max-width: 90%;
        span{
          font-weight: 500;

        }
        small{
          text-transform: lowercase;
          margin: 0 2em;
        }
      }
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: auto;
  position: relative;
  background: #fff;

  .loading {
    &-bg {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .wrapper {
    position: relative;
    z-index: 10;
    &:first-child{
      display: flex;
      top: 0;
      position: absolute;
      padding-top: 4vh;
    }
  }
  .wrapper-top{
    display: flex;
      top: 0;
      position: absolute;
      padding-top: 4vh;
  }

  .clock {
    width: 75vmin;
    height: 75vmin;
    opacity: .15;
    animation: appear-scale-down 2s 1.5s both;
/*
    .clock-needle {

    } */
  }

  /* .chart {
    stroke: #EE4422;
  } */

  .copy-header {
    &-intro,
    &-picture {
      animation: appear 1s 2.5s both;
    }

    &-title {
      animation: appear 1s 2s both, up .5s 2s both;
    }
  }

  @media screen and (max-width: 768px) {
    .wrapper{
      &:first-child{
        padding-top: 0;
      }
    }
    .header {
      &-logo {
        &-img {
          width: 3em;
        }
        &-type {
          margin-left: 0.25em;
          span{
            display: block
          }
          small{margin-left: 0}
        }
      }
    }
    .loading {
      &-bg {
        margin-bottom: 3em;
        padding: 0;
      }
    }

    .copy-header {
      &-container {
        display: block;
      }

      &-picture {
        display: none;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .header-logo-type{
      font-size: 0.9em;
    }
  }
`


export default Loading;
