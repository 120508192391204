import React, {Component}  from 'react';
import Header from '../components/Header';
import Wrapper from '../components/Wrapper';
import Clock from '../components/Clock';
import ClockLegend from '../components/ClockLegend';
import Counter from '../components/Counter';
import Bookmark from '../components/Bookmark';
import Share from '../components/Share';
import Slider from '../components/Slider';
import Modal from '../components/Modal';
import ModalCountryCard from '../components/ModalCountryCard';
import {MobileFilters, MobileLenses} from '../components/MobileTriggers.js';
import Loading from '../components/Loading';
import Map from '../components/Map';
import Select from '../components/Select';
import People from '../components/People';
import Footer from '../components/Footer';
import ScrollPage from '../components/ScrollPage';
import ScrollSection from '../components/ScrollSection';

//import { ScrollPage, Section } from 'react-scrollpage-extnded';
//import { matchPath} from 'react-router';
import { withRouter } from "react-router-dom";


import IconCurrentEscape from '../_/img/icon-current-escape.svg';
import IconPovertyEscape from '../_/img/icon-poverty-escape.svg';
import IconTargetEscape from '../_/img/icon-target-escape.svg';
import IconPovertyFall from '../_/img/icon-poverty-fall.svg';
import styled from 'styled-components';


class Headline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      clockData: props.clockData,
    }
  }



  labelAfterFiltering () {
    if (this.props.filters === undefined) { return 'People in the world living in extreme poverty'; }
    let place = '';
    this.props.currentCountry === 'WORLD' ?  place = '' : place = 'ON ' + this.props.currentCountry;

    if (this.props.filters[0][0]) { return 'PEOPLE IN RURAL AREAS ' + place + ' LIVING IN EXTREME POVERTY'; }
    if (this.props.filters[0][1]) { return 'PEOPLE IN URBAN AREAS LIVING IN EXTREME POVERTY'; }

    if (this.props.filters[1][0] && !this.props.filters[1][1] && !this.props.filters[1][2]) { return 'NUMBER OF FEMALES ' + place + ' LIVING IN EXTREME POVERTY'; }
    if (this.props.filters[1][1] && !this.props.filters[1][0] && !this.props.filters[1][2]) { return 'NUMBER OF MALES ' + place + ' LIVING IN EXTREME POVERTY'; }

    if (this.props.filters[1][0] && !this.props.filters[1][1] && this.props.filters[1][2]) { return 'NUMBER OF FEMALES IN AGE RANGE ' + place + ' LIVING IN EXTREME POVERTY'; }
    if (this.props.filters[1][1] && !this.props.filters[1][0] && this.props.filters[1][2]) { return 'NUMBER OF MALES IN AGE RANGE ' + place + ' LIVING IN EXTREME POVERTY'; }

    if (!this.props.filters[1][1] && !this.props.filters[1][0] && this.props.filters[1][2]) { return 'NUMBER OF PEOPLE IN AGE RANGE ' + place + ' LIVING IN EXTREME POVERTY'; }

    return 'People ' + (place === '' ? 'in the world' : place) + ' living in extreme poverty';
  }


  componentDidMount() {
    if(this.props.mapDisplay){
      window.turnTo(2);
    }
  }

  moveScroll = () => {
    if(!this.props.mapDisplay){
      window.turnTo(2);
      //this.props.toggleMap();
      //this.props.history.push('/map');
    }else{
      window.turnTo(1);
      this.props.history.push('/headline');
    }


  }
  componentDidUpdate(){
    /*if(this.props.mapDisplay === true && this.props.history.location.pathname !== '/map'){
      this.props.history.push('/map');
    }*/
  }
  pageChange = (pageNum) => {
    if(pageNum === 1){
      if(this.props.continentsDisplay){
        this.props.handleContinentsToggle();
      }else if(this.props.subnationalDisplay){
        this.props.handleSubnational(true);
        setTimeout(() => {
          this.props.handleCountryChange('WORLD','All Countries');
        }, 250);
      }else{
        if(this.props.currentCountry !== 'WORLD'){
          this.props.handleCountryChange('WORLD','All Countries');
        }
      }
      
      this.props.history.push('/headline');
      this.props.handleCountryCard(false);
      this.props.toggleMap();
      if(this.state.isFooter){
        this.props.toggleFooter();
      }
    }else if(pageNum === 2){
      if(!this.props.mapDisplay){
        this.props.history.push('/map');
        this.props.toggleMap();
      }
      if(this.props.isFooter){
        this.props.toggleFooter();
      }
    }else if(pageNum === 3){
      this.props.toggleFooter();
    }
  }
  onChange = (event) => {
    this.props.clockData.selectorList.forEach((country) => {
      if(country[0] === event){
        this.props.handleCountryChange(event,country[1]);
      }
    })
  };
  handleSelectChange = (event) => {
    const year = event.target.value;
    this.props.handleYearChange(year)
  }

  render() {
    const options = {
      curPage: 1,           // inital page number, most 1
      totalPage: 3,         // totoal page number
      onPageChange: this.pageChange,         // callback function when page changed with curPage specifed
      delay: 1200           // delay between two scoll animation
    }
    return (
      <StyledHeadline {...this.props} className="headline">
        <Header
          isHeadline={true}
          {...this.props}
        />
        <Modal
          {...this.props}
          />
        <ModalCountryCard
          {...this.props}
          />

        <div className="headline-bookmarks">
          <Bookmark bg="#EE4422" label={this.props.mapDisplay ? "Ticker" : "Map"} onClick={ () => { this.moveScroll()} }/>
          <Bookmark bg="" label={'Request\n data'} onClick={ () => {this.props.handleModal(true, 'infocapture_requestdatahome')}} />
        </div>
        <ScrollPage
          {...options}
          {...this.props}
        >
            <ScrollSection>
                {
                
                //!this.props.mapDisplay ?
                <div className={!window.matchMedia("(min-width: 768px)").matches && this.props.currentCountry !== 'WORLD' ? 'headline-content hide' : 'headline-content'}>
                  <Wrapper maxWidth="110rem" >
                      <div className="headline-chart">
                        <div className="headline-clock">
                          <Clock
                            {...this.props}
                            hasLegend={true}
                            startAngle={.4}
                            noTransition={true}
                            filtered={true}
                            onClick={ () => {this.props.handleFilterSwap()}}
                          />
                          <div className="headline-counter">
                            <div className="headline-counter-element">
                            <Counter clockData={this.props.clockData} filters={this.props.filters}/>
                            <div className="headline-counter-legend label">
                              <div className="headline-counter-legend-subtitle">{this.labelAfterFiltering()}</div>
                              <div className="people-animation">
                                {
                                  window.matchMedia("(min-width: 768px)").matches ? 
                                  <People {...this.props} ></People> :
                                  ''
                                }
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <MobileFilters {...this.props} />
                        <div className="headline-year">
                          <span className="headline-year-label label">Choose a year</span>
                          {
                            window.matchMedia("(min-width: 768px)").matches ? 
                            <Select {...this.props} onChange={this.props.handleYearChange} options= {this.props.yearRange} value={this.props.currentYear}/> 
                            :
                            <select className="year-select-mobile" onChange={this.handleSelectChange} value={this.props.currentYear}>{this.props.yearRange.map(function(year){return <option key={year.value} value={year.value}>{year.label}</option>})}</select>
                          }
                          
                        </div>

                        <div className="headline-legend">
                          <ul className="headline-legend-container">
                            <li className="headline-legend-item headline-legend-item-description" onClick={ () => {this.props.handleModal(true, 'target_escape_rate')}} >
                              <div className="headline-legend-icon">
                                <img src={IconTargetEscape} alt="Icon"/>
                              </div>
                              <div className="headline-legend-description">
                                <div className="headline-legend-heading label" >Target escape rate</div>
                                <div className="headline-legend-score"> { this.props.clockData && this.props.clockData.targetEscapeRate && this.props.currentYear !== 2031 ?
                                  parseFloat( this.props.clockData.targetEscapeRate.toFixed(1) )
                                  : ' — '} people/sec</div>
                              </div>
                            </li>
                            <li className="headline-legend-item">
                              <div className="headline-legend-icon">
                                <img src={IconPovertyEscape} alt="Icon"/>
                              </div>
                              <div className="headline-legend-description">
                                <div className="headline-legend-heading label">Escaped poverty today</div>
                                <div className="headline-legend-score">{ this.props.clockData ? Math.round(this.props.clockData.escapedPovertyToday).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</div>
                              </div>
                            </li>
                            <li className="headline-legend-item headline-legend-item-description" onClick={ () => {this.props.handleModal(true, 'current_escape_rate')}}>
                              <div className="headline-legend-icon">
                                <img src={IconCurrentEscape} alt="Icon"/>
                              </div>
                              <div className="headline-legend-description">
                                <div className="headline-legend-heading label">Current escape rate</div>
                                <div className="headline-legend-score">{
                                  this.props.clockData ?
                                   (this.props.clockData.currentEscapeRate - this.props.clockData.fallRate) < 0.1 ?
                                    parseFloat(((this.props.clockData.currentEscapeRate - this.props.clockData.fallRate)*60).toFixed(1)) :
                                    parseFloat(this.props.clockData.currentEscapeRate - this.props.clockData.fallRate).toFixed(1) : ''
                                    }
                                   {
                                     (this.props.clockData.currentEscapeRate - this.props.clockData.fallRate) < 0.1 ? ' people/min' : ' people/sec'
                                   }
                                   </div>
                              </div>
                            </li>
                            <li className="headline-legend-item">
                              <div className="headline-legend-icon">
                                <img src={IconPovertyFall} alt="Icon"/>
                              </div>
                              <div className="headline-legend-description">
                                <div className="headline-legend-heading label">Fell into poverty today</div>
                                <div className="headline-legend-score">{ this.props.clockData ? Math.round(this.props.clockData.fellIntoPovertyToday).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <ClockLegend {...this.props}/>
                      </div>

                  </Wrapper>
                </div>
                //: '' 
                }
            </ScrollSection>
            <ScrollSection>
                {
                 // this.props.mapDisplay ?
                  <div className="map-content">
                  <Wrapper maxWidth="110rem" >
                    {
                      !window.matchMedia("(min-width: 768px)").matches && this.props.currentCountry !== 'WORLD' ?
                      <div className="return-ticker-mobile">
                        <button className="button" onClick={() => this.props.handleCountryChange('WORLD','All Countries')}>&uarr; Return to world clock view</button>
                      </div> 
                      : ''
                    }
                    
                    <div className="map-display">
                        {!window.matchMedia("(min-width: 768px)").matches && this.props.subnationalDisplay ? <div className='button return' onClick={() => this.props.handleSubnational(true)}> &lt; return to the world map</div>
              : '' }
                          {
                            this.props.isMapReady ? 
                            <Map {...this.props}/>
                            : <Loading width="100" border="5" color="#EE4322"></Loading>
                          }
                          <div className="map-explore">
                            <MobileFilters {...this.props} />
                            <MobileLenses {...this.props} />
                          </div>
                         
                          <div className="map-year">
                            <span className="map-year-label label">Choose a year</span>
                            {
                            window.matchMedia("(min-width: 768px)").matches ? 
                            <Select {...this.props} onChange={this.props.handleYearChange} options={this.props.yearRange} value={this.props.currentYear}/>
                            :
                            <select className="year-select-mobile" onChange={this.handleSelectChange} value={this.props.currentYear}>{this.props.yearRange.map(function(year){return <option key={year.value} value={year.value}>{year.label}</option>})}</select>
                            }
                          </div>
                          <div className="map-country">
                            <Select
                              {...this.props}
                              onChange={this.onChange}
                              options= { this.props.clockData && this.props.clockData.countries ? this.props.clockData.countries.map( x => { return { value: x[0], label: x[1]} }) : [ {value: 'loading', label: 'loading'} ] }
                              value={this.props.currentCountryLabel}/>
                          </div>
                        </div>
                  </Wrapper>
                </div>
                //: ''
                }
                
            </ScrollSection>
            <ScrollSection>
                <Footer/>
            </ScrollSection>
        </ScrollPage>
        <Slider currentYear={this.props.currentYear} handleYearChange={this.props.handleYearChange} {...this.props}/>
        <Share/>

      </StyledHeadline>
    )
  }
}

const StyledHeadline = styled.div`
  .year-select-mobile{
    color: #fff;
    font-weight: bold;
    font-size: 1.6rem;
    font-family: "Work Sans", sans-serif;
    width: 100%;
    background: transparent url("data:image/svg+xml;utf8,<svg viewBox='0 0 20 20' width='20' height='20' xmlns='http://www.w3.org/2000/svg'><g><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z' fill='white'/></g></svg>");
    border: none;
    background-position: right;
    background-repeat: no-repeat;
    height: 2em;
    appearance: none;
  }
  position: relative;
  .slider{
    display: ${props => props.children[0].props.isFooter ? "none" : "block"}
    };
  }
  
  .people-animation{
    position: absolute;
    top: -5.25em;
    right: 0;
    margin-right: 2.5em;
  }
  


  .headline {
    &-bookmarks {
      display: ${props => props.children[0].props.isFooter ? "none" : "flex"};
      flex-direction:column;
      align-items: flex-end;
      position: fixed;
      top: 24rem;
      right: 0;
      z-index: 10;

      .bookmark {
        &:not(:last-child) {
          margin-bottom: .25em;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100vh;
      padding-bottom: 3.25em;
      position: relative;

    }

    &-chart {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 2em;
      margin-bottom: 1.5em;
      position: relative;


      > .clock-legend {
        display: none;
      }
    }

    &-clock {
      position: relative;
      display: flex;
      margin-top: 5em;
      margin-left: 1.5em;
      .clock {
        flex: 0 1 44rem;
        max-width: 42vmin;
        max-height: 42vmin;
      }
    }

    &-counter {
      width: 100%;
      height: 100%;
      max-width: calc(30em + 25vmin);
      z-index: 10;
      text-align: left;
      pointer-events: none;
      
      position: absolute;
      &-element{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-top: 2.25em;
        margin-left: 28vmin;
      }

      &-legend {
        width: 19em;
        z-index: 1002;
        margin-left: 10em;
        position: relative;
        &-subtitle{
          text-align: right;
        }
      }
    }
    &-year {
      display: none;
    }

    &-legend {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      /*margin-top: -2.5rem;*/

      &-container {
        flex: 1 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        max-width: 45em;
        margin-right: -2em;
        margin-bottom: -1em;
      }

      &-item {
        flex: 0 0 20em;
        display: flex;
        margin-right: 2em;
        margin-bottom: 1em;
        &-description{
          cursor: pointer;
        }
      }

      &-icon {
        margin-right: 1em;
      }

      &-heading {
        font-size: 87.5%;
      }

      &-score {
        font-size: 112.5%;
        font-weight: bold;
      }
    }
  }

  .map-content{
    height: 100vh;
    .map-display{
      .button.return{
        display: none;
      }
      padding-top: 10em;
      min-height: 100vh;
      position: relative;
    }
  }
  .map-legend{
    width: 100%;
    max-width: 64em;
    margin-left: auto;
    margin-right: auto;
    padding: .5em 1.5em;
    position: relative;
    .circle{
      margin-right: 1.1rem;
    }
    li{
      display: inline-block;
      zoom: 1;
      margin-right: 1.5rem;
    }
    &-item {
      display: inline-block;
      vertical-align: middle
    }

  }
  .clock {
    max-width: 44rem;
  }



  .share {
    right: 5.5rem;


    position: fixed;
    bottom: 5em;
  }
  footer{
    height: 100vh;
  }
  @media screen and (max-width: 768px) {
    .react-select__control{
          background-color: transparent;
          border-bottom: 0;
    }
    .react-select__menu{
      z-index: 200;
      position: absolute
    }
    .react-select__input input{
      color: #fff !important;
    }
    .react-select__indicator-separator{
          display: none;
        }
        .react-select__single-value{
          color: #fff;
          font-weight: bold;
        }
        .react-select__value-container{
          padding-left: 0
        }
    .headline {
      &-bookmarks {
        display: none;
      }

      &-content {
        padding-top: 7rem;
        padding-bottom: 5rem;

        > .wrapper {
          display: block;
        }
      }

      &-chart {
        display: block;
        margin-bottom: 0;
        margin-top: 0;
        > .clock-legend {
          display: flex;
          transform: rotate(0) !important;
        }
        .clock-legend-percentage{
            display: none;
        }

      }

      &-clock {
        margin-bottom: 7em;
        margin-left: 0;
        margin-top: 0;
        .clock {
          max-width: none;
          max-height: none;
          margin: 0 auto 1.5em auto;
        }
      }

      &-counter {    
        position: absolute;
        right: 0;
        bottom: 0;
        margin-top: 0;
        margin-bottom: -5.5em;
        height: auto;
        .counter {
          margin-top: 0;
          font-size: ${45 / 16}em;
          text-align: center;
        }
        &-element{
          position: relative;
          transform: none;
          padding: 0;
          margin: 0;
        }
        &-legend {
          max-width: none;
          font-size: ${14 / 16}em;
          
          margin: auto;
          &-subtitle{
            text-align: center;
          }
        }
      }

      &-year {
        display: block;
        margin-left: -3rem;
        margin-right: -3rem;
        margin-bottom: 2.5em;
        padding: .5em 3rem;
        background-color: #000;
        color: #fff;
        
        
        
        &-label {
          display: block;
          margin-bottom: .25em;
          font-size: ${14 / 16}em;
        }

        &-date {
          font-weight: bold;
        }
      }

      &-legend {
        margin-top: 0;

        &-container {
          flex-direction: column;
          margin-right: 0;
          width: 100%;
        }

        &-item {
          flex: none;
          margin-right: 0;

          &:nth-child(2n) {
            order: 1;
          }

          &:nth-child(n+3) {
            margin-bottom: 1.75em;
            padding-bottom: 2em;
            border-bottom: 1px solid;
          }
        }
      }
    }

    .slider {
      display: none;
    }

    .share {
      display: none;
    }
  }

  .hidden {
    height: 0px;

  }

  .map {
    &-year {
      display: none;
    }
    &-country {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .return-ticker-mobile{
      margin-top: 3.75em;
      margin-bottom: 1.25em;
      .button{
        display: block;
        margin: 0 auto;

      }
    }
    .map {
    
      &-year {
        display: block;
        margin-left: -3rem;
        margin-right: -3rem;
        padding: .5em 3rem;
        background-color: #EE4422;
        color: #fff;
        .select{
          z-index: 12
        } 
      }
      &-country {
        font-size: 150%;
        display: block;
        margin-left: -3rem;
        margin-right: -3rem;
        padding: .5em 3rem;
        background-color: #444;
        color: #fff;
      }
    }
    .map-content {
      height: auto;
      .map-display{
        .button.return{
          position: absolute;
          z-index: 12;
          margin-top: 1em;
          margin-left: 1.5em;
          display: block;
        }
        padding-top: 0
      }
    }
    .map-legend{
      padding-left: 0;
      padding-right: 0;
      color: #fff;
      &-item{
        display: none;
      }
      &-item-mobile{
        display: block;
      }
      li{
        display: inline-block;
        margin-right: 1rem;
        margin-bottom: .5rem;
        padding: .25em;
        .circle{display: none};
      }
    }
    .map-explore{
      display: flex;
      .filters{
        filter: ${props => props.subnationalDisplay ? 'grayscale(100%)' : 'none'};
        pointer-events: ${props => props.subnationalDisplay ? 'none' : 'auto'};
      }
      .headline-explore{
        &:last-child{
          margin-left: .75em;
          margin-left: auto;
        }
      }
      
    }
  .scroll {
    &-enter {
        height:0;
    }
    &-enter-active {
        height: 700px;
        transition: height 1200ms;
    }
    &-exit {
        height: 700px;
    }
    &-exit-active {
        height: 0;
        transition: height 1200ms;
    }
}

`

export default withRouter(Headline);
