import React, {Component} from 'react';
import styled from 'styled-components';

export default class Lenses extends Component {
    render(){
        return(
            <StyledLenses className="lenses">
                <button className={`button ${this.props.currentMapLens === 'track' ? 'active' : ''}`} onClick={() => this.props.toggleLenses('track')}>on/off track to meet sdg1</button>
                <button className={`button ${this.props.currentMapLens === 'world' ? 'active' : ''}`} onClick={() => this.props.toggleLenses('world')}>poverty relative to the { this.props.subnationalDisplay ? 'country' : 'world' }</button>
                <button className={`button ${this.props.currentMapLens === 'country' ? 'active' : ''}`} onClick={() => this.props.toggleLenses('country')}>poverty relative to the {this.props.subnationalDisplay ? 'region' : 'country'}</button>
            </StyledLenses>
        );

    }
}
const StyledLenses = styled.div`
    text-align: center;
    margin-top: .5em;
    .button{
      margin-right: 1.5em;
    }
`