import React, {Component} from 'react';
import styled from 'styled-components';



export default class Dropdown extends Component {
  DropdownCallback = function(parent, element){
    parent.label=element;
  }
  render() {
    if ( !this.props ) { return <div/> }
    return (
      <StyledDropdown {...this.props} className="dropdown" onMouseOver={() => this.props.toggleScroll(false)} onMouseLeave={() => this.props.toggleScroll(true)}>
      {

        this.props.parent.dropdown.map((element, i) => {
          return (
            <div key={i} className= {this.props.currentAgeRange.includes(element[0]) ? 'is-selected' : ''}
              onClick={(e) =>  { this.DropdownCallback(this.props.parent, element[0]); this.props.handleClickDropdown(element[0])}}
            >{element[1]}</div>
          )
        })
      }
      </StyledDropdown>
    )
  }
}

const StyledDropdown = styled.div`
    position: absolute;
    width: 190px;
    height: 157px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);
    background-color: #ffffff;
    top: 2.5em;
    right: 0;
    padding: 11px 0;
    overflow-y: auto;
    color: #00000081;
    font-weight: normal;
    z-index: 200;
  div {
    font-family: 'Work Sans';
    font-size: 16px;
    height: 32px;
    line-height: 32px;
    text-align: left;
    padding: 0 19px;
  }
  div:after{
    content: "";
    border-bottom: 1px solid #979797;
    width: 100%;
    display:block;
    margin:0 auto;
  }
  div:hover {
    background:#eeeeee;
  }
  .is-selected {
    background-color: #EE4422;
    color: white;
    font-weight: bold;
    background-image: url(/static/media/tick.bf549516.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 19px) center;
  }
  .is-selected:hover {
    color: #939393;
    font-weight: normal;
  }
`
