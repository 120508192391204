import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Lenses from './Lenses';
import { Map as LeafletMap} from 'react-leaflet';
import Close from '../_/img/close.svg';
import MapIcon from '../_/img/map.svg';
import Choropleth from 'react-leaflet-choropleth';
import styled from 'styled-components';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import bbox from '@turf/bbox';


const continentBounds = {
  NA: {
    bb0: -171.7911106028912,
    bb1: 7.220541490096537,
    bb2: -12.20855,
    bb3: 83.64513,
  },
  SA: {
    bb0: -81.41094255239946,
    bb1: -55.61183,
    bb2: -34.729993455533034,
    bb3: 12.437303168177309,
  },
  EU:{
    bb0: -24.542225,
    bb1: 31.78639,
    bb2: 50.37499,
    bb3: 70.088882,
  },
  AS:{
    bb0: 26.04335127127254,
    bb1:-10.359987481327963,
    bb2:145.54313724180278,
    bb3:55.38525014914353,
  },
  AF:{
    bb0: -17.62504269049066,
    bb1: -34.81916635512371,
    bb2: 51.13387,
    bb3: 37.349994411766545,
  },
  OC:{
    bb0: 113.3389530782625,
    bb1: -46.641235446967855,
    bb2: 178.51709354076283,
    bb3: -2.500002129734028,
  }

}

const colors = ['#EE4322', '#EBA900', '#00A046', '#D7D7D7', '#FFFFFF'];
const grayToRedGradient = ['#ebebeb', '#EE4322'];
let showOverlay = true;
export default class Map extends Component {
    constructor(props){
      super(props);
      this.mapRef = React.createRef();
      this.state = {
        geoData: this.props.mapData,
        isMouseTooltipVisible: false,
        isLoaded: false,
        currentZoom: 0,
        mapHeight: 0
      };
    }
    
    currentZoom = 0;
    hooveredAreaName = '';
    hooveredColor = '';
    hooveredType = '';
    headCountInPoverty = '';
    hooveredPercentage = 0;
    biggerPercentage = 0;
    needlePosition = 0;
    subnationalMap = false;

    toggleMouseTooltip = () => {
        this.setState(prevState => ({ isMouseTooltipVisible: !prevState.isMouseTooltipVisible }));
    };
    highlightFeatureAction = (e) => {
        if(!this.state.isMouseTooltipVisible){
          this.toggleMouseTooltip();
        }

        var layer = e.target;

        layer.setStyle({
            weight: 2,
            color: '#666',
            dashArray: '',
            fillOpacity: 0.7
        });

        layer.bringToFront();
        this.hooveredAreaName = e.target.feature.properties.name;
        this.headCountInPoverty = e.target.feature.properties.headcount_in_poverty;
        
        this.hooveredColor = e.target.options.fillColor
        this.hooveredType = e.target.feature.properties.tag;
        if(this.props.currentMapLens === 'world') {
          this.hooveredPercentage = e.target.feature.properties.percentage_world;
          
          this.needlePosition = this.hooveredPercentage/this.biggerPercentage;
          
        }else if(this.props.currentMapLens === 'country'){
          this.hooveredPercentage = e.target.feature.properties.percentage_country
          this.needlePosition = this.hooveredPercentage;
        }
        
    }
    highlightFeature = (e) => { // On mouse enters hoover
      if(window.matchMedia("(min-width: 768px)").matches){
        this.highlightFeatureAction(e);
      }   
    }
    resetHighlight = (e) => { // On mouse leaves hoover
        e.target.setStyle(this.getStyle());
        if(window.matchMedia("(min-width: 768px)").matches){
          this.toggleMouseTooltip()
        }
    }
    setColorTags() {
        //define tag for colors
        this.state.geoData.features.forEach( (feature) => {
        if ( feature.properties.stable_track === 'nopoverty') { //no extreme poverty, poverty below 3%
          feature.properties.tag = 3;
        } else if ( feature.properties.stable_track === 'rising' ) {
          feature.properties.tag = 0;
        } else if ( feature.properties.stable_track === 'offtrack' ) {//Offtrack for SDG
          feature.properties.tag = 1;
        } else if ( feature.properties.stable_track === 'ontrack' ){ //Ontrack for SDG
          feature.properties.tag = 2;
        }
        if ( feature.properties.stable_track === undefined 
            || feature.properties.name === "Ukraine" 
            || feature.properties.name === "Turkmenistan") { //no data, if no country has tag == 4, the map colors have to be readjusted
          feature.properties.tag = 4;
        }
      })
    }
    setHoverColorFromTag() {
        this.state.geoData.features.forEach( (feature) => {
            if(feature.properties.name === this.hooveredAreaName){
              this.hooveredColor = colors[feature.properties.tag]
              this.hooveredType = feature.properties.tag;
            }
        })
    }
    clickOnCountry = (e) => {
        //if(window.matchMedia("(min-width: 768px)").matches){
          if(this.hooveredType !== 3 && this.hooveredType !== 4 && this.props.currentMapLens === 'track' && !this.subnationalMap){
            this.props.handleCountryChange(e.target.feature.id, e.target.feature.properties.name);
          }else if(this.subnationalMap){
            this.props.handleRegion(e.target.feature.properties.name);
            this.props.handleCountryCard(true);
          }
          if(!window.matchMedia("(min-width: 768px)").matches && this.props.currentMapLens !== 'track'){
            this.highlightFeatureAction(e);
          }
        //}
        
    }
    getInitialBounds = () => {
      const worldBounds = this.getWorldBounds();
      if(worldBounds){
        let initialBounds = [];
        initialBounds[0] = [worldBounds[0][0]+75,worldBounds[0][1]];
        initialBounds[1] = [worldBounds[1][0]-50,worldBounds[1][1]-75];
        return initialBounds;
      }
      
    }
    getWorldBounds = () => {
      if(this.subnationalMap){
        return;
      } 
      var bounds = [[],[]];
      var bb = bbox(this.state.geoData);
      var corner1 = [bb[1]-15,bb[0]];
      var corner2 = [bb[3],bb[2]+75];
      bounds = [corner1, corner2]

      return bounds
    }
    getBounds = () => {
        var bounds = [[],[]];
        var bb = bbox(this.state.geoData);
        //var percentageAcc = 0;
        if(this.props.currentCountry !== 'WORLD' && this.subnationalMap === false && this.props.continentsDisplay === false) {
            var dataSubset = {"type":"FeatureCollection","features":[{"type":"Feature","id":"EXP","properties":{"name":"EXAMPLE"},"geometry":{"type":"Polygon","coordinates":[]}}]}

            this.state.geoData.features.forEach( feature => {
                if(feature.id === this.props.currentCountry){
                    dataSubset.features[0] = feature;
                }
            })
            
            bb = bbox(dataSubset);
        }else if(this.props.continentsDisplay){
          bb = [];
          bb[1] = continentBounds[this.props.currentCountry].bb1;
          bb[0] = continentBounds[this.props.currentCountry].bb0;
          bb[3] = continentBounds[this.props.currentCountry].bb3;
          bb[2] = continentBounds[this.props.currentCountry].bb2;
        }

        var corner1 = [bb[1],bb[0]];
        var corner2 = [bb[3],bb[2]];
        bounds = [corner1, corner2]
        return bounds
    }
    getColorScale = () => {
      if(this.props.currentMapLens === 'track'){
        return colors
      }else{
        return grayToRedGradient
      }  
    }
    getPropertyValues = (feature) => {
      switch (this.props.currentMapLens) {
        case 'track':
          return feature.properties.tag;
        case 'world': 
          return feature.properties.percentage_world;
        case 'country':
          return feature.properties.percentage_country;
        default:
          return feature.properties.tag;
      }
    }
    getHoverCardInfo = () => {

      const noData = this.headCountInPoverty === undefined 
      || this.hooveredAreaName === "Ukraine"
      || this.hooveredAreaName === "Turkmenistan";
      
      if(noData){
        return 'No Data';
      }
      switch (this.props.currentMapLens) {
        case 'track':
            if(noData){
              return 'No Data';
            }else if(this.hooveredType === 3){
              return '';
            }else{
              return Math.round(this.headCountInPoverty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        case 'world': 
          if(this.hooveredPercentage < 0.005){
            return '< 0.5%';
          }
          //return (this.hooveredPercentage*100).toFixed(1)+'%';
          return Math.round(this.headCountInPoverty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        case 'country':
            
            if(this.hooveredPercentage < 0.03){
              return '< 3%';
            }
          //return (this.hooveredPercentage*100).toFixed(1)+'%';
          return Math.round(this.headCountInPoverty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        default: 
            return
      }
      
      
    }
    getNumSteps = () => {
      switch (this.props.currentMapLens) {
        case 'track':
          return 5;
        case 'world': 
          return 13;
        case 'country':
          return 200;
        default:
          return 4;
      }
    }
    getStyle(){
      let style = {
        weight: 1,
        opacity: 1,
        color: 'grey',
        dashArray: '0',
        fillOpacity: 1,
      }
      if(this.props.currentMapLens === 'track'){
        style.fillOpacity = 0.5
      }
      return style
    }
    setMaxPercentageWorld(){
      let percentgeAcc = 0;
        this.state.geoData.features.forEach( feature => {
          
          if(feature.properties.percentage_world && feature.properties.percentage_world > percentgeAcc){
            percentgeAcc = feature.properties.percentage_world
          }
        });
        this.biggerPercentage = percentgeAcc;
    }
    componentDidMount() {
        this.setState({isLoaded: true});
        const header = ReactDOM.findDOMNode(this).closest('main').querySelector('.header').offsetHeight;
        const slider = ReactDOM.findDOMNode(this).closest('main').querySelector('.slider').offsetHeight;
        const indicator = ReactDOM.findDOMNode(this).closest('main').querySelector('.slider-indicator-label').offsetHeight;
        const continents = ReactDOM.findDOMNode(this).closest('main').querySelector('.continents.button').offsetHeight;
        const map_legend = ReactDOM.findDOMNode(this).querySelector('.map-legend').offsetHeight;
        const lenses = ReactDOM.findDOMNode(this).querySelector('.lenses').offsetHeight;
        
        this.setState({mapHeight: slider + map_legend + lenses + header + indicator + continents},()=>{
          setTimeout(() => {
            this.mapRef.current.leafletElement.invalidateSize();
            this.mapRef.current.leafletElement.setZoom(1.5);
          }, 100);
        }) 
        
        
    }
    componentDidUpdate(){
      this.setMaxPercentageWorld();
      this.setColorTags();
      if(this.props.subnationalDisplay) {
        if(!this.subnationalMap){
          const targetZoom = this.mapRef.current.leafletElement.getBoundsZoom(this.getBounds());
          
          this.mapRef.current.leafletElement.setMinZoom(targetZoom-1);
          this.setState({geoData: this.props.subnationalData})
        }
        this.subnationalMap = true;
      } else {
        if(this.subnationalMap){
          this.mapRef.current.leafletElement.setMinZoom(1);
          this.setState({geoData: this.props.mapData})
        }  
        this.subnationalMap = false;
      } 
    }

    labelAfterFiltering () {
      let contextWorld = "global";
      let contextCountry = "country";
      
      if(this.headCountInPoverty === undefined 
        || this.hooveredAreaName === "Ukraine"
        || this.hooveredAreaName === "Turkmenistan"){return ''}
      if(this.props.subnationalDisplay){
        contextWorld = "countries'";
        contextCountry = "region";
      }
      let filterInfo = '';
      if (this.props.currentMapLens === 'track'){
        filterInfo = 'people';
      }else{
        filterInfo = 'population';
      }
      if (this.props.filters[0][0]) { filterInfo = 'rural '+filterInfo }
      if (this.props.filters[0][1]) { filterInfo = 'urban '+filterInfo }
            if (this.props.filters[1][0] && !this.props.filters[1][1] && !this.props.filters[1][2]) { filterInfo = 'females' }
            if (this.props.filters[1][1] && !this.props.filters[1][0] && !this.props.filters[1][2]) { filterInfo = 'males' }
            if (this.props.filters[1][0] && !this.props.filters[1][1] && this.props.filters[1][2]) { filterInfo = 'females in selected age range' }
            if (this.props.filters[1][1] && !this.props.filters[1][0] && this.props.filters[1][2]) { filterInfo = 'males in selected age range' }
            if (!this.props.filters[1][1] && !this.props.filters[1][0] && this.props.filters[1][2]) { filterInfo = 'people in selected age range'; }
            
      switch (this.props.currentMapLens) {
        case 'track':
            if(this.hooveredType === 3){return 'Extreme poverty below 3%'}
            if (this.props.filters === undefined) { return 'People living in extreme poverty'; }
            return 'Number of '+filterInfo+' living in extreme poverty'
        case 'world':
            if(this.hooveredPercentage >= 0.005)
              return (this.hooveredPercentage*100).toFixed(1)+"% of the "+contextWorld+" "+filterInfo+" in extreme poverty live in this "+contextCountry;
            else
              return "of the "+contextWorld+" "+filterInfo+" in extreme poverty live in this "+contextCountry;
        case 'country':
            if(this.hooveredPercentage >= 0.03)
              return (this.hooveredPercentage*100).toFixed(1)+"% of this "+contextCountry+"'s "+filterInfo+" live in extreme poverty";
            else
              return "of this "+contextCountry+"'s "+filterInfo+" live in extreme poverty";
        default:
          break;
      }
      
    }
    toggleOverlay = () => {
      showOverlay = !showOverlay;
    } 

    viewportChange = e => this.currentZoom = e.zoom;
    
    render() {
    
        return (
            <StyledMap mapHeight={this.state.mapHeight} needlePosition={this.needlePosition} isMouseTooltipVisible={this.state.isMouseTooltipVisible}>
                    <div className={showOverlay ? 'map-overlay' : 'hide'}>
                      <div className={showOverlay ? 'map-overlay-content' : 'hide'}>
                        <img className="mapIcon" src={MapIcon} alt="Map Icon" />
                        <h2>Interactive poverty map</h2>
                        <p>Pinch to zoom in the map and touch the country to get more details about the status of its poverty</p>
                        <button className="button" onClick={this.toggleOverlay}>Start exploring</button>
                      </div>
                      
                    </div>
                    <LeafletMap ref={this.mapRef} attributionControl={false} minZoom={1} maxZoom={!this.props.subnationalDisplay ? 7 : 3} bounds={this.props.currentCountry === 'WORLD' && !this.props.subnationalDisplay ? this.getInitialBounds() : this.getBounds()} maxBounds={this.getWorldBounds()} maxBoundsViscosity={0.5} useFlyTo={true} onViewportChange={this.viewportChange} touchZoom={true} tap={false} scrollWheelZoom={window.matchMedia("(min-width: 768px)").matches} >
                        <Choropleth
                            key={this.subnationalMap.toString()}
                            data={{type: 'FeatureCollection', features: this.state.geoData.features }}  /*feature collection or array*/
                            valueProperty={(feature) => this.getPropertyValues(feature)}//feature.properties['id']  /*value for choropleth*/}
                            //visible={(feature) => true    /*use choropleth color?*/}
                            scale={this.getColorScale()}                      /*color range*/
                            steps={this.getNumSteps()}                                             /*how many different colors to use?*/
                            mode={'e'}                                             /*use equadistance mode, others include kmeans and quantile*/
                            style={this.getStyle()}
                            onEachFeature={(feature, layer) => {
                                layer.on({
                                    mouseover: this.highlightFeature,
                                    mouseout: this.resetHighlight,
                                    click: this.clickOnCountry,
                                });
                            }}
                        />

                        { /*this.currentZoom >= 3 ?
                          <TileLayer
                            url="https://stamen-tiles.a.ssl.fastly.net/terrain-labels/{z}/{x}/{y}.png"
                          />
                         : ''*/
                        }
                    </LeafletMap>
                    {
                      this.state.isMouseTooltipVisible ? 
                      <MouseTooltip className="country-card-tooltip"
                        offsetX={15}
                        offsetY={-150}
                        >
                        {
                          <div className='country-card-small'>
                            <button className="close-button" onClick={ () => { this.toggleMouseTooltip() } }><img className="close" src={Close} alt="Close" /></button>
                            <p className='country-card-small-color' style={{color: this.hooveredColor}}> {
                            //this.geoData.features.forEach( (feature) => {if(feature.properties.name === this.hooveredAreaName) return feature.properties.tag;})
                            }⬤</p>
                            <p className='country-card-small-title'>{ !this.props.subnationalDisplay ? 'Country' : 'Region' } Card</p>
                            <p className='country-card-small-country' >{this.hooveredAreaName}</p>
                            <p className='country-card-small-data-numbers' style={{color: this.hooveredColor}}>
                              {
                                this.getHoverCardInfo()
                              }
                            </p>
                            <p>{this.labelAfterFiltering()}</p>
                        </div>
                        }
                        
                    </MouseTooltip>
                      : ''
                    }
                    {
                      this.props.currentMapLens === 'track' ? 
                        <ul className="map-legend">
                              <li className="poverty-rising">
                                <div className="map-legend-item circle circle-poverty-rising "></div>
                                <div className="map-legend-item">Poverty is rising</div>
                                <div className="map-legend-item-mobile">rising</div>
                              </li>
                              <li className="offtrack">
                                <div className="map-legend-item circle circle-offtrack"></div>
                                <div className="map-legend-item">Off-track for SDG target</div>
                                <div className="map-legend-item-mobile">Off-track</div>
                              </li>
                              <li className="ontrack">
                                <div className="map-legend-item circle circle-ontrack"></div>
                                <div className="map-legend-item">On-track for SDG target</div>
                                <div className="map-legend-item-mobile">On-track</div>
                              </li>
                              <li className="no-extreme-poverty">
                                <div className="map-legend-item circle circle-no-extreme-poverty"></div>
                                <div className="map-legend-item">Poverty below 3%</div>
                                <div className="map-legend-item-mobile">Poverty below 3%</div>
                              </li>
                              <li className="no-data">
                                <div className="map-legend-item circle circle-no-data"></div>
                                <div className="map-legend-item">No data</div>
                                <div className="map-legend-item-mobile">No data</div>
                              </li>
                          </ul>
                      :
                      <div className="map-color-bar">
                        <div className="map-color-bar-gradient">
                          <div className="map-color-bar-gradient-start">0%</div>
                          <div className="map-color-bar-gradient-end">
                          { this.props.currentMapLens === 'world' ?
                            (Math.ceil(this.biggerPercentage*100))+'%' :
                            '100%'
                          }</div>
                          <div className="map-color-bar-stick"></div>
                        </div>
                        
                      </div>
                    }
                    <Lenses {...this.props} />
                    
            </StyledMap>
        )
    }
}


const StyledMap = styled.div`
    
    .leaflet-container {
        height: calc( 90vh - ${props => props.mapHeight}px );
        margin-bottom: .25em;
        width: 100%;
        
        background-color: white;
        z-index: 0;
    }
    @media (min-aspect-ratio: 2) {
      .leaflet-container {
        height: calc(20vh + 10em);
      }
    }
    .circle {
      width: 1em;
      height: 1em;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      text-align: center;
      display: flex;
    }
    

    .country-card-small{
        .close-button{
          display: none;
        }
        background-color: black;
        color: white;
        min-width: 20em;
        max-width: 25em;
        padding: 1.5em;
        position: relative;
        &-color{
          position: absolute;
          font-size: 2em;
          right: 0;
          top: 0;
          margin-top: .5em;
          margin-right: .5em;
        }
        &-title{
          font-size: 14px;
          letter-spacing: 2px;
          text-transform: uppercase
        }
        &-country{
            font-weight: bold;
            font-family: 'Berlingske';
            font-size: 35px;
            margin-bottom: .1em;
            margin-top: .1em
        }
        &-data-numbers{
          font-size: 20px;
          font-weight: bold;
          margin-bottom: .2em;
        }
    }
  
  

  .map-color-bar{
    position: relative;
    width: 50%;
    margin: 0 auto;
    padding: .5em 1.5em;
    &-gradient{
      width: 100%;
      height: 1.25em;
      border-radius: 15px;
      
      background-image: linear-gradient(to right, #ebebeb, #ee4322);
      opacity: 1;
      position: relative;
      &-start{
        position: absolute;
        left: 0;
        margin-left: -2em;
      }
      &-end{
        position: absolute;
        right: -2.2em;
        width: 1.6em;
      }
    }
    &-stick{
      display: ${props => props.isMouseTooltipVisible ? 'block' : 'none'};
      position: absolute;
      width: 1px;
      height: 1.5em;
      background-color: black;
      top: 0;
      margin-top: -0.25em;
      margin-left: ${props => props.needlePosition*100 < 99 ? props.needlePosition*100 : 99}%;
    }
  }
  .map-legend{
      .poverty-rising .circle{
        background-color: #EE4322;
      }
      .offtrack .circle{
        background-color: #EBA900;
      }
      .ontrack .circle{
        background-color: #00A046;
      }
      .no-extreme-poverty .circle{
        background-color: #D7D7D7;
      }
      .no-data .circle{
        background-color: #fff;
        border: 1px solid #979797;
      }
      &-item-mobile{
        display: none;
      }
  }
  .map-overlay{
    display: none;
  }
  .lenses .button{
    min-width: 27%;
  }
  @media screen and (max-width: 768px) {
    .leaflet-container {
      width: calc(100% + 6rem );
      height: calc(40vh + 10em);
      margin-left: -3rem;
      border-bottom: 1px solid #eeeeee;
      border-top: 1px solid #eeeeee;
    }
    .lenses{
      display: none;
    }
    .map-color-bar{
      width: 100%;
      padding: 1.5em;
    }
    .map-overlay{
      display: block;
      width: calc(100% + 6rem );
      margin-left: -3rem; 
      height: calc(40vh + 7.5em);
      background-color: rgba(0,0,0,.75);
      position: absolute;
      z-index: 12;
      color: #fff;
      &-content{
        padding: 0 3em;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        h2{
          font-size: 2.5em;
          font-weight: bold;
          margin-bottom: .3em;
        }
      }
      .button{
        margin-top: 1.25rem;
        color: #000;
      }
      &-control{
          width: 4.5em;
          height: 4.5em;
          border-radius: 50vw;
          background-color: black;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      
    }
    .map-legend{
      .poverty-rising{
        background-color: #EE4322;
      }
      .offtrack{
        background-color: #EBA900;
      }
      .ontrack{
        background-color: #00A046;
      }
      .no-extreme-poverty{
        background-color: #D7D7D7;
        color: #000;
      }
      .no-data{
        background-color: #fff;
        border: 1px solid #979797;
        color: #000;
      }
    }
    .country-card-tooltip{
      left: 0 !important;
      top: 0 !important;
      margin-top: 25%;
      position: absolute !important;
      display: block !important;
      .country-card-small{
        .close-button{
          display: block;
        }
        padding: 2.5em 1.5em 1.5em 1.5em;

        &-color{
          margin-top: 1.5em;
        }
      }
    }
    .close-button{
      display: block;
      position: absolute;
      right: 0;
      margin-right: 1.5em;
      margin-top: -1.5em;
      .close{
        width: 1em;
      }
    }
    
    
  }

`
