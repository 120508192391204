import React from 'react';
import styled from 'styled-components';

function CopyHeader(props) {
  return (
    <StyledCopyHeader className="copy-header">
      <p className="copy-header-intro">{props.intro}</p>

      <div className="copy-header-container">
        <h1 className="copy-header-title"><a href={props.url}>{props.url_data}</a>{props.title}</h1>
        
      </div>
    </StyledCopyHeader>
  )
}

const StyledCopyHeader = styled.header`
.copy-header {
  &-intro {
    margin-bottom: 2em;
    color: #4A4A4A;
    letter-spacing: .15em;
    text-transform: uppercase;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-title {
    font-family: 'Berlingske';
    font-size: ${45 / 16}em;
    a{
      color: #EE4422;
      text-decoration: underline;
    }
    &:not(:last-child) {
      margin-right: 1em;
    }

    &:not(:only-child) {
      max-width: 12em;
    }
  }

  &-picture {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .copy-header {
    &-intro {
      margin-bottom: 1em;
    }

    &-title {
      margin-right: 0;
      font-size: ${25 / 14}em;
    }
  }
}
`

export default CopyHeader;
